
// import { legacy_createStore as createStore} from 'redux';
// import languageReducer from './reducer';
// import { composeWithDevTools } from 'redux-devtools-extension';
//  const store = createStore(languageReducer,composeWithDevTools());
import { legacy_createStore as createStore} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import mobileCountryCodeReducer from './reducer';

//  export default store;

 const store = createStore(mobileCountryCodeReducer,composeWithDevTools());

 export default store;