import { useContext } from "react";
import { User } from "../../Contexts/UserContexts";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';


export default function RequireAuth() {
    const location = useLocation();
    const user = useContext(User);
    // console.log(user);
    // get and set Cookie
    const cookie = new Cookies();
    // const tokenCookie = cookie.set('Bearer', token);
    const getToken = cookie.get('Bearer')
    // console.log(getToken);
    return getToken ? <Outlet /> : <Navigate state={{ from: location }} replace to='/login' />
}