import React , { useEffect, useState } from "react";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Accordion from "react-bootstrap/Accordion";

const FeedEvent = () => {
  return (
    <div className="flex-wrap d-flex row">
          <div className="col-lg-4 col-md-12 col-sm-4 ps-0 ">
            <section
              id="filter-hub"
              className="box b5 me-3 position-relative"
             
            >
              <div className="cursor-pointer">
                <div>
                  <img
                    className="shadow rounded-3 lazyloaded"
                    src="https://img.10times.com/images/category/150.png"
                    width="180"
                    height="180"
                    alt="Security &amp; Defense Hub"
                    style={{ minWidth: "100%", maxHeight: "150px" }}
                  />
                </div>
                <div className="pt-3">
                  <div className="mb-2 d-flex justify-content-between">
                    <h2 className="mb-0" style={{ fontSize: "1.2em" }}>
                      <span>Go to</span>
                      <span id="community_name">Security & Defense Hub</span>
                    </h2>
                    <div style={{ cursor: "pointer", marginLeft: "10%" }}>
                      <a href="" className="text-decoration-none text-blue">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-arrow-right-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div className="btn-group-vertical me-2">
                    <div className="mb-2 mt-2">
                      <span
                        id="community-member-count"
                        className="text-black me-2 fs-12"
                      >
                        124332 Members
                      </span>
                      <span style={{ marginRight: "-1%" }}>
                        <img
                          className="position-relative border border-white rounded-circle"
                          src="https://c1.10times.com/userimages/c.jpg"
                          alt="https://c1.10times.com/userimages/c.jpg"
                          width="30"
                          height="30"
                          loading="lazy"
                        />
                        <img
                          className="position-relative border border-white rounded-circle"
                          src="https://c1.10times.com/userimages/f.jpg"
                          alt="https://c1.10times.com/userimages/f.jpg"
                          width="30"
                          height="30"
                          loading="lazy"
                          style={{ left: "-1%" }}
                        />
                        <img
                          className="position-relative border border-white rounded-circle"
                          src="https://c1.10times.com/user/images/1/1/5/62522115_1627470480.jpg"
                          alt="https://c1.10times.com/user/images/1/1/5/62522115_1627470480.jpg"
                          width="30"
                          height="30"
                          loading="lazy"
                          style={{ left: "-2%" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 px-0 ">
            <div className="w-100 mb-4 rounded-3 shadow p-3 mb-4 align-items-baseline bg-white">
              <div className="cc_intro">
                <img
                  id="com_vid"
                  className=" rounded-circle me-2 profile-pic  "
                  src="https://img.10times.com/user_pic%2FnUjEqF4zq9RsczNFelovYEKBO4XUVJtTbTcYBnUos5J0DOFN2sO%2BAh4fvbQ44KZN09lnHk%2BhxaGZohS%2BQAw%3D%3D.jpg?imgeng=/w_80/h_80/m_letterbox_000000_100/cmpr_60"
                  width="42"
                  height="42"
                  alt="profile_pic"
                  loading="lazy"
                />
              </div>
              <div className="w-100">
                <div className="position-relative">
                  <input
                    id="com_place"
                    className="text-muted border px-3 py-2 w-100 position-relative link padding-10"
                    style={{ borderRadius: "1rem" }}
                    placeholder="Write a post here..."
                    readOnly=""
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    className="bi bi-camera float-end camposition"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z" />
                    <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                  </svg>
                </div>
                <div className="mt-2 text-muted">
                  Join the conversation, share goals, or simply say HI! and
                  connect instantly with your industry circle.
                </div>
              </div>
            </div>
            <div id="com_posts" >
              <div id="ajaxfifteen" >
                <div className="container px-0 " >
                  <div className="row justify-content-center ">
                    <div className="col-md-12 ">
                      <div
                        className="card mb-3 rounded-3 deep-shadow border-0 "
                       
                      >
                        <div className="d-flex card-body ">
                          <img
                            className="rounded-circle position-absolute lazyloaded"
                            src="https://c1.10times.com/userimages/a.jpg"
                            alt="User Profile"
                            style={{ width: "55px", height: "55px" }}
                          />
                          <div className="w-95 ms-4">
                            <div className="d-flex align-items-center mb-2 ms-4">
                              <div className="w-100 d-flex flex-row ms-2 justify-content-between cursor-pointer">
                                <div className="ms-2 w-75">
                                  <p className="fs-5 mb-0">Ashiya Nisha</p>
                                  <p className="text-muted text-truncate w-75 m-0">
                                    Associate client servicing • 10times
                                  </p>
                                </div>
                                <div id="feedright">
                                  <div className="droopdown">
                                    <a href="" className="text-blue">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        fill="currentColor"
                                        className="bi bi-three-dots"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p
                              id="anime_card"
                              className="card-text mb-1 feedposition"
                            >
                              Army Navy Military Expo added new images in ANME
                              Trade Show.
                            </p>
                            <div
                              className="d-flex w-100"
                              style={{ height: "300px", marginLeft: "-20px" }}
                            >
                              <div style={{ width: "99%", height: "300px" }}>
                                <img
                                  id="imgup"
                                  src="https://img.10times.com/upload/event/64cd7aaac60bb.jpeg?imgeng=/w_500/h_300/m_letterbox_000000_80/cmpr_60"
                                  alt="User Posted Media"
                                  className="w-100 h-100 photos-tumbnail link"
                                  style={{ objectFit: "fill" }}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <div
                                className="d-flex mb-0"
                                style={{ marginLeft: "-23px" }}
                              >
                                <div className="lazyloaded d-flex flex-row w-75 h-75 justify-content-sm-center link rounded-5 likeposition">
                                  <div className=" d-sm-grid justify-content-sm-center mb-3">
                                    <svg
                                      id="up-arrow_26822527"
                                      fill="#6c757d"
                                      height="20px"
                                      width="20px"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 512 512"
                                      enableBackground="new 0 0 512 512"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        strokeWidth="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <polygon points="245,0 74.3,213.3 202.3,213.3 202.3,512 287.7,512 287.7,213.3 415.7,213.3 "></polygon>
                                      </g>
                                    </svg>
                                  </div>
                                  <div className="lazyloading d-flex justify-content-sm-center text-center ms-1 me-1">
                                    <p>0</p>
                                  </div>
                                  <div
                                    id="downvote"
                                    className="d-sm-grid justify-content-sm-center mb-3"
                                  >
                                    <svg
                                      id="down-arrow_26822527"
                                      fill="#6c757d"
                                      height="20px"
                                      width="20px"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      enableBackground="new 0 0 512 512"
                                      space="preserve"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        strokeWidth="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <polygon points="283.7,298.7 283.7,0 198.3,0 198.3,298.7 70.3,298.7 241,512 411.7,298.7 "></polygon>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="d-flex float-end mt-2 text-muted"
                                style={{ fontSize: "15px" }}
                              >
                                Aug 2023 • 03:54
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};
 
  export default FeedEvent;
