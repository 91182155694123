import React, { useEffect, useState } from "react";

import axiosInstance from "../../AxiosConfig/axiosinstance";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Accordion from "react-bootstrap/Accordion";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";
import "./eventsDetails.css";
import AboutEvent from "./aboutEvent";
import FeedEvent from "./feedEvent";
import FollowerEvent from "./followerEvent";
import ExhibitorEvent from "./exhibitor";
import SpeakerEvent from "./speakerEvent";
import ReviewEvent from "./reviewEvent";
import SpinnerComponent from "../../Components/Spinner/spinner";
import { useNavigate } from "react-router-dom";
import { getEventDetails } from "../../Store/action";

const EventsDetails = () => {
  // const percentage = 66;

  // const event = useSelector(state => state.eventDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const result = localStorage.getItem("event");
  const event = JSON.parse(result);
  const [events, setEvents] = useState();
  const [otherEvents, setOtherEvents] = useState();

  const getEventDetail = () => {
    axiosInstance
      .get(
        `aboutEvent/${event.EventData.data._id}?country=${event.EventData.data.Country
        }&city=${event.EventData.data.City}&category=${encodeURIComponent(
          event.EventData.data.Category[0]
        )}`
      )
      .then((respons) => {
        setEvents(respons.data.EventData.data);
        setOtherEvents(respons.data.EventData);
        // console.log(respons.data.EventData.data);
        // console.log(respons.data.EventData);
        // console.log(events);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //get new event details the same category
  const eventDetails = async (eventData) => {
    // console.log(event.Country);
    // console.log(event);
    try {
      const response = await axiosInstance.get(
        `/aboutEvent/${eventData._id}?country=${eventData.Country}&city=${eventData.City
        }&category=${encodeURIComponent(eventData.Category[0])}`
      );
      dispatch(getEventDetails(response.data));
      const result = JSON.stringify(response.data);
      localStorage.setItem("event", result);
      setEvents(response.data.EventData.data);
      setOtherEvents(response.data.EventData);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
    // axiosInstance
    //   .get(`/aboutEvent/${event._id}?country=${event.Country}`)
    //   .then((response) => {
    //     console.log(response.data);
    //     // console.log(data);
    //     // navigate("/eventsinfo");
    //     dispatch(getEventDetails(response.data));
    //     const result = JSON.stringify(response.data);
    //     localStorage.setItem("event", result);
    //     setEvents(response.data);
    //     window.location.reload();
    //     // console.log(response);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  useEffect(() => {
    if (localStorage.getItem("event")) {
      getEventDetail();
      handleButtonClick();
    } else {
      navigate("/events");
    }
  }, []);
  const [content, setContent] = useState(null);
  const [activeButton, setActiveButton] = useState("about");

  // const [showRatingSection, setShowRatingSection] = useState(true);
  // const handelRating = () => {
  //   setShowRatingSection(!showRatingSection);
  //   console.log(showRatingSection);
  // };

  // const [activeTags, setActiveTags] = useState([]);

  // const handleTagClick = (index) => {
  //   console.log(index);
  //   if (activeTags.includes(index)) {
  //     setActiveTags(activeTags.filter((tagIndex) => tagIndex !== index));
  //   } else {
  //     setActiveTags([...activeTags, index]);
  //   }
  //   console.log(activeTags);
  // };

  const handleButtonClick = (id = "about") => {
    setActiveButton(id);
    if (id === "about") {
      setContent(<AboutEvent />);
    } else if (id === "feed") {
      setContent(<FeedEvent />);
    } else if (id === "followers") {
      setContent(<FollowerEvent />);
    } else if (id === "exhibitors") {
      setContent(<ExhibitorEvent />);
    } else if (id === "speakers") {
      setContent(<SpeakerEvent />);
    } else if (id === "reviews") {
      setContent(<ReviewEvent />);
    } else if (id === "deals") {
      setContent(<h1> deals Content Page</h1>);
    }
  };

  return (
    <>
      {events ? (
        <>
          {/* header section */}
          <section
            className="page-wrapper "
            style={{ backgroundImage: `url(${events.Background})` }}
          >
            <div className="container text-start d-flex">
              {events.Banner !== "" ? (
                <div className="col-md-2 col-sm-3 td">
                  <img className=" img-fluid" src={events.Banner} />
                </div>
              ) : (
                ""
              )}

              <div className="col-md-10 col-sm-9">
                <div
                  className="float-end"
                  style={{
                    lineHeight: "29.4px",
                    fontSize: "21px",
                    fontWeight: "300",
                  }}
                >
                  <a className="text-decoration-none" href="">
                    <span
                      className="label label-success rounded-2"
                      style={{ fontSize: "15.75px" }}
                    >
                      <strong>{events.Rating.Average.toFixed(1)}</strong>
                      <small style={{ fontWeight: "400" }}>/5</small>
                    </span>
                  </a>
                  <br />
                  <a className="text-decoration-none" href="">
                    <p
                      className="text-center fs-12"
                      style={{ lineHeight: "16.8px" }}
                    >
                      <small>
                        {" "}
                        {`${events.Rating["1"] +
                          events.Rating["2"] +
                          events.Rating["3"] +
                          events.Rating["4"] +
                          events.Rating["5"]
                          }`}{" "}
                      </small>
                      ratings
                    </p>
                  </a>
                </div>
                <span className="label label-success me-1 fs-12 rounded-2">
                  {events.Format}
                </span>
                {events.Featured ? (
                  <span className="label bg-orange fs-12 rounded-2">
                    Featured
                  </span>
                ) : (
                  ""
                )}

                <button
                  className="selectEvent add-new-listing float-end me-2 btn p-0"
                  style={{ color: "#ffffff" }}
                  data-bs-toggle="popover"
                  data-bs-content="Select event"
                  data-bs-placement="top"
                  data-bs-custom-classname="list-tooltip"
                  data-bs-trigger="hover"
                  data-bs-original-title=""
                  title=""
                >
                  <svg
                    className="svg-inline--fa fa-list-add ms-2 mt-1 fs-22"
                    style={{ width: " 1.8em", cursor: " pointer" }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fak"
                    data-icon="list-add"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M298.7 213.3H42.7V256h256v-42.7zm0-85.3H42.7v42.7h256V128zM384 298.7v-85.3h-42.7v85.3H256v42.7h85.3v85.3H384v-85.3h85.3v-42.7H384zM42.7 341.3h170.7v-42.7H42.7v42.7z"
                    ></path>
                  </svg>
                </button>
                <div>
                  <h1 style={{ width: "94%" }} className="event-head">
                    {events.Name}
                  </h1>
                </div>
                <div className="mb-0 fs-20">
                  <span
                    style={{
                      width: " 20px",
                      height: "20px",
                      display: "inline-block",
                    }}
                  >
                    <svg
                      className="svg-inline--fa fa-clock fa-w-16 fa-fw"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="far"
                      data-icon="clock"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                      ></path>
                    </svg>
                  </span>
                  <span className="ms-1">
                    {moment(events.StartDate).format("ddd, D - ")}{" "}
                    {moment(events.EndDate).format("ddd, D MMM YYYY")}
                  </span>
                  <span
                    className="x-bk evtstatus fs-12 fw-bold action-cl action-db ms-2 text-decoration-none cursor"
                    style={{ opacity: "0.7" }}
                  >
                    Add To Calendar{" "}
                  </span>
                  <small className="evtstatus1 fs-12 status greeen">
                    ( Ongoing )
                  </small>
                </div>
                <div className="mb-0 fs-20">
                  <span
                    className="mx-1"
                    style={{
                      width: "15px",
                      height: "17px",
                      display: "inline-block",
                    }}
                  >
                    <svg
                      className="svg-inline--fa fa-map-marker-alt fa-w-12 fa-fw "
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fa"
                      data-icon="map-marker-alt"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
                      ></path>
                    </svg>
                  </span>
                  {events.Address !== "Null" && events.Address !== ""
                    ? `${events.Address}, `
                    : " "}
                  <a href="" className="bld text-decoration-none">
                    {(events.Venue.City) ? (events.Venue.City) : ("")}
                  </a>
                  ,
                  <a href="" className=" text-decoration-none">
                    {" "}
                    {events.Venue.Country ? events.Venue.Country : ""}
                  </a>
                  <span
                    className="text-decoration-none fs-12 fw-bold ms-2 cursor"
                    style={{ opacity: "0.7" }}
                  >
                    Get Directions
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 525.155 525.155"
                      style={{
                        enableBackground: "new 0 0 525.155 525.155",
                        cursor: "pointer",
                        opacity: "0.7",
                      }}
                      space="preserve"
                      width="15px"
                      height="15px"
                      className="engageAction ms-1"
                      data-toggle="tooltip"
                      data-original-title="Get Direction"
                      data-action="openGoogleMap"
                      data-lat="51.508460"
                      data-lng="0.029846"
                      data-parma="1"
                    >
                      <g>
                        <g>
                          <path
                            d="M517.472,244.066L281.088,7.682c-10.24-10.24-26.783-10.24-37.001,0L7.68,244.066c-10.24,10.24-10.24,26.783,0,37.001   l236.406,236.406c10.24,10.24,26.783,10.24,37.001,0l236.406-236.406C527.713,271.155,527.713,254.525,517.472,244.066z    M314.982,328.353v-65.666H209.886v78.839h-52.493V236.451c0-14.42,11.816-26.258,26.236-26.258H314.96V144.55l91.902,91.902   L314.982,328.353z"
                            data-original="#000000"
                            className="active-path"
                            data-old_color="#e96400"
                            fill="currentColor"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className="ms-2"></span>
                </div>
              </div>
            </div>
          </section>
          {/* bar Section  */}
          <div id="tt-tabs-wrapper" style={{ backgroundColor: "#fff" }}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 py-sm-2 fix-header-cta">
                  <button
                    className="text-decoration-none btn btn-default x-go action-go btn-g engageAction"
                    style={{ color: "white" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-send-arrow-up-fill mx-1"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M15.854.146a.5.5 0 0 1 .11.54L13.026 8.03A4.5 4.5 0 0 0 8 12.5c0 .5 0 1.5-.773.36l-1.59-2.498L.644 7.184l-.002-.001-.41-.261a.5.5 0 0 1 .083-.886l.452-.18.001-.001L15.314.035a.5.5 0 0 1 .54.111ZM6.637 10.07l7.494-7.494.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338Z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.354-5.354a.5.5 0 0 0-.722.016l-1.149 1.25a.5.5 0 1 0 .737.676l.28-.305V14a.5.5 0 0 0 1 0v-1.793l.396.397a.5.5 0 0 0 .708-.708l-1.25-1.25Z"
                      />
                    </svg>
                    Going
                  </button>
                  <button className="text-decoration-none btn btn-default engageAction">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-star-fill me-1 text-muted"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                    </svg>
                    Add a Review
                  </button>
                  <button className="text-decoration-none btn btn-default engageAction">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-share-fill me-1 text-muted"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                    </svg>
                    Share & Invite
                  </button>
                  <button className="text-decoration-none btn btn-default action-save x-bk-t engageAction">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      fill="currentColor"
                      className="bi bi-bookmark-fill me-1 text-muted"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                    </svg>
                    Save
                  </button>
                  <ul className="float-end mb-0 nav">
                    <li>
                      <button
                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                        className="common-interest text-decoration-none payRegister btn btn-register btn-expo fw-bold bg-orange-l x-int-r action-rg  engageAction"
                        style={{ minWidth: "5rem" }}
                      >
                        Register
                      </button>
                      {/* <!-- Modal --> */}
                      <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog  modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h1 className="modal-title fs-5" id="staticBackdropLabel">Request a Booth</h1>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              <form action="">
                                <div className="row">
                                  <div className="col-6">
                                    <input type="text" className="form-control my-1" placeholder="First name" />
                                  </div>
                                  <div className="col-6">
                                    <input type="text" className="form-control my-1" placeholder="Last name" />
                                  </div>
                                  <div className="col-13">
                                    <input type="text" className="form-control my-1" placeholder="Email Address" />
                                  </div>
                                  <div className="col-6">
                                    <input type="text" className="form-control my-1" placeholder="Title/Role" />
                                  </div>
                                  <div className="col-6">
                                    <input type="text" className="form-control my-1" placeholder="Organization" />
                                  </div>
                                  <div className="col-13">

                                    <label htmlFor="inputState" className="form-label">What service you need?</label>
                                    <select id="inputState" className="form-select">
                                      <option value="" selected>Choose...</option>
                                      <option value="">SEO</option>
                                      <option value="">ADV</option>
                                      <option value="">DEVELOP</option>
                                    </select>
                                  </div>
                                  <div className="col-13">
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label">Tell Us About Your Project</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                  </div>
                                </div>

                              </form>
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn  btn-expo" data-bs-dismiss="modal">Send</button>
                              {/* <button type="button" className="btn btn-primary">Understood</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <span>
                        <div
                          data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                          className="text-decoration-none btn btn-register x-rabb fw-bold fs-20 bg-gray-dark"
                          style={{ color: "#fff", minWidth: "150px" }}
                        >
                          Request a Booth
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* main section */}
          <div className="pdb" id="main-wrapper ">
            <div id="main" className="container ">
              <div className="row ">
                {/* Left Section */}
                <div className="col-md-8 col-sm-12 about ">
                  <div className="row " id="content">
                    {/* bar section */}
                    <table
                      id="tt-tabs"
                      className="tt-tabs  fs-14 col-sm-12 mt-2 "
                    >
                      <tbody>
                        <tr className=" col-sm-12 row ">
                          <td id="about" className="col-md">
                            <span
                              className={`text-decoration-none cursor ${activeButton === "about" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("about");
                              }}
                            >
                              About
                            </span>
                          </td>
                          <td id="hubs" className="col-md">
                            <a
                              className={`text-decoration-none cursor ${activeButton === "feed" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("feed");
                              }}
                            >
                              Feed
                            </a>
                          </td>
                          <td id="visitors" className="col-md">
                            <a
                              className={`text-decoration-none cursor ${activeButton === "followers" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("followers");
                              }}
                            >
                              Followers
                              <span
                                className="badge badge-grey fs-12 fw-normal ms-1"
                                style={{ display: "inline" }}
                              >
                                {events.Followers.length}
                              </span>
                            </a>
                          </td>
                          <td id="exhibitors" className="col-md">
                            <a
                              className={`text-decoration-none cursor ${activeButton === "exhibitors" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("exhibitors");
                              }}
                            >
                              Exhibitors
                              <span
                                className="badge badge-grey fs-12 fw-normal ms-1"
                                style={{ display: "inline" }}
                              >
                                {events.Exhibitors.length ? events.Exhibitors.length : "0"}
                              </span>
                            </a>
                          </td>
                          <td id="speakers" className="col-md">
                            <span
                              className={`text-decoration-none cursor ${activeButton === "speakers" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("speakers");
                              }}
                            >
                              Speakers
                              <span
                                className="badge badge-grey fs-12 fw-normal ms-1"
                                style={{ display: "inline" }}
                              >
                                {events.Speakers.length}
                              </span>
                            </span>
                          </td>
                          <td id="reviews" className="col-md">
                            <a
                              className={`text-decoration-none cursor ${activeButton === "reviews" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("reviews");
                              }}
                            >
                              Reviews
                              <span
                                className="badge badge-grey fs-12 fw-normal ms-1"
                                style={{ display: "inline" }}
                              >
                                {event.EventData.reviews.length > 0
                                  ? event.EventData.reviews.length
                                  : ""}
                              </span>
                            </a>
                          </td>
                          <td id="deals" className="col-md">
                            <a
                              className={`text-decoration-none cursor ${activeButton === "deals" ? "active" : ""
                                }`}
                              onClick={() => {
                                handleButtonClick("deals");
                              }}
                            >
                              Deals
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div>{content}</div>
                  </div>
                </div>

                {/* Right Section */}
                <div className="col-md-4 col-sm-12" id="sidebar">
                  {/* Followers Section */}
                  <section
                    className="fs-14 box "
                    id="intrstbox"
                    style={{ backgroundColor: "#e7e7e7" }}
                  >
                    <span>
                      <a
                        href=""
                        className="d-flex text-decoration-none align-items-center"
                        style={{ textAlign: "center" }}
                      >
                        <img
                          className="position-relative rounded-circle lazyloaded"
                          src=" https://img.10times.com/user_pic%2F96s6A5YoT1PgkOvjAlmKTJuMaLyNszSPkxO3xQmp8ahGe%2BtYlKCUeCFjL9H6YpjIUwUfCwxxYLZKyF2pbOHQ%3D%3D.jpg?imgeng=/w_32/h_32/m_stretch/cmpr_60 "
                          data-src=" https://img.10times.com/user_pic%2F96s6A5YoT1PgkOvjAlmKTJuMaLyNszSPkxO3xQmp8ahGe%2BtYlKCUeCFjL9H6YpjIUwUfCwxxYLZKyF2pbOHQ%3D%3D.jpg?imgeng=/w_32/h_32/m_stretch/cmpr_60 "
                          alt=" Karan "
                          style={{
                            border: "2px solid #fff",
                            width: "32px",
                            height: "32px",
                          }}
                        />
                        <img
                          className="position-relative rounded-circle lazyloaded"
                          src=" https://img.10times.com/user_pic%2Fvo8y%2Bxbh2qK4C6JYibwCB7b4Dkz2Bfwqq2ACyDxvRpmGt5TwJuNtlo1ZhkdqaPeNz2TPg%2BDgMUtpCiyPSokg%3D%3D.jpg?imgeng=/w_32/h_32/m_stretch/cmpr_60 "
                          data-src=" https://img.10times.com/user_pic%2Fvo8y%2Bxbh2qK4C6JYibwCB7b4Dkz2Bfwqq2ACyDxvRpmGt5TwJuNtlo1ZhkdqaPeNz2TPg%2BDgMUtpCiyPSokg%3D%3D.jpg?imgeng=/w_32/h_32/m_stretch/cmpr_60 "
                          alt=" Reeshi Raj Gupta "
                          style={{
                            marginLeft: "-10px",
                            border: "2px solid #fff",
                            width: "32px",
                            height: "32px",
                          }}
                        />
                        <strong
                          className=" text-blue"
                          id="userCityFollowres"
                          style={{ marginLeft: "5px" }}
                        >
                          14 Followers
                        </strong>
                      </a>
                    </span>
                    <div className="mt-2">
                      <a
                        href=""
                        className="text-decoration-none"
                        style={{ color: "inherit" }}
                      >
                        <div className="text-black">
                          {" "}
                          Connect, Meet and Build a Strong Network
                        </div>
                      </a>
                    </div>
                  </section>

                  {/* advertise section */}
                  <section className="box box-ads">
                    <h5
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "280px", minHeight: "100px" }}
                    >
                      advertise with us
                    </h5>
                  </section>

                  {/* Related Events By Category section */}
                  <section className="box othr_rel_evt fs-14">
                    <h2
                      className="fw-bold"
                      style={{ fontSize: "1.2em", alignItems: "baseline" }}
                    >
                      Related Events By Category
                    </h2>
                    <table className="tb-list w-100">
                      <tbody>
                        {otherEvents.sameCategoryEvents.length > 0
                          ? otherEvents.sameCategoryEvents.map(
                            (event, index) => {
                              return (
                                <tr key={index} className="box-link">
                                  <td
                                    className="text-end text-muted"
                                    style={{
                                      width: "70px",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    {/* {event.StartDate} */}
                                    {moment(event.StartDate).format("MMM DD")}
                                    <small className="d-block">
                                      {event.StartDate.slice(0, 4)}
                                    </small>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-bookmark-fill float-end ms-4"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                                    </svg>
                                    <a
                                      className="text-blue text-decoration-none cursor"
                                      onClick={() => eventDetails(event)}
                                    >
                                      {event.Name}
                                    </a>{" "}
                                    <br />
                                    <span className="d-block text-muted">
                                      {event.City}, {event.Country}
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )
                          : ""}
                      </tbody>
                    </table>
                  </section>

                  {/* Featured Hotels section */}
                  <section className="box othr_rel_evt fs-14">
                    <h2
                      className="fw-bold"
                      style={{ fontSize: "1.2em", alignItems: "baseline" }}
                    >
                      Featured Hotels
                    </h2>
                  </section>

                  {/* advertise section */}
                  <section className="box box-ads">
                    <h5
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "280px", minHeight: "100px" }}
                    >
                      advertise with us
                    </h5>
                  </section>

                  {/* Related Events By Category section */}
                  <section className="box othr_rel_evt fs-14">
                    <h2
                      className="fw-bold"
                      style={{ fontSize: "1.2em", alignItems: "baseline" }}
                    >
                      Related Events By Category
                    </h2>
                    <table className="tb-list w-100">
                      <tbody>
                        {otherEvents.sameCategoryEvents.length > 0
                          ? otherEvents.sameCategoryEvents.map(
                            (event, index) => {
                              return (
                                <tr key={index} className="box-link">
                                  <td
                                    className="text-end text-muted"
                                    style={{
                                      width: "70px",
                                      verticalAlign: "top",
                                    }}
                                  >
                                    {/* {event.StartDate} */}
                                    {moment(event.StartDate).format("MMM DD")}
                                    <small className="d-block">
                                      {event.StartDate.slice(0, 4)}
                                    </small>
                                  </td>
                                  <td>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      className="bi bi-bookmark-fill float-end ms-4"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                                    </svg>
                                    <a
                                      className="text-blue text-decoration-none cursor"
                                      onClick={() => eventDetails(event)}
                                    >
                                      {event.Name}
                                    </a>{" "}
                                    <br />
                                    <span className="d-block text-muted">
                                      {event.City}, {event.Country}
                                    </span>
                                  </td>
                                </tr>
                              );
                            }
                          )
                          : ""}
                      </tbody>
                    </table>
                  </section>

                  {/* Video section */}
                  <section className="box box-ads">
                    <h5
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "280px", minHeight: "100px" }}
                    >
                      Video & gif
                    </h5>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <SpinnerComponent />
      )}
    </>
  );
};

export default EventsDetails;
