import React, { useEffect, useState } from "react";
import axiosInstance from "../../AxiosConfig/axiosinstance";

const ExhibitorEvent = () => {
  const result = localStorage.getItem("event");
  const event = JSON.parse(result);

  const [exhibitors, setExhibitors] = useState([]);

  const getAllExhibitors = async () => {
    try {
      const response = await axiosInstance.get(
        `/getEventExhibitors/${event.EventData.data._id}`
      );
      // console.log(response.data.data.Exhibitors);
      setExhibitors(response.data.data.Exhibitors);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllExhibitors();
    // console.log(exhibitors);
  }, []);

  return (
    <>
      {exhibitors.length > 0 ? (
        <section className="b5" style={{ background: "none" }}>
          <div className="box d-flex flex-wrap">
            <div className="col-md-8 col-sm-8">
              <h2 style={{ fontSize: "1.2em" }}>Exhibitors List</h2>
              <div id="exhibitorsFiltertotal" className="fs-14">
                Total
                <strong> {exhibitors.length} </strong>
                Exhibitors, showing
                <strong id="exh_onPage"> {exhibitors.length} </strong>
                Exhibitors
                <span id="filter_text">of Current Edition</span>
                <br />
              </div>
            </div>
            <div className="col-md-4 col-sm-4">
              <span className="input-group float-end">
                <input
                  type="text"
                  className="form-control fs-14"
                  placeholder="Search by Company name"
                />
                <span
                  className="input-group-addon text-center rounded-2 fs-14"
                  style={{
                    padding: "9px 12px",
                    lineHeight: "1",
                    color: "#555",
                    backgroundColor: "#eee",
                    border: "1px solid #ccc",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-search"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </span>
              </span>
            </div>
          </div>
          <div className="mt-3 d-flex flex-wrap">
            {exhibitors.map((exhibit, index) => {
              const isTenthElement = (index + 1) % 10 === 0; // Check if it's the tenth element
              const elementToRender = (
                <div
                  key={index}
                  className="exhibitorsBlock row py-2 mx-0 mb-2 bg-white deep-shadow"
                >
                  <div className="col-12 text-dark">
                    <div className="small fw-500">
                      <span>Exhibited In Nov 2018</span>
                    </div>
                  </div>
                  <div className="col-12 text-break">
                    <h2
                      className="exhibitorName position-relative mb-0"
                      style={{ fontSize: "1.2em " }}
                    >
                      <span className="position-absolute end-0">
                        {exhibit.Banner ? (
                          <img
                            className="rounded-3 float-end img-48 lazyloaded"
                            src={exhibit.Banner}
                            alt={exhibit.Name}
                            loading="lazy"
                          />
                        ) : (
                          ""
                        )}
                      </span>
                      <span
                        className="d-block"
                        style={{ marginRight: "3.5rem" }}
                      >
                        <span> {exhibit.Name}</span>
                        <span className="d-inline-block">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-shield-check mx-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                            <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                          </svg>
                        </span>
                      </span>
                    </h2>
                  </div>
                  <div className="col-12 mb-2">
                    <span className="small">
                      {exhibit.City !== "N/A" ? `${exhibit.City},` : ""}{" "}
                      {exhibit.Country}
                    </span>
                  </div>
                  <div
                    className="col-12 small text-muted mb-2"
                    style={{ lineHeight: "2" }}
                  >
                    <span className="d-inline-block small me-2 p-1 lh-1 bg-light rounded-1">
                      <span className="website engageAction text-decoration-none cursor fw-500 text-blue exhibitrs_website">
                        <a
                          href={
                            exhibit.Website !== "Null" ? exhibit.Website : ""
                          }
                          className="text-decoration-none text-blue"
                        >
                          Website
                        </a>
                      </span>
                    </span>
                  </div>
                  <div
                    className="py-2 mt-3 w-100 d-flex"
                    style={{ lineHeight: "2" }}
                  >
                    <div className="small text-center rounded-3 bg-light">
                      <button className="exhibitorEnquiry btn btn-sm btn-orange typeSort fs-12 border-0 border-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="white"
                          className="bi bi-star-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>{" "}
                        Follow
                      </button>
                      <span className="show_pointer interestedContent px-2 fs-12 cursor position-relative">
                        <span className="intrestNumbers fs-12">1</span>
                      </span>
                    </div>
                  </div>
                </div>
              );
              if (isTenthElement && index !== 0) {
                return (
                  <>
                    <section className="box fs-14">
                      <h5
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100px", minHeight: "100px" }}
                      >
                        advertise with us
                      </h5>
                    </section>
                    {elementToRender}
                  </>
                );
              }
              return elementToRender;
            })}
          </div>
        </section>
      ) : (
        <section
          className="box"
          style={{ height: "100px", minHeight: "100px", textAlign: "center" }}
        >
There is no exhibitor mapped to this event. Want to Exhibit?
          <button className="btn btn-expo d-block m-auto mt-2">
            Request booth
          </button>
        </section>
      )}
    </>
  );
};

export default ExhibitorEvent;
