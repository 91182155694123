const initialValue = {
    phoneNumber: "",
    countryCode: "",
    userInfo: "",
    location: "",
    eventDetails: "",
    isAuthenticated: false,
    username: null,
}

export default function mobileCountryCodeReducer(state = initialValue, action) {
    switch (action.type) {
        case "GET_MOBILENUMBER":
            return { ...state, phoneNumber: action.payload };
        case "GET_COUNTRYCODE":
            return { ...state, countryCode: action.payload };
        case "GET_USERINFO":
            return { ...state, userInfo: action.payload };
        case "GET_LOCATION":
            return { ...state, location: action.payload };
        case "GET_EVENTDETAILS":
            return { ...state, eventDetails: action.payload };
        case 'LOGIN':
            return {...state, isAuthenticated: true,
                username: action.payload.username,};
        case 'LOGOUT':
            return {...state,isAuthenticated: false,
                username: null,};
        default:
            return state
    }
}