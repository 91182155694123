import React from 'react';
import { useNavigate } from 'react-router-dom';

import './top100.css'


const Top100 = () => {
    const navigate = useNavigate();
    const handelNavigate = () => {
        navigate('/')
    }
    return (
        // <>
        //     <header>
        //         <section className='wrapper' style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
        //             <div className='container text-white py-5'>
        //                 <div className="row">
        //                     <div className="col-8">
        //                         <h1 className='text-white p-0 m-0'>
        //                             Top 100  Events
        //                         </h1>
        //                         <div className='mb-3'>
        //                             Ranking of popular trade fairs, trade shows & conferences you should participate. The only global event ranking table to calibrate the performance of events in their core missions after carefully considering factors like : audience reach,  opportunities created, size, rating, format and international outlook.
        //                         </div>
        //                     </div>
        //                     <div className="col-4 text-end my-auto">
        //                         <div className='mb-3'>
        //                             <img src="./image/expo-white.png" alt="logo" width={100} height={50} />
        //                         </div>
        //                         <form autoComplete='off' className='d-flex justify-content-between'>
        //                             <select id="selectIndustry" className="form-select top100Combo" name="industry" autoComplete="off" aria-label="industry" style={{ width: '45%' }}>
        //                                 <option value>All Industry</option>
        //                                 <option className="text-muted" value="vascular-surgery"># Vascular Surgery</option>
        //                                 <option className="text-muted" value="vegan-food"># Vegan Food</option>
        //                             </select>

        //                             <select id="selectCountry" className="form-select top100Combo" name="country" autoComplete="off" aria-label="country" style={{ width: '45%' }}><option value>All Country</option><option data-id="AL" value="egypt">Egypt</option>
        //                             </select>
        //                         </form>
        //                     </div>
        //                 </div>
        //             </div>
        //         </section>
        //     </header>
        //     <main className='container'>
        //         <table className='table table-responsive table-hover mt-5 shadow' id='event-list'>
        //             <thead style={{ backgroundColor: "#ddd" }}>
        //                 <th className="fw-bold border-0">Rank</th>
        //                 <th className="fw-bold border-0">Event</th>
        //                 <th className="fw-bold border-0">Latest Dates</th>
        //                 <th className="fw-bold border-0">Location</th>
        //                 <th className="fw-bold border-0">Category</th>
        //                 <th className="fw-bold border-0">Rating</th>
        //                 <th className="fw-bold border-0"></th>
        //             </thead>
        //             <tbody>
        //                 <tr className='align-middle top100-event'>
        //                     <td className='box-link'>
        //                         <a href="" className='d-flex justify-content-center align-items-center text-decoration-none text-white rounded-circle' style={{ width: "40px", height: "40px", backgroundColor: "#b13b3b" }}>1</a>
        //                     </td>
        //                     <td >
        //                         <div className='d-flex'>
        //                             <a href="" className='text-decoration-none me-2'>
        //                                 <img className="bg-white p-1 border rounded-2 ls-is-cached lazyloaded" src="https://img.10times.com/images/event/13039/eventlogo-23-1623094776.jpg?imgeng=/w_45/h_45/m_stretch/cmpr_60" width={45} height={45} alt="MAGIC LAS VEGAS" loading="lazy" />
        //                             </a>
        //                             <div className='w-100 name'>
        //                                 <a href="" className='text-decoration-none fw-bold d-inline-block'>
        //                                     <span className="line-2">MAGIC LAS VEGAS</span>
        //                                 </a>
        //                             </div>
        //                         </div>
        //                     </td>
        //                     <td className='small date'>
        //                         <div className='text-muted'>13 - 15 Feb 2024</div>
        //                     </td>
        //                     <td className="small">
        //                         <div className="text-muted">Qena</div>
        //                         <a href="" className='text-decoration-none'>Eygpt</a>
        //                     </td>
        //                     <td>
        //                         <a href="" className='text-decoration-none d-block'>Fashion &amp; Beauty</a>
        //                         <a href="" className='text-decoration-none'>Apparel &amp; Clothing</a>
        //                     </td>
        //                     <td>
        //                         <a
        //                             href="#"
        //                             className="badge fw-500 text-decoration-none interested"
        //                             style={{
        //                                 backgroundColor: "#198754",
        //                             }}
        //                         >
        //                             5
        //                             <svg
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 width="13"
        //                                 height="13"
        //                                 fill="currentColor"
        //                                 className="bi bi-star-fill ms-1"
        //                                 viewBox="0 0 16 16"
        //                             >
        //                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        //                             </svg>
        //                         </a>
        //                     </td>
        //                     <td className="action text-end">
        //                         <div className='small text-muted mb-2'>
        //                             6534 Following
        //                         </div>
        //                         <button className='btn btn-outline-expo btn-sm px-2 a-m '>
        //                             <svg
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 width="13"
        //                                 height="13"
        //                                 fill="currentcolor"
        //                                 className="bi bi-star-fill ms-1"
        //                                 viewBox="0 0 16 16"
        //                             >
        //                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        //                             </svg>
        //                             <span className=" "> Interested </span>
        //                         </button>
        //                     </td>
        //                 </tr>
        //                 <tr className='align-middle top100-event'>
        //                     <td className='box-link'>
        //                         <a href="" className='d-flex justify-content-center align-items-center text-decoration-none text-white rounded-circle' style={{ width: "40px", height: "40px", backgroundColor: "#b13b3b" }}>1</a>
        //                     </td>
        //                     <td >
        //                         <div className='d-flex'>
        //                             <a href="" className='text-decoration-none me-2'>
        //                                 <img className="bg-white p-1 border rounded-2 ls-is-cached lazyloaded" src="https://img.10times.com/images/event/13039/eventlogo-23-1623094776.jpg?imgeng=/w_45/h_45/m_stretch/cmpr_60" width={45} height={45} alt="MAGIC LAS VEGAS" loading="lazy" />
        //                             </a>
        //                             <div className='w-100 name'>
        //                                 <a href="" className='text-decoration-none fw-bold d-inline-block'>
        //                                     <span className="line-2">MAGIC LAS VEGAS</span>
        //                                 </a>
        //                             </div>
        //                         </div>
        //                     </td>
        //                     <td className='small date'>
        //                         <div className='text-muted'>13 - 15 Feb 2024</div>
        //                     </td>
        //                     <td className="small">
        //                         <div className="text-muted">Qena</div>
        //                         <a href="" className='text-decoration-none'>Eygpt</a>
        //                     </td>
        //                     <td>
        //                         <a href="" className='text-decoration-none d-block'>Fashion &amp; Beauty</a>
        //                         <a href="" className='text-decoration-none'>Apparel &amp; Clothing</a>
        //                     </td>
        //                     <td>
        //                         <a
        //                             href="#"
        //                             className="badge fw-500 text-decoration-none interested"
        //                             style={{
        //                                 backgroundColor: "#198754",
        //                             }}
        //                         >
        //                             5
        //                             <svg
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 width="13"
        //                                 height="13"
        //                                 fill="currentColor"
        //                                 className="bi bi-star-fill ms-1"
        //                                 viewBox="0 0 16 16"
        //                             >
        //                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        //                             </svg>
        //                         </a>
        //                     </td>
        //                     <td className="action text-end">
        //                         <div className='small text-muted mb-2'>
        //                             6534 Following
        //                         </div>
        //                         <button className='btn btn-outline-expo btn-sm px-2 a-m '>
        //                             <svg
        //                                 xmlns="http://www.w3.org/2000/svg"
        //                                 width="13"
        //                                 height="13"
        //                                 fill="currentcolor"
        //                                 className="bi bi-star-fill ms-1"
        //                                 viewBox="0 0 16 16"
        //                             >
        //                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        //                             </svg>
        //                             <span className=" "> Interested </span>
        //                         </button>
        //                     </td>
        //                 </tr>
        //                 <tr>
        //                     <section className=" fs-14">
        //                         <h5
        //                             className="d-flex justify-content-center align-items-center text-center"

        //                         >
        //                             advertise with us
        //                         </h5>
        //                     </section>
        //                 </tr>
        //             </tbody>
        //         </table>
        //     </main>
        //     <h3>Top 100 Events</h3>
        //     <button onClick={() => handelNavigate()} className='btn btn-primary'>Back To Home</button>

        // </>
        <main className="main under-construction">
            <section className="contact my-5">
                <h1 className="title text-center">Awesome Thing</h1>
                <h2 className="sub-title text-center">Site Under Construction</h2>
                <p className="d-flex justify-content-center" style={{marginTop:"-7em"}}>
                <img src="https://png.pngtree.com/background/20230725/original/pngtree-website-under-construction-domain-webhoster-code-photo-picture-image_4298984.jpg" alt="under-construction" className="rounded-5 img-fluid" style={{zIndex:"-1"}} />

                </p>
            </section>
        </main>
    );
}

export default Top100;
