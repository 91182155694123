import { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { User } from "../../Contexts/UserContexts";
import Cookies from 'universal-cookie';
import axiosInstance from "../../AxiosConfig/axiosinstance";
import { toast } from 'react-hot-toast';
import SpinnerComponent from "../../Components/Spinner/spinner";

export default function PersistLogin() {

    //Get Current user 
    const context = useContext(User);
    const token = context.auth.token;

    const [loading, setLoading] = useState(true);

    const location = useLocation();

    // get and set Cookie
    const cookie = new Cookies();
    // const tokenCookie = cookie.set('Bearer', token);
    const getToken = cookie.get('Bearer')
    // console.log(context);
    // console.log(getToken);

    const navigate = useNavigate();

    useEffect(() => {

        const newToken = localStorage.getItem("accessToken");
        const userInfo = localStorage.getItem("userInfo");
        
        // handle get new token nd user details
        async function getToken() {
            //set cookie
            cookie.set('Bearer', newToken);
            context.setAuth((prev) => {
                return {
                    token: newToken,
                    userDetails: JSON.parse(userInfo),
                }
            });

            try {
                // let res = await axiosInstance.post('/login', {
                //     "email": "amrking006@1gmail.com",
                //     "password": "1231231123"
                // }).then((result) => {
                //     // console.log(result.data);
                //     const newToken = result.data.token;
                //     const newUserDetails = result.data.userDetails;

                //     // toast.success(result.data.message);
                //     // navigate('/profile');
                // })

            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false)
            }
        }
        !token ? getToken() : setLoading(false);
    }, [])

    return (loading ? <SpinnerComponent /> : <Outlet />)
}