import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Col, Container, Image, Modal, Row } from 'react-bootstrap';
import axiosInstance from '../../AxiosConfig/axiosinstance';
import SpinnerComponent from '../../Components/Spinner/spinner';
import InfiniteScroll from "react-infinite-scroll-component";
import moment from 'moment';

const Companies = () => {

    // handel alert for country
    const [showCountry, setShowCountry] = useState(false);
    const [showCategory, setShowCategory] = useState(false);
    const handleCloseCountry = () => setShowCountry(false);
    const handleShowCountry = () => setShowCountry(true);
    const handleCloseCategory = () => setShowCategory(false);
    const handleShowCategory = () => setShowCategory(true);
    const [companies, setCompanies] = useState([]);
    const [featuredEvents, setFeaturedEvents] = useState([]);
    const [countries, setCountries] = useState();
    const [categories, setCategories] = useState();
    const [emptyCompany, setEmptyCompany] = useState("");
    const [filters, setFilters] = useState({
        country: "",
        category: "",
        event: "",
        follower: "",
        limit: "200",
    });

    const cardColors = [
        "linear-gradient(90deg, #F8F5F0, #F4CB8C)",
        "linear-gradient(90deg, #F6FCF2, #D1F2B8)",
        "linear-gradient(90deg, #F5F7FB, #B4C4DA)",
    ];


    // function to get all country and category in filter 
    const getFilterData = async function () {
        try {
            const response = await axiosInstance.get('/filterCompanyInfo');
            // console.log(response.data.data.Category);
            setCountries(response.data.data.Country);
            setCategories(response.data.data.Category);

        } catch (error) {
            console.log(error);
        }
    }

    // function to get all Companies
    const getAllCompanies = async function () {
        // console.log("hello form Company function");
        let currentPage = Math.ceil(companies.length / 20) + 1;
        try {
            // const response = await axiosInstance.get(`/displayCompany?limit=30&page=0`);
            const response = await axiosInstance.get(`/displayCompany?limit=30&page=${currentPage - 1}`);
            // console.log(response.data);
            setCompanies((prevCompany) => [...prevCompany, ...response.data.company]);
            setFeaturedEvents((prevFeatured) => [...prevFeatured, ...response.data.featuredEvents]);

        } catch (error) {
            console.log(error);
        }
    }

    const [result, setResult] = useState();
    //function to make filter in company
    const filterCompany = async function (e) {
        setResult(true);
        // console.log(e.value, e.name);

        const newFilters = { ...filters };
        switch (e.name) {
            case "country":
                newFilters.country = e.value;
                break;
            case "limit":
                newFilters.limit = 200;
                break;
            case "event":
                newFilters.event = encodeURIComponent(e.value);
                break;
            case "follower":
                newFilters.follower = encodeURIComponent(e.value);
                break;
            case "category":
                newFilters.category = encodeURIComponent(e.value);
                break;
            default:
                break;
        }

        let currentPage = 0;

        try {
            const response = await axiosInstance.get(`/filterCompany?followers=${newFilters.follower}&events=${newFilters.event}&limit=100&country=${newFilters.country}&category=${newFilters.category}`);
            if (response.data.filteredResults == "") {
                console.log("no data");
                setEmptyCompany("Unfortunately, there are no Companies here");
            }
            setCompanies(response.data.filteredResults);
            setResult((prev) => !prev);
            // console.log(response.data.filteredResults);

        } catch (error) {
            console.log(error);
        }

        setFilters(newFilters);
    }

    useEffect(() => {
        // getAllCompanies();
        // getFilterData()

    }, []);

    return (
        // <>
        //     <header className='company pt-4 mb-3'>
        //         <div className='container'>
        //             <div className='pb-3'>
        //                 <h1 className="mb-0" style={{ fontSize: "2em" }}>Top Companies</h1>
        //                 <div className='my-2 fw-bold small pt-md-2 pb-1'>
        //                     The world's most popular companies in MICE world. The companies are ranked on the basis of their event participation and their popularity in the industry. The list includes companies from  all industries.
        //                 </div>
        //             </div>
        //         </div>
        //     </header>
        //     {result ? <SpinnerComponent /> : ""}
        //     <div className='main-wrapper ' id='main' style={{ backgroundColor: "#f8f9fa" }}>
        //         <Container className="py-3">
        //             <div className="row" id='content-main'>
        //                 {/* Start Filter Section*/}
        //                 <Col sm={12} md={6} lg={3} >
        //                     <div
        //                         id=""
        //                         className="box bg-white deep-shadow position-relative"
        //                     >
        //                         {/*Start Location Section */}
        //                         <h2 className='accordion-header'>
        //                             <button className='d-flex justify-content-between align-items-center accordion-button px-3 py-2 cursor-default'>
        //                                 <span className='fw-500'>
        //                                     Location
        //                                 </span>
        //                             </button>
        //                         </h2>
        //                         <div
        //                             id="accordion-filter"
        //                             className="accordion accordion-flush"
        //                         >
        //                             {countries ? Object.keys(countries).map((country, index) => {
        //                                 if (index < 5) {
        //                                     return (
        //                                         <div
        //                                             key={index}
        //                                             className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
        //                                         >
        //                                             <div className="form-check">
        //                                                 <input
        //                                                     name="country"
        //                                                     className="form-check-input"
        //                                                     type="radio"
        //                                                     value={country}
        //                                                     id={country}
        //                                                     onChange={(e) =>
        //                                                         filterCompany(e.target)
        //                                                     }
        //                                                 />
        //                                                 <label
        //                                                     className="form-check-label"
        //                                                     htmlFor={country}
        //                                                 >
        //                                                     {country}
        //                                                 </label>
        //                                             </div>
        //                                             <small className="text-blue fw-500 ms-2 ">
        //                                                 {countries[country].Number}
        //                                             </small>

        //                                         </div>
        //                                     )
        //                                 }
        //                                 return null;

        //                             }) : ""}


        //                             <button
        //                                 onClick={handleShowCountry}
        //                                 id="page-share"
        //                                 className="d-flex justify-content-center btn btn-sm btn-light rounded-4 py-1 hShadow share-page share_top w-50 my-3"
        //                                 aria-label="share"
        //                                 style={{
        //                                     boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)",
        //                                 }}
        //                             >
        //                                 {" "}
        //                                 View All{" "}
        //                             </button>
        //                             <hr />

        //                             {/* modal */}
        //                             <Modal
        //                                 show={showCountry}
        //                                 onHide={handleCloseCountry}
        //                                 size="lg"
        //                             >
        //                                 <Modal.Header closeButton>
        //                                     <Modal.Title>List of Countries</Modal.Title>
        //                                 </Modal.Header>
        //                                 <Modal.Body>
        //                                     <Container>
        //                                         <Row  >
        //                                             {countries ? Object.keys(countries).map((country, index) => {
        //                                                 if (index < 500) {
        //                                                     return (
        //                                                         <div className='col-4' key={index}>
        //                                                             <div
        //                                                                 className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 "

        //                                                             >
        //                                                                 <div
        //                                                                     className="form-check d-flex btn text-muted text-start fw-normal w-100 h-100 bg-light rounded-3">
        //                                                                     <input
        //                                                                         name="country"
        //                                                                         className="form-check-input d-none "
        //                                                                         type="radio"
        //                                                                         value={country}
        //                                                                         id={country}
        //                                                                         onChange={(e) =>
        //                                                                             filterCompany(e.target)
        //                                                                         }
        //                                                                     />

        //                                                                     <label
        //                                                                         className="form-check-label cursor"
        //                                                                         htmlFor={country}

        //                                                                     >
        //                                                                         {country}
        //                                                                     </label>
        //                                                                     <small
        //                                                                         className="text-blue fw-500 ms-2"
        //                                                                         style={{ color: "#1f5d84" }}
        //                                                                     >
        //                                                                         {
        //                                                                             countries[country]
        //                                                                                 .Number
        //                                                                         }
        //                                                                     </small>
        //                                                                 </div>
        //                                                             </div>
        //                                                         </div>
        //                                                     );
        //                                                 }
        //                                                 return null;

        //                                             }) : ""}


        //                                         </Row>
        //                                     </Container>
        //                                 </Modal.Body>
        //                                 <Modal.Footer>
        //                                     <Button
        //                                         variant="secondary"
        //                                         onClick={handleCloseCountry}
        //                                     >
        //                                         Close
        //                                     </Button>
        //                                     <Button
        //                                         variant="primary"
        //                                         onClick={handleCloseCountry}
        //                                     >
        //                                         Save Changes
        //                                     </Button>
        //                                 </Modal.Footer>
        //                             </Modal>
        //                         </div>
        //                         {/*End Location Section */}

        //                         {/*Start Category Section */}
        //                         <h2 className='accordion-header'>
        //                             <button className='d-flex justify-content-between align-items-center accordion-button px-3 py-2 cursor-default'>
        //                                 <span className='fw-500'>
        //                                     Category
        //                                 </span>
        //                             </button>
        //                         </h2>
        //                         <div
        //                             id="accordion-filter"
        //                             className="accordion accordion-flush"
        //                         >
        //                             {categories ? Object.keys(categories).map((category, index) => {
        //                                 if (index < 6) {
        //                                     return (
        //                                         <div key={index} className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga">
        //                                             <div className="form-check mt-2">
        //                                                 <input
        //                                                     name="category"
        //                                                     className="form-check-input"
        //                                                     type="radio"
        //                                                     value={category}
        //                                                     id={category}
        //                                                     onChange={(e) =>
        //                                                         filterCompany(e.target)
        //                                                     }
        //                                                 />
        //                                                 <label
        //                                                     className="form-check-label"
        //                                                     htmlFor={category}
        //                                                 >
        //                                                     {category}
        //                                                 </label>
        //                                             </div>
        //                                             <small className="text-blue fw-500 ms-2">
        //                                                 {categories[category].Number}
        //                                             </small>

        //                                         </div>
        //                                     )
        //                                 }
        //                                 return null;
        //                             }) : ""}
        //                             <button
        //                                 onClick={handleShowCategory}
        //                                 id="page-share"
        //                                 className="d-flex justify-content-center btn btn-sm btn-light rounded-4 py-1 hShadow share-page share_top w-50 my-3"
        //                                 aria-label="share"
        //                                 style={{
        //                                     boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)",
        //                                 }}
        //                             >
        //                                 {" "}
        //                                 View All{" "}
        //                             </button>
        //                             <hr />
        //                             {/* modal */}
        //                             <Modal
        //                                 show={showCategory}
        //                                 onHide={handleCloseCategory}
        //                                 size="lg"
        //                             >
        //                                 <Modal.Header closeButton>
        //                                     <Modal.Title>List of Categories</Modal.Title>
        //                                 </Modal.Header>
        //                                 <Modal.Body>
        //                                     <Container>
        //                                         <Row  >
        //                                             {categories ? Object.keys(categories).map((category, index) => {
        //                                                 if (index < 500) {
        //                                                     return (
        //                                                         <div style={{ overflowY: "auto" }} className='col-4' key={index}>
        //                                                             <div
        //                                                                 className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 "

        //                                                             >
        //                                                                 <div
        //                                                                     className="form-check d-flex btn text-muted text-start fw-normal w-100 h-100 bg-light rounded-3">
        //                                                                     <input
        //                                                                         name="category"
        //                                                                         className="form-check-input d-none "
        //                                                                         type="radio"
        //                                                                         value={category}
        //                                                                         id={category}
        //                                                                         onChange={(e) =>
        //                                                                             filterCompany(e.target)
        //                                                                         }
        //                                                                     />

        //                                                                     <label
        //                                                                         className="form-check-label cursor"
        //                                                                         htmlFor={category}
        //                                                                     >
        //                                                                         {category}
        //                                                                     </label>
        //                                                                     <small
        //                                                                         className="text-blue fw-500 ms-2"
        //                                                                         style={{ color: "#1f5d84" }}
        //                                                                     >
        //                                                                         {
        //                                                                             categories[category].Number

        //                                                                         }
        //                                                                     </small>
        //                                                                 </div>
        //                                                             </div>
        //                                                         </div>
        //                                                     );
        //                                                 }
        //                                                 return null;

        //                                             }) : ""}


        //                                         </Row>
        //                                     </Container>
        //                                 </Modal.Body>
        //                                 <Modal.Footer>
        //                                     <Button
        //                                         variant="secondary"
        //                                         onClick={handleCloseCategory}
        //                                     >
        //                                         Close
        //                                     </Button>
        //                                     <Button
        //                                         variant="primary"
        //                                         onClick={handleCloseCategory}
        //                                     >
        //                                         Save Changes
        //                                     </Button>
        //                                 </Modal.Footer>
        //                             </Modal>
        //                         </div>
        //                         {/*End Category Section */}


        //                         {/*Start Events Section */}
        //                         <h2 className='accordion-header'>
        //                             <button className='d-flex justify-content-between align-items-center accordion-button px-3 py-2 cursor-default'>
        //                                 <span className='fw-500'>
        //                                     Events
        //                                 </span>
        //                             </button>
        //                         </h2>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="event"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="0 10"
        //                                 id="10"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }
        //                             />
        //                             <label className="form-check-label" htmlFor="10">
        //                                 Less than 10
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="event"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="10 30"
        //                                 id="30"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }

        //                             />
        //                             <label className="form-check-label" htmlFor="30">
        //                                 10-30
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="event"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="30 50"
        //                                 id="50"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }
        //                             />
        //                             <label className="form-check-label" htmlFor="50">
        //                                 30-50
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="event"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="50 100"
        //                                 id="100"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }
        //                             />
        //                             <label className="form-check-label" htmlFor="100">
        //                                 50-100
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="event"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="100 1000"
        //                                 id="+100"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }
        //                             />
        //                             <label className="form-check-label" htmlFor="+100">
        //                                 +100
        //                             </label>
        //                         </div>
        //                         <hr />
        //                         {/*End Events Section */}

        //                         {/*Start Followers Section */}
        //                         <h2 className='accordion-header'>
        //                             <button className='d-flex justify-content-between align-items-center accordion-button px-3 py-2 cursor-default'>
        //                                 <span className='fw-500'>
        //                                     Followers
        //                                 </span>
        //                             </button>
        //                         </h2>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="follower"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="1 5"
        //                                 id="1-5"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }

        //                             />
        //                             <label className="form-check-label" htmlFor="1-5">
        //                                 1-5
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="follower"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="5 10"
        //                                 id="5-10"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }

        //                             />
        //                             <label className="form-check-label" htmlFor="5-10">
        //                                 5-10
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="follower"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="10 50"
        //                                 id="10-50"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }
        //                             />
        //                             <label className="form-check-label" htmlFor="10-50">
        //                                 10-50
        //                             </label>
        //                         </div>
        //                         <div className="form-check my-3">
        //                             <input
        //                                 name="follower"
        //                                 className="form-check-input"
        //                                 type="radio"
        //                                 value="50 1000"
        //                                 id="50-100"
        //                                 onChange={(e) =>
        //                                     filterCompany(e.target)
        //                                 }
        //                             />
        //                             <label className="form-check-label" htmlFor="50-100">
        //                                 +50
        //                             </label>
        //                         </div>
        //                     </div>
        //                 </Col>
        //                 {/* End Filter Section*/}




        //                 {/* Start Company Section*/}
        //                 <div className="col-md-5">
        //                     {companies == "" ?
        //                         (emptyCompany !== "" ?
        //                             (<h5>{emptyCompany}</h5>) :
        //                             (<SpinnerComponent />)
        //                         ) :
        //                         (
        //                             <InfiniteScroll
        //                                 dataLength={companies.length}
        //                                 next={getAllCompanies}
        //                                 // next={handelCheckCalendar}
        //                                 // style={{
        //                                 //   display: "flex",
        //                                 //   flexDirection: "column-reverse",
        //                                 // }} //To put endMessage and loader to the top.
        //                                 // inverse={true} //
        //                                 hasMore={true}
        //                                 loader={<h4>Loading...</h4>}
        //                             //  scrollableTarget="scrollableDiv"
        //                             >
        //                                 {companies ? companies.map((company, index) => {
        //                                     return (
        //                                         <div
        //                                             id="sort-by"
        //                                             className="   small  mb-3 bg-white deep-shadow"
        //                                             style={{ boxShadow: "0 1px 4px rgba(0,0,0,.18)" }}
        //                                             key={index}
        //                                         >
        //                                             <a
        //                                                 className=" scroller-item text-dark text-decoration-none  rounded-3 link"
        //                                             //   onClick={(e) =>
        //                                             //     eventDetails(event)
        //                                             //   }
        //                                             >
        //                                                 <Card className="border-0">
        //                                                     <Card.Body className="p-2">
        //                                                         {/* name and date */}
        //                                                         <Row>
        //                                                             <Col className="col-9">
        //                                                                 <span className="eventName d-block event-name cursor">
        //                                                                     {company.Name}
        //                                                                 </span>
        //                                                                 <Card.Subtitle className="event-location fw-500 mt-2 mb-3">
        //                                                                     {company.City !== "N/A" ? `${company.City},` : ""} {company.Country}
        //                                                                 </Card.Subtitle>
        //                                                             </Col>
        //                                                             <Col className="col-3 d-flex justify-content-end ">
        //                                                                 {company.Banner ? <Image
        //                                                                     className="event-img "
        //                                                                     src={company.Banner}
        //                                                                 /> : ""}

        //                                                             </Col>

        //                                                             <Col className="col-12 mt-3">
        //                                                                 <div className="event-prev text-wrap text-break">

        //                                                                 </div>
        //                                                             </Col>
        //                                                             <Col className='mt-3 small'>
        //                                                                 <div className='text-muted d-inline-block me-3'>
        //                                                                     <span className="me-1">Hosted Events:</span>
        //                                                                     <span className="text-dark fw-500">{company.NumberOfEvents
        //                                                                     }</span>
        //                                                                 </div>
        //                                                                 <div className='text-muted d-inline-block me-3'>
        //                                                                     <span className="me-1">Upcoming Events:</span>
        //                                                                     <span className="text-dark fw-500">{company.UpcomingEvent
        //                                                                         .length
        //                                                                     }</span>
        //                                                                 </div>

        //                                                             </Col>
        //                                                         </Row>

        //                                                         {/* category and topic */}
        //                                                         <div className="mt-1 small text-muted mb-2">
        //                                                             {company.Category.map((cat, index) => {
        //                                                                 return (
        //                                                                     <span
        //                                                                         key={index}
        //                                                                         className="  me-2 p-1  bg-light rounded-1 text-muted event-category cursor"
        //                                                                     >
        //                                                                         {cat}
        //                                                                     </span>
        //                                                                 )
        //                                                             })}
        //                                                         </div>

        //                                                         {/* footer section */}
        //                                                         <Col>
        //                                                             <div className="d-flex justify-content-between align-items-center mb-2 ">
        //                                                                 {/* Interested */}
        //                                                                 <div
        //                                                                     className="small text-center rounded-3 bg-light"
        //                                                                     style={{
        //                                                                         color: " #161616",
        //                                                                         border: "2px solid #e7e7e7",
        //                                                                     }}
        //                                                                 >
        //                                                                     <button className=" btn btn-sm fw-600 border-0 border-end rounded-0 bg-white a-m c-ga xn interested">
        //                                                                         <svg
        //                                                                             xmlns="http://www.w3.org/2000/svg"
        //                                                                             width="16"
        //                                                                             height="16"
        //                                                                             fill="currentColor"
        //                                                                             className="bi bi-ticket-detailed-fill me-1"
        //                                                                             viewBox="0 0 16 16"
        //                                                                             style={{ rotate: "-40deg" }}
        //                                                                         >
        //                                                                             <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4 1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5Zm0 5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5ZM4 8a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1Z" />
        //                                                                         </svg>
        //                                                                         <span
        //                                                                             className="interested fw-600"
        //                                                                             style={{ fontWeight: "600" }}
        //                                                                         >
        //                                                                             Follow
        //                                                                         </span>
        //                                                                     </button>
        //                                                                     <a
        //                                                                         href="#"
        //                                                                         className="small fw-500 text-decoration-none px-2 xn text-dark interested"
        //                                                                     >
        //                                                                         {company.FollowersNumber}
        //                                                                     </a>
        //                                                                 </div>
        //                                                                 {/* rating */}
        //                                                                 <div>
        //                                                                     <span
        //                                                                         className="float-end "
        //                                                                         style={{ marginTop: "5px" }}
        //                                                                     >
        //                                                                         <a
        //                                                                             className="badge fw-500 text-decoration-none text-muted fs-6"
        //                                                                         >
        //                                                                             {company.Rating.Average ? company.Rating.Average : 4.9}
        //                                                                             <svg
        //                                                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                                                 width="13"
        //                                                                                 height="13"
        //                                                                                 fill="currentColor"
        //                                                                                 className="bi bi-star-fill ms-1 text-warning"
        //                                                                                 viewBox="0 0 16 16"
        //                                                                             >
        //                                                                                 <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
        //                                                                             </svg>
        //                                                                         </a>
        //                                                                     </span>
        //                                                                 </div>
        //                                                             </div>
        //                                                         </Col>
        //                                                     </Card.Body>
        //                                                 </Card>
        //                                             </a>
        //                                         </div>
        //                                     )
        //                                 }) : ""}
        //                             </InfiniteScroll>
        //                         )
        //                     }

        //                 </div>
        //                 {/* End Company Section*/}


        //                 {/* Start Adsenss Section*/}
        //                 <Col md={4} lg={4}>
        //                     <section className="box fs-14">
        //                         <h5
        //                             className="d-flex justify-content-center align-items-center"
        //                             style={{ height: "100px", minHeight: "100px" }}
        //                         >
        //                             advertise with us
        //                         </h5>
        //                     </section>

        //                     <div className="d-flex justify-content-end align-items-center fw-500 mb-2">
        //                         <span
        //                             className="d-inline-block me-2 rounded-1"
        //                             style={{
        //                                 width: "14px",
        //                                 height: " 14px",
        //                                 backgroundColor: " gold",
        //                             }}
        //                         ></span>
        //                         Premium
        //                     </div>
        //                     {featuredEvents.map((featuredEvent, index) => {
        //                         const cardColor = cardColors[index % cardColors.length];
        //                         const isTenthElement = (index + 1) % 10 === 0; // Check if it's the tenth element
        //                         const elementToRender = (
        //                             <div
        //                                 key={index}
        //                                 style={{ background: cardColor }}
        //                                 id="featured-events"
        //                                 className="side-lst"
        //                             >
        //                                 <div
        //                                     className="p-2 mb-3 deep-shadow premium-event event-card"
        //                                     style={{ boxShadow: " 0 1px 4px rgba(0,0,0,.18)" }}
        //                                 >
        //                                     <table className="w-100">
        //                                         <tbody>
        //                                             <tr>
        //                                                 <td className="pb-2">
        //                                                     <div
        //                                                         className="small fw-500"
        //                                                         style={{ lineHeight: "1.2" }}
        //                                                     >
        //                                                         {moment(featuredEvent.StartDate).format(
        //                                                             "ddd, D - "
        //                                                         )}{" "}
        //                                                         {moment(featuredEvent.EndDate).format(
        //                                                             "ddd, D MMM YYYY"
        //                                                         )}
        //                                                     </div>
        //                                                     <div className="fw-bold position-relative lh-1">
        //                                                         <a
        //                                                             href="#"
        //                                                             className="text-decoration-none c-ga xn"
        //                                                         >
        //                                                             <span className="position-absolute end-0">
        //                                                                 {featuredEvent.Banner !== "" ? (
        //                                                                     <img
        //                                                                         className="ms-2 mb-2 rounded-3 float-end lazyloaded"
        //                                                                         src={featuredEvent.Banner}
        //                                                                         alt=""
        //                                                                         width="50"
        //                                                                         height="50"
        //                                                                     />
        //                                                                 ) : (
        //                                                                     ""
        //                                                                 )}
        //                                                             </span>
        //                                                             <span
        //                                                                 className="d-block my-1"
        //                                                                 style={{ marginRight: " 60px" }}
        //                                                             >
        //                                                                 <span
        //                                                                     className="featured-event-name"
        //                                                                 //   onClick={(e) =>
        //                                                                 //     eventDetails(featuredEvent)
        //                                                                 //   }
        //                                                                 >
        //                                                                     {featuredEvent.Name}
        //                                                                 </span>
        //                                                             </span>
        //                                                         </a>
        //                                                     </div>
        //                                                     <div className="small text-dark fw-600 venue">
        //                                                         <a
        //                                                             href="#"
        //                                                             className="text-decoration-none text-dark"
        //                                                         >
        //                                                             {featuredEvent.City},{" "}
        //                                                             {featuredEvent.Country}
        //                                                         </a>
        //                                                     </div>
        //                                                     <div className="small mt-1">
        //                                                         <a
        //                                                             href="#"
        //                                                             className="text-muted text-decoration-none "
        //                                                         >
        //                                                             {featuredEvent.Category.map(
        //                                                                 (cat, index) => {
        //                                                                     return (
        //                                                                         <span
        //                                                                             key={index}
        //                                                                             className="d-inline-block small me-2 p-1 lh-1 bg-light rounded-1"
        //                                                                         >
        //                                                                             {cat}
        //                                                                         </span>
        //                                                                     );
        //                                                                 }
        //                                                             )}
        //                                                         </a>
        //                                                     </div>
        //                                                 </td>
        //                                             </tr>
        //                                             <tr>
        //                                                 <td className="d-flex justify-content-between align-items-center mt-2">
        //                                                     <a
        //                                                         href="#"
        //                                                         className=" text-decoration-none text-dark small px-0 fw-600"
        //                                                     >
        //                                                         <svg
        //                                                             xmlns="http://www.w3.org/2000/svg"
        //                                                             width="16"
        //                                                             height="16"
        //                                                             fill="currentColor"
        //                                                             className="bi bi-person-fill"
        //                                                             viewBox="0 0 16 16"
        //                                                         >
        //                                                             <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
        //                                                         </svg>
        //                                                         {featuredEvent.Interested} Members
        //                                                     </a>
        //                                                     <button className="btn btn-sm bg-light rounded-circle a-m ga c-ga xn bookmark">
        //                                                         <svg
        //                                                             xmlns="http://www.w3.org/2000/svg"
        //                                                             width="16"
        //                                                             height="16"
        //                                                             fill="currentColor"
        //                                                             className="bi bi-bookmark-fill"
        //                                                             viewBox="0 0 16 16"
        //                                                         >
        //                                                             <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
        //                                                         </svg>
        //                                                     </button>
        //                                                 </td>
        //                                             </tr>
        //                                         </tbody>
        //                                     </table>
        //                                 </div>
        //                             </div>
        //                         );
        //                         if (isTenthElement && index !== 0) {
        //                             // إذا كان هذا العنصر هو العنصر العاشر وليس العنصر الأول، أضف <h1>
        //                             return (
        //                                 <React.Fragment key={index}>
        //                                     <section className="box fs-14">
        //                                         <h5
        //                                             className="d-flex justify-content-center align-items-center"
        //                                             style={{ height: "100px", minHeight: "100px" }}
        //                                         >
        //                                             advertise with us
        //                                         </h5>
        //                                     </section>{" "}
        //                                     {elementToRender}
        //                                 </React.Fragment>
        //                             );
        //                         }

        //                         return elementToRender;
        //                     })}




        //                 </Col>
        //                 {/* End Adsenss Section*/}
        //             </div>
        //         </Container >

        //     </div >
        // </>
        <main className="main under-construction">
            <section className="contact my-5">
                <h1 className="title text-center">Awesome Thing</h1>
                <h2 className="sub-title text-center">Site Under Construction</h2>
                <p className="d-flex justify-content-center" style={{ marginTop: "-7em" }}>
                    <img src="https://png.pngtree.com/background/20230725/original/pngtree-website-under-construction-domain-webhoster-code-photo-picture-image_4298984.jpg" alt="under-construction" className="rounded-5 img-fluid" style={{ zIndex: "-1" }} />

                </p>
            </section>
        </main>
    );
}

export default Companies;
