import Carousel from 'react-bootstrap/Carousel';
// import Slider from 'react-slick';
import './carousel.css';

export default function Carousels() {
    

    return <>
        <Carousel controls={false} indicators={false}>
            <Carousel.Item interval={1000}>
                <img className='imgSlider' src='image/slider1.png' alt="First slide" />
                {/* <Carousel.Caption>
                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <img className='imgSlider' src='image/slider2.png' alt="Second slide" />
                {/* <Carousel.Caption>

                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item interval={1000}>
                <img className='imgSlider' src='image/slider3.png' alt="Third slide" />
                {/* <Carousel.Caption>
                    
                </Carousel.Caption> */}
            </Carousel.Item>
        </Carousel>
    </>
}