import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

const SpinnerComponent = () => {
    return (
        <>
        <div className=' d-flex justify-content-center'>
             <Button   style={{backgroundColor:'#862efb !important' , border:"1px solid #862efb" , opacity:"10"}} disabled>
                <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Loading...
            </Button>
        </div>
           
        </>
    );
}

export default SpinnerComponent;
