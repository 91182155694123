import './navbar.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SpinnerComponent from '../Spinner/spinner.jsx';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import axiosInstance from '../../AxiosConfig/axiosinstance.jsx';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEventDetails } from "../../Store/action";


export default function NavBar() {

    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [result, setResult] = useState(true);
    const loader = useSelector((state) => state.loader);

    const [events, setEvents] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [venues, setVenues] = useState([]);
    const [topics, setTopics] = useState([]);
    const [categories, setCategories] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSearch = async (value) => {
        // console.log(value);
        try {
            const response = await axiosInstance.get(`mainSearchAutoComplete?search=${value}`);
            if (response.status === 200) {
                setResult(false)
            }
            // console.log(response.data);
            setEvents(response.data.events);
            setCompanies(response.data.companies);
            setVenues(response.data.venues);
            setTopics(response.data.topics);
            setCategories(response.data.categories);
        } catch (error) {

        }
    }

    // send Event Details
    const eventDetails = (event) => {
        // console.log(event)
        axiosInstance
            .get(`/aboutEvent/${event._id}?country=${event.Country}&city=${event.City}&category=${encodeURIComponent(event.Category[0])}`)
            .then((response) => {
                // console.log(response.data);
                dispatch(getEventDetails(response.data));
                const result = JSON.stringify(response.data);
                localStorage.setItem("event", result);
                navigate("/eventsinfo")
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };




    return <>
        {(loader) ? <SpinnerComponent /> : <Navbar expand="lg" className="bg-body-tertiary">
            <Container fluid>
                {/* <Navbar.Brand href="/"><img src="image/fv.png" alt="" className='Logo' /></Navbar.Brand> */}
                <Navbar.Brand href="/"><img src="image/fv.svg" alt="logo" className='logo-header' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll" >
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px', width: '70%' }}
                        navbarScroll
                    >
                        <Form className="d-flex w-100">
                            <Form.Control
                                type="search"
                                placeholder="Search for Topic, Event or Company, Venue, Category"
                                className="me-2"
                                aria-label="Search"
                                onClick={() => setOpenSearchBar(true)}
                                onChange={(ele) => {
                                    handleSearch(ele.target.value);
                                }}
                            />
                            <Button variant="outline btn-expo">Search</Button>
                        </Form>
                        {openSearchBar ?
                            <div id="explore-keywords-container scroll-y" className="position-fixed w-100 start-0 " style={{ height: 'calc(100vh - 51px)', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, marginTop: "120px" }}>
                                <div id="explore-keywords-dropdown" className="position-fixed w-100 rounded-bottom-3 shadow scroll-y search-suggestion new bg-white" style={{ height: '40%', overflowY: 'scroll' }}>
                                    <button id="close-explore-keywords-dropdown" className="btn btn-sm btn-outline-dark" style={{ zIndex: 1, position: 'fixed', top: 70, right: 46 }} onClick={() => setOpenSearchBar(false)}>Close</button>


                                    <div className="container">
                                        {result ? <h5 className='mt-2'> Search for Topic, Event or Company, Venue, Category.... </h5> : <div className="row ">

                                            {/* event */}
                                            <div className="p-0 m-0 d-flex flex-column col-sm-12 col-md-4" >
                                                <div className="px-3 mt-3">
                                                    <div className="fw-bold px-3 mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="mx-1 bi bi-megaphone" viewBox="0 0 16 16">
                                                            <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0m-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065m-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z" />
                                                        </svg>

                                                        EVENTS
                                                    </div>
                                                    {events && events.map((event, index) => {
                                                        return (
                                                            <div className="w-100 w-lg-50 px-3 search-group" key={index}>
                                                                <a className="d-flex align-items-center small p-2 bg-light mb-2 text-decoration-none item bg-light rounded-3 cursor-pointer cursor" onClick={() => {
                                                                    eventDetails(event)
                                                                    setOpenSearchBar(false)
                                                                }} >
                                                                    <span className="d-flex align-items-center gap-3">
                                                                        {event.Banner ?
                                                                            <img className="border rounded-2" src={event.Banner} data-src="true" width={32} height={32} alt={event.Name} /> :
                                                                            <img className="border rounded-2" src={`https://img.10times.com/userimages/${event.Name.toLowerCase()[0]}.jpg?imgeng=/w_50/h_50`} data-src="true" width={32} height={32} alt={event.Name} />}
                                                                        <span className="d-flex flex-column" style={{ width: 'auto' }}>
                                                                            <span className="fw-bold text-dark line-2">
                                                                                {event.Name}
                                                                            </span>
                                                                            <span className="small text-muted line-1 mt-1">
                                                                                {event.City}, {event.Country}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                            </div>

                                            {/* Company */}
                                            <div className="p-0 m-0 d-flex flex-column col-sm-12 col-md-4" >
                                                <div className="px-3 mt-3">
                                                    <div className="fw-bold px-3 mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-clock mx-1" viewBox="0 0 16 16">
                                                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                                        </svg>

                                                        COMPANIES
                                                    </div>
                                                    {companies && companies.map((company, index) => {
                                                        return (
                                                            <div className="w-100 w-lg-50 px-3 search-group" key={index}>
                                                                <a className="d-flex align-items-center small p-2 bg-light mb-2 text-decoration-none item bg-light rounded-3 cursor-pointer cursor"
                                                                    onClick={() => {
                                                                        navigate("/companies");
                                                                        setOpenSearchBar(false)
                                                                    }}>
                                                                    <span className="d-flex align-items-center gap-3">
                                                                        {company.Banner ? <img className="border rounded-2" src={company.Banner} width={32} height={32} alt={company.Name} /> : <img className="border rounded-2" src={`https://img.10times.com/userimages/${company.Name.toLowerCase()[0]}.jpg?imgeng=/w_50/h_50`} width={32} height={32} alt={company.Name} />}

                                                                        <span className="d-flex flex-column" style={{ width: 'auto' }}>
                                                                            <span className="fw-bold text-dark line-2">
                                                                                {company.Name}
                                                                            </span>
                                                                            <span className="small text-muted line-1 mt-1">
                                                                                {company.City}, {company.Country}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </div>

                                            {/* Venues */}
                                            <div className="p-0 m-0 d-flex flex-column col-sm-12 col-md-4" >
                                                <div className="px-3 mt-3">
                                                    <div className="fw-bold px-3 mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                                                        </svg>

                                                        VENUES
                                                    </div>

                                                    {venues && venues.map((venue, index) => {
                                                        return (
                                                            <div className="w-100 w-lg-50 px-3 search-group" key={index}>
                                                                <a className="d-flex align-items-center small p-2 bg-light mb-2 text-decoration-none item bg-light rounded-3 cursor-pointer cursor" 
                                                                onClick={() => {
                                                                    navigate("/venues");
                                                                    setOpenSearchBar(false)
                                                                }}>
                                                                    <span className="d-flex align-items-center gap-3">
                                                                        {venue.Banner ? <img className="border rounded-2" src={venue.Banner} width={32} height={32} alt={venue.Name} /> : <img className="border rounded-2" src={`https://img.10times.com/userimages/${venue.Name.toLowerCase()[0]}.jpg?imgeng=/w_50/h_50`} width={32} height={32} alt={venue.Name} />}

                                                                        <span className="d-flex flex-column" style={{ width: 'auto' }}>
                                                                            <span className="fw-bold text-dark line-2">
                                                                                {venue.Name}
                                                                            </span>
                                                                            <span className="small text-muted line-1 mt-1">
                                                                                {venue.City}, {venue.Country}
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                            </div>

                                            {/* topics */}
                                            <div className=" col-6">
                                                <div id="explore-by" className="w-100 w-md-50 text-start p-0 m-0 search-group" >
                                                    <div className="fw-bold px-3 mt-3 mb-2">
                                                        <span className="px-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="currentColor" className="bi bi-hash bold" viewBox="0 0 16 16">
                                                                <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z" />
                                                            </svg>


                                                            TOPICS
                                                        </span>
                                                    </div>
                                                    {topics && topics.map((topic, index) => {
                                                        return (
                                                            <a className="d-flex align-items-center small px-3 py-2 mx-3 text-decoration-none item cursor-pointer cursor" key={index} >
                                                                <span className="d-flex align-items-center gap-3">
                                                                    <img className="border rounded-2" src={`https://img.10times.com/userimages/${topic.toLowerCase()[0]}.jpg?imgeng=/w_50/h_50`} width={32} height={32} alt="Venues in Egypt" />
                                                                    <span className="d-flex flex-column" style={{ width: '21vw' }}>
                                                                        <span className="line-2" data-bs-toggle="tooltip" title={topic}>
                                                                            {topic}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                            {/* Category */}
                                            <div className=" col-6">
                                                <div id="explore-by" className="w-100 w-md-50 text-start p-0 m-0 search-group" >
                                                    <div className="fw-bold px-3 mt-3 mb-2">
                                                        <span className="px-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-clock mx-1" viewBox="0 0 16 16">
                                                                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
                                                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
                                                            </svg>

                                                            CATEGORIES
                                                        </span>
                                                    </div>
                                                    {categories && categories.map((category, index) => {
                                                        return (
                                                            <a className="d-flex align-items-center small px-3 py-2 mx-3 text-decoration-none item cursor-pointer cursor" key={index}>
                                                                <span className="d-flex align-items-center gap-3">
                                                                    <img className="border rounded-2" src={`https://img.10times.com/userimages/${category.toLowerCase()[0]}.jpg?imgeng=/w_50/h_50`} width={32} height={32} alt={category} />
                                                                    <span className="d-flex flex-column" style={{ width: '21vw' }}>
                                                                        <span className="line-2" title={category}>
                                                                            {category}
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        )
                                                    })}
                                                </div>
                                            </div>


                                        </div>
                                        }</div>
                                </div>
                            </div> : ""}


                    </Nav>
                    <a href="#" className="navbarLink"> Promote Event</a>
                    <a href="#" className="navbarLinkActive"> Add Event</a>
                    <br />
                </Navbar.Collapse>
            </Container>
        </Navbar>}
    </>
}