import "./App.css";
import NotFound from "./Pages/NotFound/notFound";
import Login from "./Pages/Login&Register/login";
import Register from "./Pages/Login&Register/register";
import { Route, Routes } from "react-router-dom";
import ResetPassword from "./Pages/Login&Register/resetPassword";
import NewPassword from "./Pages/Login&Register/newPassword";
import Home from "./Pages/Home/home";
import VerifyCode from "./Pages/Login&Register/verifyCode";
import Header from "./Components/Header/header";
import NavBar from "./Components/Navbar/navbar";
import Footer from "./Components/Footer/footer";
import Events from "./Pages/Events/events";
import Companies from "./Pages/Companies/companies";
import Venues from "./Pages/Venues/venues";
import Top100 from "./Pages/Top100/top100";
import EventsDetails from "./Pages/EventsDetails/eventsDetails";
import ItemList from "./Pages/Test/itemList";
import UserProfile from "./Pages/Profile/userProfile";
import RequireAuth from "./Pages/Login&Register/RequireAuth";
import PersistLogin from "./Pages/Login&Register/PersistLogin";
import VenuesDetails from "./Pages/VenuesDetails/venuesDetails";
import Test from "./Pages/Events/test";

function App() {
  return (
    <>
      <NavBar />
      <Header />
      <Routes>
        {/* <Route path='/' element={<ItemList />} /> */}
        <Route element={<PersistLogin />}>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="verifycode" element={<VerifyCode />} />
          <Route path="resetpassword" element={<ResetPassword />} />
          <Route path="newpassword" element={<NewPassword />} />
          <Route path="events" element={<Events />} />
          <Route path="eventsinfo" element={<EventsDetails />} />
          <Route path="companies" element={<Companies />} />
          <Route path="venues" element={<Venues />} />
          <Route path="test" element={<Test />} />
          <Route path="venueinfo" element={<VenuesDetails />} />
          <Route path="top100" element={<Top100 />} />
          {/* protected Route */}
          <Route element={<RequireAuth />}>
            <Route path="profile" element={<UserProfile />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
