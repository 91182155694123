import React, { useEffect, useState } from "react";

import axiosInstance from "../../AxiosConfig/axiosinstance";

const FollowerEvent = () => {
  const result = localStorage.getItem("event");
  const event = JSON.parse(result);

  const [followers, setFollowers] = useState([]);

  const getAllFollowers = async () => {
    try {
      const response = await axiosInstance.get(
        `/eventFollowers/${event.EventData.data._id}?limit=12`
      );
      setFollowers(response.data.data.Followers);
      // console.log(response.data.data.Followers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFollowers();
    // console.log(followers);
  }, []);

  return (
    <>
      {" "}
      {followers.length > 0 ? (
        <>
          <section className="box visitors-block fs-14">
            <h2
              className="visitors fw-bold d-flex"
              style={{ alignItems: "baseline", fontSize: "1.2em" }}
            >
              Followers
              <strong className="text-muted fs-14 fw-500 ms-1">
                [ Users who have shown interest for this Event ]
              </strong>
              <span className="d-flex" style={{ marginLeft: "auto" }}>
                <span className="btn btn-sm btn-expo x-iai join-comm text-decoration-none fs-12 cursor">
                  Join Community
                </span>
                <span className="btn btn-sm btn-official x-rabb ms-2 text-decoration-none fs-12 cursor">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-person-fill-add"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                  </svg>
                  Invite
                </span>
              </span>
            </h2>
            <div className="row my-3">
              <div className="col-md-12 d-flex">
                <div >
                  <select
                    name=""
                    id="visitor_designation"
                    className="btn btn-official text-muted selOpt fs-14 col"
                    style={{ width: "120px", marginRight: "5px", marginBottom:"5px" }}
                  >
                    <option value="">All Profiles</option>
                    {Object.keys(event.EventData.data.jobCounts).map(
                      (key, index) => (
                        <option key={index} value={key}>
                          {key} [ {event.EventData.data.jobCounts[key]} ]
                        </option>
                      )
                    )}
                  </select>

                  <select
                    name=""
                    id="visitor_designation"
                    className="btn btn-official text-muted selOpt fs-14 col"
                    style={{ width: "120px", marginRight: "5px" , marginBottom:"5px" }}
                  >
                    <option value="">All Countries</option>
                    <option value="IN">India</option>
                    <option value="US">USA</option>
                    <option value="AE">UAE</option>
                  </select>
                </div>
                <div className="top-profile">
                  <span className="text-muted fs-12">Sort By </span> &nbsp;
                  <select
                    name=""
                    id="visitor_designation"
                    className="btn btn-official text-muted selOpt fs-14"
                    style={{ width: "120px", marginRight: "5px" }}
                  >
                    <option value="Top_Profiles"> Top Profiles</option>
                    <option value="Recommended">Recommended</option>
                  </select>
                </div>
              </div>
            </div>
          </section>
          <div className="visitor clearfix row flex-wrap">
            {followers.map((follower, index) => {
              return (
                <div key={index} className="col-lg-3 col-md-4 col-sm-12">
                  <div className="box box-210 text-center bx-hl position-relative">
                    <div
                      className="scroll no-scroller navbar-nav-scroll"
                      style={{ height: "155px" }}
                    >
                      <small
                        className="text-success position-absolute"
                        style={{ top: "5px", left: "5px" }}
                      >
                        {" "}
                        Going
                      </small>
                      <span
                        className="follow text-muted position-absolute fs-14"
                        style={{
                          top: "0px",
                          right: "0px",
                          height: "20px",
                          width: "20px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-heart-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                          />
                        </svg>
                      </span>
                      <a href="" className="text-decoration-none">
                        <div className="d-flex justify-content-center">
                          <div>
                            <img
                              className="rounded-2 img-48 lazyloaded"
                              src={follower.Image}
                              alt="Gorav Gupta"
                            />
                          </div>
                        </div>
                        <div className="color-333 mt-2 visitor_name">
                          {follower.FirstName}
                        </div>
                      </a>
                      <p
                        className="fs-12 text-muted"
                        style={{
                          marginTop: "5px",
                          marginBottom: "10px",
                          lineHeight: "14px",
                        }}
                      >
                        {follower.Role} at {follower.Organization}
                      </p>
                      <small className="d-block text-muted fs-12">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          fill="currentColor"
                          className="bi bi-geo-alt-fill me-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {follower.City}, {follower.Country}
                      </small>
                    </div>
                    <div className="row action-button">
                      <button className="sugg_button btn btn-block fw-bold fs-14 x-vs-cb">
                        Connect
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* advertise section */}
          <section
            className="box"
            style={{ height: "100px", minHeight: "100px", textAlign: "center" }}
          >
            advertise with us
          </section>
        </>
      ) : (
          <section
            className="box"
            style={{ height: "100px", minHeight: "100px", textAlign: "center" }}
          >
            There is no follower mapped to this event. Want to Follow?
            <button className="btn btn-expo d-block m-auto mt-2">Follow</button>
          </section>
      )}
    </>
  );
};

export default FollowerEvent;
