import axiosInstance from '../../AxiosConfig/axiosinstance';
import Alert from 'react-bootstrap/Alert';
import './register.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Register = () => {

       // handel form login
    const [user, setUser] = useState({
        fName: "",
        lName: "",
        email: "",
        password: "",
        number: "",
    });
    const [userErorr, setUserErorr] = useState({
        fNameErorr: "",
        lNameErorr: "",
        emailErorr: "",
        passwordErorr: "",
        numberErorr: "",
    })
    const [error, setError] = useState('');
    const [show, setShow] = useState(false);
    const [reqestMsg, setReqestMsg] = useState('');
    const navigate = useNavigate();

    const handelForm = (eve) => {
        eve.preventDefault();
        axiosInstance.post('/register', {
            "email": user.email,
            "password": user.password,
            "firstName": user.fName,
            "lastName": user.lName,
            "phoneNumber": user.number,
        }).then((respons) => {
            setReqestMsg(respons.data.message);
            // console.log(respons);
            setTimeout(() => {
                navigate('/login');

            }, 2000)
            setShow(true)
        }).catch((error) => {
            console.log(error);
            setError(error.response.data.message)
        })
    }



    return (
        <>
            <div>
                <div className='container  justify-content-end w-25 mt-2'>
                    <Alert className='' show={show} variant="success">
                        <Alert.Heading style={{ backgroundColor: "#d1e7dd", textAlign: "center", padding: "10px" }}>{reqestMsg} ..!</Alert.Heading>
                        <p style={{ backgroundColor: "#d1e7dd" }}>
                        </p>
                        <hr />
                        <div className="d-flex justify-content-end"></div>
                    </Alert>
                </div>
                <div className='parent my-5 bg-white' style={{ width: "50%", margin: "auto" }}>
                    <center className='ticket'>
                        <a href="" className='whySignup bg-white'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle mx-1" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                            WhySignup
                        </a>
                        <div className='fs-16 text-expo bg-white'>
                            Signup
                        </div>
                        <div className="mt-2 bg-white">
                            <div className='bg-white justify-content-evenly align-items-center align-items-center    small social_btn_wrapper ' style={{ width: "88%" }}>
                                <div role='button' className=' shadow-login bg-white d-flex fw-500 text-muted text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social-connect social_btn'>
                                    <span className=' bg-white d-flex align-items-center pe-2 me-2 border-end' >
                                        <img className='bg-white' src="./image/google-icon.svg" alt="" width={"16px"} />
                                    </span>
                                    Continue with Google
                                </div>
                                <div role='button' className='shadow-login bg-white d-flex fw-500 text-muted text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social_btn' >
                                    <span className='bg-white d-flex align-items-center pe-2 me-2 border-end' >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone bg-white" viewBox="0 0 16 16">
                                            <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                            <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg>
                                    </span>
                                    Continue with Phone
                                </div>
                                <div role='button' className='shadow-login d-flex align-items-center fw-500 text-white text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social-connect social_btn' style={{ backgroundColor: "#1877f2" }}>
                                    <span className='text-dark d-flex align-items-center pe-2 me-2 border-end' style={{ width: "25px", backgroundColor: "#1877f2" }}>
                                        <svg className="svg-inline--fa fa-facebook fa-w-16" aria-hidden="true" style={{ width: " 16px", height: "16px", backgroundColor: "#1877f2", color: "white" }} focusable="false" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                            <path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z">
                                            </path>
                                        </svg>
                                    </span>
                                    Continue with Facebook
                                </div>
                                <div role='button' className='text-white shadow-login d-flex align-items-center fw-500  text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social-connect social_btn' style={{ backgroundColor: "#0072b1" }}>
                                    <span className='text-light d-flex align-items-center pe-2 me-2 border-end' style={{ width: "25px", backgroundColor: "#0072b1" }}>
                                        <svg className="svg-inline--fa fa-linkedin-in fa-w-14" aria-hidden="true" style={{ width: " 16px", height: "16px", backgroundColor: "#0072b1", color: "white" }} focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                    </span>
                                    Continue with Linkedin
                                </div>
                            </div>
                        </div>
                        <form action="" onSubmit={handelForm} className='p-0'>
                            <div className="row ">
                                <div className="col-12  bg-white">
                                    <input style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} type="text" className="form-control w-50 m-auto shadow-login" placeholder="First name" required
                                        aria-label="First name" name='fName' onChange={(e) => {
                                            setUser({ ...user, [e.target.name]: e.target.value })
                                        }} />
                                    {/* <p className='text-danger bg-white' style={{ backgroundColor: "white  !important" }}>{user.fName}</p> */}
                                    <p className='text-danger bg-white' style={{ backgroundColor: "white  !important" }}>{userErorr.fNameErorr}</p>
                                </div>
                                <div className="col-12  bg-white">
                                    <input style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} type="text" required className="shadow-login form-control w-50 m-auto" placeholder="Last name"
                                        aria-label="Last name" name='lName' onChange={(e) => {
                                            setUser({ ...user, [e.target.name]: e.target.value })
                                        }} />
                                    <p className='text-danger bg-white'>{userErorr.lNameErorr}</p>

                                </div>
                                <div className="col-12  bg-white">
                                    <input style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} type="email" className="shadow-login form-control w-50 m-auto" placeholder="Email" required
                                        name='email' onChange={(e) => {
                                            setUser({ ...user, [e.target.name]: e.target.value })
                                        }} />
                                    <p className='text-danger bg-white'>{userErorr.emailErorr}</p>
                                </div>
                                <div className="col-12   bg-white">
                                    <input style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} type="password" className="shadow-login form-control w-50 m-auto" placeholder="Password" required
                                        name='password' onChange={(e) => {
                                            setUser({ ...user, [e.target.name]: e.target.value })
                                        }} />
                                    <p className='text-danger bg-white'>{userErorr.passwordErorr}</p>
                                </div>
                                <div className="col-12  bg-white">
                                    <input style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} type="number" className="shadow-login form-control w-50 m-auto" placeholder="Phone Number" required
                                        name='number' onChange={(e) => {
                                            setUser({ ...user, [e.target.name]: e.target.value })
                                        }} /><br />
                                    <p className='bg-white text-danger'>{error}</p>
                                </div>
                                <div className='col-12 bg-white form-group small privacy-policy d-flex justify-content-center cursor-pointer privacy-policy-text  fs-12'>
                                    <div className="bg-white">
                                        <input required type="checkbox" name="" id="" className='fa-w-14 me-2 ' />
                                    </div>
                                    <div className="server_check_box_msg bg-white">
                                        I agree to <a className="text-decoration-none bg-white" href="#" target="_blank"> Privacy Policy</a> and acknowledge to receive communication from Expo Galaxy
                                    </div>
                                </div>
                                <div className="col-12  bg-white">
                                    <input type="submit" id="send" value="Register" className=" btn btn-default m-3 mb-2" style={{ backgroundColor: " white", border: "  0.05em solid #862efb", outline: " none", color: " #862efb", padding: " 4px", borderRadius: "  5px", fontSize: " 14px", width: " 80px" }} />

                                </div>

                            </div>
                        </form>
                    </center >
                </div >
            </div>

        </>
    );
}

export default Register;
