import React, { useState, useEffect, useContext } from "react";
// import Link from "../Link/link";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Link, NavLink, useNavigate } from "react-router-dom";
import './header.css';
import { useDispatch, useSelector } from "react-redux";
import { getLocation } from "../../Store/action";
import axios from "axios";
import { User } from "../../Contexts/UserContexts";
import Cookies from 'universal-cookie'
import { Button, Form } from "react-bootstrap";

export default function Header() {

    const navigate = useNavigate();
    const userInfo = useContext(User);
    const location = useSelector((state) => state.location);
    const cookie = new Cookies();
    const token = cookie.get("Bearer")

    const dispatch = useDispatch();

    // function to  handle get location
    function fetchLocationAndData() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                axios.get(`https://api.opencagedata.com/geocode/v1/json?key=e1dfac9df48946eb8294035a7ca48886&q=${latitude},${longitude}&pretty=1`)
                    .then((response) => {
                        // console.log(response.data.results[0].components);
                        dispatch(getLocation(response.data.results[0].components));
                    })
                    .catch((error) => {
                        console.log(error);
                        // إعادة المحاولة بعد فترة زمنية معينة إذا كان هناك خطأ
                        setTimeout(fetchLocationAndData, 5000); // انتظر 5 ثواني وحاول مرة أخرى
                    });
            },
            (error) => {
                console.error(error);
                // إعادة المحاولة بعد فترة زمنية معينة إذا لم يتم الوصول إلى الموقع
                setTimeout(fetchLocationAndData, 5000); // انتظر 5 ثواني وحاول مرة أخرى
            }
        );
    }


    // function to logout
    const handleLogout = () => {
        console.log("log out");
        cookie.remove("Bearer");
        localStorage.removeItem("userInfo");
        window.location.pathname = "/"

    };

    useEffect(() => {
        fetchLocationAndData();
        // console.log(userInfo);

    }, []);





    return <>
        <Container fluid className="sticky-top bg-white">

            <Navbar expand="lg" className="sticky-top">
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Col>
                        <Nav
                            className="me-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px', width: '100%' }}
                            navbarScroll
                        >
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/events" className="navbarLink mx-3">EVENTS</NavLink>
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/venues" className="navbarLink mx-3">VENUES</NavLink>
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/companies" className="navbarLink mx-3" >COMPANIES</NavLink>
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/top100" className="navbarLink mx-3" >TOP 100 EVENTS</NavLink>
                        </Nav>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <Link to="/" className="navbarLink mx-3">
                            <svg className="svg-inline--fa fa-plus fa-w-12 fa-sm " aria-hidden="true" focusable="false" data-prefix="far" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="" data-icon="plus" style={{ width: "20px", height: "20px" }}><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
                            Create Filter
                        </Link>
                        <Link to="/" className="navbarLink mx-3">
                            <svg className="svg-inline--fa fa-location fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="location" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{ width: "16px ", height: " 16px" }}>
                                <path fill="currentColor" d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z">
                                </path>
                            </svg>
                            &nbsp;{(location === "") ? " Nerbay Events " : location.country}
                        </Link>
                        {(!userInfo.auth.userDetails) ? <Link to="/login" className="navbarLink mx-3">
                            Login
                        </Link> : <Link className="test navbarLink" to="/" style={{ width: "150px" }}>
                            <div className="dropdown">
                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Hello, {userInfo.auth.userDetails?.firstName}
                                </span>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/profile" >Profile</Link></li>
                                    <li><Link className="dropdown-item" >Another action</Link></li>
                                    <li><Link className="dropdown-item" onClick={handleLogout}>Logout</Link></li>
                                </ul>
                            </div>
                            <Image className="img-avatar" src={userInfo.auth.userDetails?.image} rounded />
                        </Link>}
                    </Col>
                </Navbar.Collapse>
            </Navbar>
        </Container >
        {/* <Container fluid className="sticky-top bg-white ">
            
            <div className="d-flex justify-content-between  ">
                <div className="d-flex align-items-center">
                    <Navbar.Brand href="/"><img src="image/fv.svg" alt="logo" className='logo-header' /></Navbar.Brand>
                    <Form className="d-flex">
                        <Form.Control
                            type="search"
                            placeholder="Search for Topic, Event or Company, Venue, Category"
                            className="me-2"
                            aria-label="Search"

                        />
                        <Button variant="outline btn-expo">Search</Button>
                    </Form>
                    <Link to="/" className="navbarLink mx-3">
                        <svg className="svg-inline--fa fa-plus fa-w-12 fa-sm " aria-hidden="true" focusable="false" data-prefix="far" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="" data-icon="plus" style={{ width: "20px", height: "20px" }}><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
                        Create Filter
                    </Link>
                    <Link to="/" className="navbarLink mx-3">
                        <svg className="svg-inline--fa fa-location fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="location" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{ width: "16px ", height: " 16px" }}>
                            <path fill="currentColor" d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z">
                            </path>
                        </svg>
                        &nbsp;{(location === "") ? " Nerbay Events " : location.country}
                    </Link>
                </div>
                <div className="">
                    <Nav
                        className="me-auto my-2 my-lg-0 text-end"
                        style={{ maxHeight: '100px', width: '100%' }}
                        navbarScroll
                    >
                        <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/events" className="navbarLink mx-3">EVENTS</NavLink>
                        <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/venues" className="navbarLink mx-3">VENUES</NavLink>
                        <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/companies" className="navbarLink mx-3" >COMPANIES</NavLink>
                        <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/top100" className="navbarLink mx-3" >TOP 100 EVENTS</NavLink>

                        {(!userInfo.auth.userDetails) ? <Link to="/login" className="navbarLink mx-3">
                            LOGIN
                        </Link> : <Link className="test navbarLink" to="/" style={{ width: "150px" }}>
                            <div className="dropdown">
                                <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Hello, {userInfo.auth.userDetails?.firstName}
                                </span>
                                <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item" to="/profile" >Profile</Link></li>
                                    <li><Link className="dropdown-item" >Another action</Link></li>
                                    <li><Link className="dropdown-item" onClick={handleLogout}>Logout</Link></li>
                                </ul>
                            </div>
                            <Image className="img-avatar" src={userInfo.auth.userDetails?.image} rounded />
                        </Link>}
                    </Nav>
                </div>
            </div>
        </Container > */}
        {/* <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top" id="navbar-primary" style={{
            zIndex: "1031", boxShadow: "0px 0px 8px 0px #c7c7c7"
        }}>
            <div className="container-fluid small px-3 mx-3">
                <span className="navbar-brand" aria-label="10Times" style={{width: 100, height: 35, backgroundImage: 'url("https://expogalaxy.com/image/fv.svg")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} />

                <div id="primary-nav" className="collapse navbar-collapse" >
                    <div className="nav-item c-ga" style={{ width: "280px" }}>
                        <div className="search-box-area" style={{ zIndex: '3 !important' }}>
                            <form action="" className="d-flex w-100" style={{ paddingRight: "0.75rem" }}>
                                <div className="input-group bg-light rounded-pill">
                                    <input type="search" name="main-search" id="explore-keywords" placeholder="Search for Topic, Event or Location" className="form-control fs-12 bg-transparent border-0 search-input" />
                                    <span className="input-group-text ps-0 bg-transparent border-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                        </svg>
                                    </span>
                                </div>
                            </form>
                        </div>

                    </div>
                    <span className="fs-12 ps-0 pe-3 location-link d-flex align-items-center gap-1 nav-link border-0 bg-transparent hover-orange-nav-ling">
                        <svg className="svg-inline--fa fa-location fa-w-16" aria-hidden="true" focusable="false" data-prefix="far" data-icon="location" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="" style={{ width: "16px ", height: " 16px" }}>
                            <path fill="currentColor" d="M256 168c-48.6 0-88 39.4-88 88s39.4 88 88 88 88-39.4 88-88-39.4-88-88-88zm0 128c-22.06 0-40-17.94-40-40s17.94-40 40-40 40 17.94 40 40-17.94 40-40 40zm240-64h-49.66C435.49 145.19 366.81 76.51 280 65.66V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v49.66C145.19 76.51 76.51 145.19 65.66 232H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h49.66C76.51 366.81 145.19 435.49 232 446.34V496c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-49.66C366.81 435.49 435.49 366.8 446.34 280H496c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16zM256 400c-79.4 0-144-64.6-144-144s64.6-144 144-144 144 64.6 144 144-64.6 144-144 144z">
                            </path>
                        </svg>
                        &nbsp;{(location === "") ? " Nerbay Events " : location.country}
                    </span>
                    <span>
                        <svg className="svg-inline--fa fa-plus fa-w-12 fa-sm " aria-hidden="true" focusable="false" data-prefix="far" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="" data-icon="plus" style={{ width: "20px", height: "20px" }}><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
                        Create Filter
                    </span>
                    <div className="navbar-nav ms-auto">
                        <Nav
                            className=""
                            style={{ maxHeight: '100px', width: '100%' }}
                            navbarScroll
                        >
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/events" className="navbarLink mx-3 ">EVENTS</NavLink>
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/venues" className="navbarLink mx-3 ">VENUES</NavLink>
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/companies" className="navbarLink mx-3 " >COMPANIES</NavLink>
                            <NavLink style={({ isActive }) => (isActive) ? { color: "#862efb" } : undefined} to="/top100" className="navbarLink mx-3 " >TOP 100 EVENTS</NavLink>

                            {(!userInfo.auth.userDetails) ? <Link to="/login" className="navbarLink mx-3 ">
                                LOGIN
                            </Link> : <Link className="test navbarLink" to="/" style={{ width: "150px" }}>
                                <div className="dropdown">
                                    <span className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Hello, {userInfo.auth.userDetails?.firstName}
                                    </span>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/profile" >Profile</Link></li>
                                        <li><Link className="dropdown-item" >Another action</Link></li>
                                        <li><Link className="dropdown-item" onClick={handleLogout}>Logout</Link></li>
                                    </ul>
                                </div>
                                <Image className="img-avatar" src={userInfo.auth.userDetails?.image} rounded />
                            </Link>}
                        </Nav>
                    </div>
                </div>

            </div>
        </nav> */}
    </>
}