import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Accordion from "react-bootstrap/Accordion";
import axiosInstance from "../../AxiosConfig/axiosinstance";
import { useDispatch } from "react-redux";
import { getEventDetails } from "../../Store/action";
import { useNavigate } from "react-router-dom";
import { User } from "../../Contexts/UserContexts";

const AboutEvent = () => {
  const percentage = 66;
  const result = localStorage.getItem("event");
  const data = JSON.parse(result);
  const [event, setEvent] = useState(data);
  const [otherEvents, setOtherEvents] = useState();

  const [map, setMap] = useState(event.EventData.data.Venue.Contacts.maps);

  const [variables, setVariables] = useState({
    rating: "",
    review: "",
    imgUpload: "",
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      setSelectedFile(URL.createObjectURL(files[0]));
    }

    setVariables((prevVariables) => ({
      ...prevVariables,
      [name]: value,
    }));
  };

  // const token = window.localStorage.getItem("accessToken");
  const context = useContext(User);
  const token = context.auth?.token
  // console.log(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // add rating of event
  const handleSubmit = (e) => {
    e.preventDefault();

    var formData = new FormData();
    const files = document.querySelector("#uploadimage");

    formData.append("subject", event.EventData.data._id);
    formData.append("content", variables.review);
    formData.append("rating", variables.rating);
    // formData.append("images", variables.imgUpload);
    formData.append("about", JSON.stringify(selectedOptions));
    for (let i = 0; i < files.files.length; i++) {
      formData.append("images", files.files[i]);
    }

    console.log(token);
    axiosInstance
      .post("addEventReview", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(
            `/aboutEvent/${event.EventData.data._id}?country=${event.EventData.data.Country}`
          )
          .then((response) => {
            console.log(response.data);
            dispatch(getEventDetails(response.data));
            const result = JSON.stringify(response.data);
            localStorage.setItem("event", result);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
        setShowRatingSection(!showRatingSection);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get new event details the same country
  const eventDetails = (event) => {
    // console.log(event.Country);
    // console.log(event);
    axiosInstance
      .get(
        `/aboutEvent/${event._id}?country=${event.Country}&city=${event.City}&category=${encodeURIComponent(event.Category[0])}`
      )
      .then((response) => {
        // console.log(response);
        // navigate("/eventsinfo");
        dispatch(getEventDetails(response.data));
        const result = JSON.stringify(response.data);
        localStorage.setItem("event", result);
        // console.log(result);
        setEvent(response.data);
        window.location.reload();
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //get new event details the same City
  const eventDetailsByCity = (event) => {
    // console.log(event.Country);
    // console.log(event);
    axiosInstance
      .get(
        `/aboutEvent/${event._id}?country=${event.Country}&city=${event.City}&category=${encodeURIComponent(event.Category[0])}`
      )
      .then((response) => {
        localStorage.removeItem("event")
        // localStorage.clear();
        // console.log(response.data.EventData);
        dispatch(getEventDetails(response.data));
        const result = JSON.stringify(response.data);
        // console.log(result);
        localStorage.setItem("event", result);
        setEvent(response.data);
        window.location.reload();
        // navigate("/eventsinfo");
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showRatingSection, setShowRatingSection] = useState(false);
  const [login, setLogin] = useState(false);
  
  const handelRating = () => {

    if (window.localStorage.getItem("userInfo")) {
      setLogin(true);
      setShowRatingSection(!showRatingSection);
    } else {
      console.log("logout");
      setLogin(false);
    }
  };

  const [activeTags, setActiveTags] = useState([]);

  const handleTagClick = (index) => {
    if (activeTags.includes(index)) {
      setActiveTags(activeTags.filter((tagIndex) => tagIndex !== index));
    } else {
      setActiveTags([...activeTags, index]);
    }
  };

  // cutting string
  function truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + "...";
    }
  }

  return (
    <div>
      {/* about Section */}
      <section className="box fs-14">
        {/* Slider */}
        <section className="position-relative fs-14">
          <div className="position-relative fs-14">
            <div className="text-nowrap overflow-hidden cursor">
              <div
                className="photos_tumbnail_box no-scroller navbar-nav-scroll"
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                {event.EventData.data.Images.map((review, index) => {
                  return (
                    <div
                      key={index}
                      className="gallary-card-review position-relative cursor me-2 m-0"
                      style={{
                        width: "225px",
                        height: "150px",
                        flex: "0 0 225px",
                      }}
                    >
                      <img
                        className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                        src={review}
                        width="225"
                        height="150"
                        alt="gallery"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <p className="mng text-break fs-14" style={{ lineHeight: "24px" }}>
          {event.EventData.data.HeaderAbout !== "Null" ? (
            <strong>{event.EventData.data.HeaderAbout}</strong>
          ) : (
            ""
          )}
          <br />
          {event.EventData.data.Content !== "Null"
            ? event.EventData.data.Content
            : ""}
        </p>

        {/* HighLights */}
        {event.EventData.data.Highlights !== "Null" &&
          event.EventData.data.Highlights !== "" ? (
          <div
            className="p-2 mb-4 rounded-2"
            style={{ backgroundColor: "#faf2d9" }}
          >
            <h2 className="fw-bold ms-1" style={{ fontSize: "1.2rem" }}>
              Highlights
            </h2>
            <ul className="mb-0">
              {event.EventData.data.Highlights.split(".").map((str, index) => {
                return (
                  <li key={index} className="fs-14">
                    {truncateText(str, 150)}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          ""
        )}

        {/* Category & Related Topics */}
        <div className="fs-14 position-relative">
          <h2 className="fs-14 fw-bold">Listed In</h2>
          <div id="nav-btn">
            <div className="quicklink-wrap text-muted">
              {/* {event.EventData.Category} */}
              {event.EventData.Category.map((cat, index) => {
                return (
                  <span key={index} className="quicklinks1 d-inline">
                    <img
                      width="14"
                      height="14"
                      src={cat.icon}
                      alt=""
                      className="rounded me-1"
                    />
                    <a href="" className="text-decoration-none text-muted">
                      {cat.name}
                    </a>
                  </span>
                );
              })}
              {event.EventData.data.Topics.map((topic, index) => {
                return (
                  <span key={index} className="quicklinks1 d-inline">
                    <a href="" className="text-decoration-none text-muted">
                      #{topic}
                    </a>
                  </span>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      {/* advertise section */}
      <section
        className="box"
        style={{ height: "100px", minHeight: "100px", textAlign: "center" }}
      >
        advertise with us
      </section>

      {/* description info */}
      <section className="box fs-14">
        <table className="table noBorder mng w-100 trBorder mb-0">
          <tbody>
            <tr id="hvrout1">
              <td style={{ width: "50%" }}>
                <h2 className="fw-bold" style={{ fontSize: "1.2rem" }}>
                  Timings
                </h2>
                <div className="d-inline timings_dv">
                  {event.EventData.data.TextDate.split(".").map((e, index) => {
                    return (
                      <div key={index} className="d-inline timings_dv">
                        {e} <br />
                      </div>
                    );
                  })}
                </div>
              </td>
              <td style={{ width: "50%" }}>
                <h2 className="fw-bold" style={{ fontSize: "1.2rem" }}>
                  Entry Fees
                </h2>
                {event.EventData.data.EntryFee}

                <small className="text-muted ml-10">
                  {" "}
                  For Industry Professionals{" "}
                </small>
                <small className="d-block">
                  <span className="text-decoration-none cursor text-blue">
                    View Details
                  </span>
                </small>
              </td>
            </tr>
            <tr>
              <td className="">
                <h2 className="fw-bold" style={{ fontSize: "1.2rem" }}>
                  Estimated Turnout
                </h2>
                <a href="" className="text-decoration-none text-blue">
                  {event.EventData.data.EstimatedVisitors}{" "}
                </a>
                <div className="d-inline-block" id="name-vis ">
                  {" "}
                  Visitors
                </div>{" "}
                <br />
                <a href="" className="text-decoration-none text-blue">
                  {event.EventData.data.Exhibitors.length}{" "}
                </a>{" "}
                Exhibitors
              </td>
              <td id="hvrout2">
                <h2 className="fw-bold" style={{ fontSize: "1.2rem" }}>
                  Event Type
                </h2>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="green"
                  className="bi bi-check"
                  viewBox="0 0 16 16"
                >
                  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                </svg>
                {event.EventData.data.Format}
              </td>
            </tr>
            <tr id="hvrout3">
              <td>
                <h2 className="fw-bold" style={{ fontSize: "1.2rem" }}>
                  Editions
                </h2>
                Oct 2023
                <span className="x-ded-in ms-1 cursor text-blue text-decoration-none editionList pastEdition">
                  <small className="">
                    <strong className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill "
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>{" "}
                      <span>Interested</span>
                    </strong>
                  </small>
                </span>
                <br />
                <span className="text-decoration-underline text-muted fs-12 cursor">
                  +4 more editions
                </span>
                <br />
                <br />
                <small className="d-block text-muted">Frequency</small>
                {event.EventData.data.Frequency}
                <br />
                <span className="d-flex"></span>
              </td>
              <td>
                <h2 className="fw-bold" style={{ fontSize: "1.2rem" }}>
                  Official Links
                </h2>
                <a href={event.EventData.data.WebsiteUrl === "Null" ? "#" : event.EventData.data.WebsiteUrl} className="text-dark" >
                  <span className="btn btn-sm btn-official x-ob-ow  fs-12 cursor">
                    Website{" "}
                  </span>
                </a>
                <span className="btn btn-sm btn-official x-ob-cd text-decoration-none fs-12 cntct_orgnzr cursor ms-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
                  Contacts{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="#862efb"
                    className="bi bi-shield-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                    <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                </span>
                {/* <!-- Modal --> */}
                <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                  <div className="modal-dialog  modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Request a Booth</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <form action="">
                          <div className="row">
                            <div className="col-6">
                              <input type="text" className="form-control my-1" placeholder="First name" />
                            </div>
                            <div className="col-6">
                              <input type="text" className="form-control my-1" placeholder="Last name" />
                            </div>
                            <div className="col-13">
                              <input type="text" className="form-control my-1" placeholder="Email Address" />
                            </div>
                            <div className="col-6">
                              <input type="text" className="form-control my-1" placeholder="Title/Role" />
                            </div>
                            <div className="col-6">
                              <input type="text" className="form-control my-1" placeholder="Organization" />
                            </div>
                            <div className="col-13">

                              <label htmlFor="inputState" className="form-label">What service you need?</label>
                              <select id="inputState" className="form-select">
                                <option value="" selected>Choose...</option>
                                <option value="">SEO</option>
                                <option value="">ADV</option>
                                <option value="">DEVELOP</option>
                              </select>
                            </div>
                            <div className="col-13">
                              <label htmlFor="exampleFormControlTextarea1" className="form-label">Tell Us About Your Project</label>
                              <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                          </div>

                        </form>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn  btn-expo" data-bs-dismiss="modal">Send</button>
                        {/* <button type="button" className="btn btn-primary">Understood</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                <span className="btn btn-sm btn-official x-ob-ow  fs-12 cursor ms-1">
                  Android App
                </span>
                <span className="btn btn-sm btn-official x-ob-ow  fs-12 cursor ms-1">
                  iOS
                </span>
                <br />
                <br />
                <br />
                <small className="text-muted x-rtl text-decoration-none cursor">
                  Report Error
                </small>
              </td>
            </tr>
            <tr className="container ">
              <td id="brand_events container " colSpan="2">
                <small className="d-block text-muted">
                  Event Happening Alongside
                </small>
                <div className="row">
                  {event.EventData.sameCountryEvents.map((event, index) => {
                    return (
                      <div
                        key={index}
                        className="col-md-4"
                        style={{ paddingRight: "0px" }}
                      >
                        <div className="other-location">
                          <a className="text-decoration-none d-inline-block text-blue cursor">
                            <strong
                              className="d-block"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              onClick={() => eventDetails(event)}
                            >
                              {event.Name.slice(0, 20)}
                            </strong>
                          </a>
                          <small className="brands_date d-block">
                            {moment(event.StartDate).format("D") +
                              " - " +
                              moment(event.EndDate).format("D MMM YYYY")}
                          </small>
                          <div style={{ textAlign: "right" }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-bookmark-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                            </svg>
                            <small className="text-success float-start">
                              {" "}
                              {event.FollowersSize} Followers
                            </small>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </td>
            </tr>
            {/* rating */}
            <tr>
              <td colSpan={2}>
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <h3
                      className="d-inline-block"
                      style={{ fontSize: "1.17em" }}
                    >
                      {" "}
                      {event.EventData.data.Rating.Average.toFixed(1)}
                      {Array.from(
                        {
                          length: event.EventData.data.Rating.Average,
                        },
                        (_, index) => (
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="#862efb"
                            className="bi bi-star-fill ms-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        )
                      )}
                    </h3>
                    <a
                      href=""
                      className="sm-x fw-bold text-decoration-none text-blue mx-1"
                    >
                      {`${event.EventData.data.Rating["1"] +
                        event.EventData.data.Rating["2"] +
                        event.EventData.data.Rating["3"] +
                        event.EventData.data.Rating["4"] +
                        event.EventData.data.Rating["5"]
                        }`}{" "}
                      Ratings
                    </a>{" "}
                    <br />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-hash "
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z" />
                    </svg>
                    <strong>33</strong> of 28740 Events in
                    <a
                      href="/top100/medical-pharma"
                      className="text-decoration-none text-blue"
                    >
                      {" "}
                      {event.EventData.data.Category[0]}
                    </a>
                    <div className="mt-2">
                      <img
                        src="https://c1.10times.com/images/svg/excellence-badge.svg"
                        alt="excellence-badge"
                        style={{ width: "20px", height: "20px" }}
                      />
                      <strong className="text-success ms-1">
                        Visitors' Choice Winner
                      </strong>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      className=""
                      style={{ marginLeft: "-15px", marginRight: "-15px" }}
                    >
                      <ul
                        style={{
                          listStyle: "none",
                          padding: "0px",
                          borderLeft: "1px solid #edecec",
                        }}
                      >
                        <li
                          style={{
                            display: "inline-block",
                            width: "25%",
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          {/* <div className='row'> */}
                          <div className="col">
                            <div
                              className="mx-2"
                              style={{ width: 50, height: 50 }}
                            >
                              <CircularProgressbar
                                value={percentage}
                                text={"4.6"}
                                styles={buildStyles({
                                  pathColor: "green",
                                  textColor: "black",
                                  trailColor: "#d6d6d6",
                                  backgroundColor: "#3e98c7",
                                  textSize: "22px",
                                  fontWeight: "bold",
                                })}
                              />
                            </div>
                            <div className="sm-x text-start mx-1">
                              Quality of <br /> Participants
                            </div>
                          </div>

                          {/* </div> */}
                        </li>
                        <li
                          style={{
                            display: "inline-block",
                            width: "25%",
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          {/* <div className='row'> */}
                          <div className="col">
                            <div
                              className="mx-2"
                              style={{ width: 50, height: 50 }}
                            >
                              <CircularProgressbar
                                value={percentage}
                                text={"4.6"}
                                styles={buildStyles({
                                  pathColor: "green",
                                  textColor: "black",
                                  trailColor: "#d6d6d6",
                                  backgroundColor: "#3e98c7",
                                  textSize: "22px",
                                  fontWeight: "bold",
                                })}
                              />
                            </div>
                            <div className="sm-x text-start mx-1">
                              Quality of <br /> Participants
                            </div>
                          </div>

                          {/* </div> */}
                        </li>
                        <li
                          style={{
                            display: "inline-block",
                            width: "25%",
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          {/* <div className='row'> */}
                          <div className="col">
                            <div
                              className="mx-2"
                              style={{ width: 50, height: 50 }}
                            >
                              <CircularProgressbar
                                value={percentage}
                                text={"4.6"}
                                styles={buildStyles({
                                  pathColor: "green",
                                  textColor: "black",
                                  trailColor: "#d6d6d6",
                                  backgroundColor: "#3e98c7",
                                  textSize: "22px",
                                  fontWeight: "bold",
                                })}
                              />
                            </div>
                            <div className="sm-x text-start mx-1">
                              Quality of <br /> Participants
                            </div>
                          </div>

                          {/* </div> */}
                        </li>
                        <li
                          style={{
                            display: "inline-block",
                            width: "25%",
                            textAlign: "center",
                            verticalAlign: "top",
                          }}
                        >
                          {/* <div className='row'> */}
                          <div className="col">
                            <div
                              className="mx-2"
                              style={{ width: 50, height: 50 }}
                            >
                              <CircularProgressbar
                                value={50}
                                text={"2.5"}
                                styles={buildStyles({
                                  pathColor: "#fdeb05",
                                  textColor: "black",
                                  trailColor: "#d6d6d6",
                                  backgroundColor: "#3e98c7",
                                  textSize: "22px",
                                  fontWeight: "bold",
                                })}
                              />
                            </div>
                            <div className="sm-x text-start mx-1">
                              Display & <br /> Participants
                            </div>
                          </div>

                          {/* </div> */}
                        </li>
                      </ul>
                      <a
                        style={{ marginRight: "15px" }}
                        href=""
                        className="sm float-end text-decoration-none text-blue"
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            {/* organzr */}
            <tr id="organizer">
              <td
                colSpan={2}
                className="p-0"
                style={{ borderBottomColor: "transparent!important" }}
              >
                <hr className="mt-1" />
                <h2 className="fw-bold" style={{ fontSize: "1.2em" }}>
                  Organizer
                </h2>
                {event.EventData.data.Organizer.Banner ? (
                  <img
                    className="img-thumbnail float-start me-2 lazyloaded"
                    src={event.EventData.data.Organizer.Banner}
                    alt="Logo"
                    width="65"
                    height="65"
                  />
                ) : (
                  ""
                )}

                <div className="float-end">
                  <button className="engageAction float-end btn btn-expo followingMe eventCompany text-decoration-none fs-14 ms-3 fw-bold cursor">
                    {" "}
                    Follow Company{" "}
                  </button>
                  <br />
                  <div className="fs-12 float-end mt-2">
                    <span className="text-muted">
                      Queries about the event?{" "}
                    </span>
                    <span className="x-ob-cd text-decoration-none cntct_orgnzr cursor text-blue">
                      Ask Organizer
                    </span>
                  </div>
                </div>
                <div style={{ display: "inherit" }}>
                  <h3
                    style={{
                      marginBottom: "1px",
                      marginTop: "-3px",
                      fontSize: "1.17em ",
                    }}
                  >
                    <a href="" className="text-decoration-none text-blue">
                      {event.EventData.data.Organizer.Name}{" "}
                    </a>
                    <small className="fs-12 rounded-2 top-rated">
                      Top Rated
                    </small>
                  </h3>
                  <small className="text-muted d-block">
                    {event.EventData.data.Organizer.Country}{" "}
                  </small>
                  <small
                    className="d-block"
                    style={{ marginTop: "0px", marginBottom: "-3px" }}
                  >
                    <span className="text-muted fw-bold">
                      {event.EventData.data.Organizer.NumberOfEvents}
                    </span>
                    <span className="text-muted"> Total Events</span>
                    <span className="text-muted">&nbsp; • &nbsp;</span>
                    <span className="text-muted fw-bold">
                      {event.EventData.data.Organizer.FollowersNumber}
                    </span>
                    <span className="text-muted"> Followers</span>
                  </small>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* whyToAttend */}
      {event.EventData.data.WhyToAttend.length > 0 ? (
        <section
          id="whyToAttend"
          className="box fs-14"
          style={{ fontWeight: "300" }}
        >
          <div>
            <h2
              className="fw-bold"
              style={{ fontSize: "1.2em", alignItems: "baseline" }}
            >
              Why to Attend?
            </h2>
          </div>
          <div>
            <div
              className="fs-14"
              style={{ margin: ".75em 0em", fontWeight: "500" }}
            >
              Popular among visitors for
            </div>
            <div className="text-muted quicklink-wrap">
              {event.EventData.data.WhyToAttend.map((question, index) => {
                return (
                  <strong key={index} className="quicklinks">
                    {question}
                  </strong>
                );
              })}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* participate section */}
      <section className="box fs-14 text-center pb-0">
        <div className="d-inline-block w-100">
          <span id="user-profile-pic">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="black"
              className="bi bi-person float-start border-2 rounded-circle"
              viewBox="0 0 16 16"
              style={{
                backgroundColor: "rgba(197, 197, 197, 0.2)",
                padding: "8",
              }}
            >
              <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
            </svg>
          </span>
          <span className="text-muted float-end position-relative">
            Edition
            <span className="fw-bold ms-1 btn btn-official fs-14 text-muted">
              Oct 2023
            </span>
          </span>
        </div>
        <div>
          <h2
            className="fw-bold"
            style={{ fontSize: "1.2em", alignItems: "baseline" }}
          >
            How would you like to participate in this event?
          </h2>
        </div>
        <div className="col-md-12 p-2 row pb-0">
          <div className="col-md p-2">
            <a className="action-attended prtcpt_as fs-14 me-2 text-decoration-none as_visitor cursor">
              <svg
                className="svg-inline--fa fa-users fa-w-20 rounded-2"
                aria-hidden="true"
                style={{
                  fontSize: "50px",
                  padding: ".12em .12em",
                  backgroundColor: "rgba(197, 197, 197, 0.2)",
                  color: "#646c74",
                  width: "45px",
                }}
                focusable="false"
                data-prefix="fas"
                data-icon="users"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
                ></path>
              </svg>
              <span className="d-block mt-1 text-muted">Visitor</span>
            </a>
          </div>
          <div className="col-md p-2">
            <a className="action-attended prtcpt_as x-rabb me-2 fs-14 text-decoration-none as_exhibitor cursor">
              <svg
                className="svg-inline--fa fa-address-book fa-w-14 rounded-2"
                aria-hidden="true"
                style={{
                  fontSize: "50px",
                  padding: ".12em .12em",
                  backgroundColor: "rgba(197, 197, 197, 0.2)",
                  color: "#646c74",
                  width: "45px",
                }}
                focusable="false"
                data-prefix="far"
                data-icon="address-book"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M436 160c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20V48c0-26.5-21.5-48-48-48H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h320c26.5 0 48-21.5 48-48v-48h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12h-20v-64h20zm-68 304H48V48h320v416zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2z"
                ></path>
              </svg>
              <span className="d-block mt-1 text-muted">Exhibitor</span>
            </a>
          </div>
          <div className="col-md p-2">
            <a className="action-attended prtcpt_as fs-14 text-decoration-none as_speaker cursor">
              <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="50"
                viewBox="0 0 512.000000 512.000000"
                preserveAspectRatio="xMidYMid meet"
                className="rounded-2"
                style={{
                  backgroundColor: "rgba(197, 197, 197, 0.2)",
                  padding: "5px",
                }}
              >
                <g
                  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                  fill="#646c74"
                  stroke="none"
                >
                  <path
                    d="M2405 5105 c-195 -37 -369 -139 -497 -290 -66 -77 -148 -234 -174
-330 -25 -93 -30 -285 -9 -386 64 -319 308 -574 630 -660 61 -16 109 -21 205
-21 155 0 242 21 387 92 226 111 397 337 448 589 23 111 16 294 -14 399 -122
422 -549 688 -976 607z m274 -320 c173 -41 323 -174 383 -340 26 -73 35 -209
19 -287 -38 -181 -184 -343 -360 -399 -91 -29 -231 -29 -321 0 -177 56 -323
219 -361 399 -16 78 -7 214 19 287 91 252 363 400 621 340z"
                  ></path>
                  <path
                    d="M3842 3822 c-94 -34 -178 -123 -203 -215 -16 -61 -7 -173 20 -225 76
-148 236 -215 390 -163 l63 22 41 -28 c60 -41 184 -167 245 -248 110 -146 209
-389 228 -557 l7 -59 -163 3 -163 3 -17 73 c-73 308 -310 581 -611 700 -174
69 -138 67 -1119 67 -826 0 -890 -1 -965 -19 -375 -86 -679 -384 -765 -748
l-17 -73 -349 -5 c-329 -5 -351 -6 -384 -26 -42 -24 -80 -87 -80 -132 0 -19
97 -301 222 -645 241 -665 240 -663 323 -686 26 -7 181 -11 440 -11 l401 0 57
-371 c62 -409 67 -423 140 -460 100 -52 227 24 227 136 0 22 -40 304 -90 625
-49 321 -90 590 -90 597 0 11 170 13 930 13 760 0 930 -2 930 -13 0 -7 -40
-276 -90 -597 -49 -321 -90 -603 -90 -625 0 -46 27 -95 70 -127 64 -47 171
-27 216 41 21 32 32 87 81 409 l57 372 401 0 c259 0 414 4 440 11 83 23 82 21
323 686 122 336 222 625 222 643 0 33 -29 99 -54 119 -8 7 -37 20 -63 30 l-48
17 -8 69 c-44 399 -282 809 -609 1049 -61 45 -68 54 -77 98 -17 76 -36 114
-86 167 -83 89 -220 123 -333 83z m-327 -974 c227 -78 388 -244 459 -475 l7
-23 -1421 0 -1421 0 7 23 c80 259 268 434 529 492 48 11 231 13 910 12 l850
-2 80 -27z m1211 -836 c-3 -9 -73 -201 -155 -427 l-149 -410 -316 -3 c-313 -2
-316 -2 -316 18 0 12 12 98 26 191 23 146 25 176 14 211 -15 53 -56 94 -108
107 -28 8 -377 11 -1184 9 -1133 -3 -1143 -3 -1178 -24 -40 -23 -80 -86 -80
-126 0 -14 11 -98 25 -185 14 -87 25 -168 25 -181 l0 -22 -316 2 -316 3 -149
410 c-82 226 -152 418 -155 427 -5 17 106 18 2166 18 2060 0 2171 -1 2166 -18z"
                  ></path>
                </g>
              </svg>
              <span className="d-block mt-1 text-muted">Speaker</span>
            </a>
          </div>
        </div>
      </section>
      {/* followers section  */}
      <section className="box visitors-block fs-14">
        <h2
          className="visitors fw-bold d-flex"
          style={{ alignItems: "baseline", fontSize: "1.2em" }}
        >
          Followers
          <strong className="text-muted fs-14 fw-500 ms-1">
            [ Users who have shown interest for this Event ]
          </strong>
          <span className="d-flex" style={{ marginLeft: "auto" }}>
            <span className="btn btn-sm btn-expo x-iai join-comm text-decoration-none fs-12 cursor">
              Join Community
            </span>
            <span className="btn btn-sm btn-official x-rabb ms-2 text-decoration-none fs-12 cursor">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-person-fill-add"
                viewBox="0 0 16 16"
              >
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
              </svg>
              Invite
            </span>
          </span>
        </h2>
        <div className="row my-3">
          <div className="col-md-12 d-flex">
            <div>
              <select
                name=""
                id="visitor_designation"
                className="btn btn-official text-muted selOpt fs-14"
                style={{ width: "120px", marginRight: "5px" }}
              >
                <option value="">All Profiles</option>
                {Object.keys(event.EventData.data.jobCounts).map(
                  (key, index) => (
                    <option key={index} value={key}>
                      {key} [ {event.EventData.data.jobCounts[key]} ]
                    </option>
                  )
                )}
              </select>

              <select
                name=""
                id="visitor_designation"
                className="btn btn-official text-muted selOpt fs-14"
                style={{ width: "120px", marginRight: "5px" }}
              >
                <option value="">All Countries</option>
                <option value="IN">India</option>
                <option value="US">USA</option>
                <option value="AE">UAE</option>
              </select>
            </div>
            <div className="top-profile">
              <span className="text-muted fs-12">Sort By </span> &nbsp;
              <select
                name=""
                id="visitor_designation"
                className="btn btn-official text-muted selOpt fs-14"
                style={{ width: "120px", marginRight: "5px" }}
              >
                <option value="Top_Profiles"> Top Profiles</option>
                <option value="Recommended">Recommended</option>
              </select>
            </div>
          </div>
        </div>
        <div className="visitor clearfix row flex-wrap">
          {event.EventData.data.Followers.map((follower, index) => {
            return (
              <div key={index} className="col-md-3 col-sm-12">
                <div className="box box-210 text-center bx-hl position-relative">
                  <div
                    className="scroll no-scroller navbar-nav-scroll"
                    style={{ height: "155px" }}
                  >
                    <small
                      className="text-success position-absolute"
                      style={{ top: "5px", left: "5px" }}
                    >
                      {" "}
                      Going
                    </small>
                    <span
                      className="follow text-muted position-absolute fs-14"
                      style={{
                        top: "0px",
                        right: "0px",
                        height: "20px",
                        width: "20px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-heart-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                        />
                      </svg>
                    </span>
                    <a href="" className="text-decoration-none">
                      <div className="d-flex justify-content-center">
                        <div>
                          <img
                            className="rounded-2 img-48 lazyloaded"
                            src={follower.Image}
                            alt="Gorav Gupta"
                          />
                        </div>
                      </div>
                      <div className="color-333 mt-2 visitor_name">
                        {follower.FirstName}
                      </div>
                    </a>
                    <p
                      className="fs-12 text-muted"
                      style={{
                        marginTop: "5px",
                        marginBottom: "10px",
                        lineHeight: "14px",
                      }}
                    >
                      {follower.Role} at {follower.Organization}
                    </p>
                    <small className="d-block text-muted fs-12">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        fill="currentColor"
                        className="bi bi-geo-alt-fill me-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                      </svg>
                      {follower.City}, {follower.Country}
                    </small>
                  </div>
                  <div className="row action-button">
                    <button className="sugg_button btn btn-block fw-bold fs-14 x-vs-cb">
                      Connect
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      {/* progress and map section */}
      <div className="box border-top-radius fw-normal d-flex flex-wrap">
        <div className="col-md-6 fs-14">
          <section id="visitor_prof">
            <h2
              className="fw-bold"
              style={{
                fontSize: "1.2em",
                alignItems: "baseline",
                width: "265px",
                padding: "1px 10px",
              }}
            >
              Audience Profile
              <span className="float-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-heart-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                  />
                </svg>
              </span>
            </h2>
            <div id="designation_spread" className="w-100 fs-14">
              <div className="tb-list">
                <div
                  className="d-block fs-14"
                  style={{ padding: "1px 10px", height: "52px" }}
                >
                  <div className="aud_box_col text-left fs-14 clickable-row cursor">
                    Doctor
                  </div>
                  <div style={{ width: "245px" }}>
                    <div
                      style={{
                        marginBottom: "0px !important",
                        height: "6px",
                      }}
                      className="progress"
                    >
                      <a
                        className="clickable-row cursor progress-bar progress-bar-orange bg-expo"
                        title="progressbar-label"
                        style={{ width: "100%" }}
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  className="d-block fs-14"
                  style={{ padding: "1px 10px", height: "52px" }}
                >
                  <div className="aud_box_col text-left fs-14 clickable-row cursor">
                    Owner
                  </div>
                  <div style={{ width: "245px" }}>
                    <div
                      style={{
                        marginBottom: "0px !important",
                        height: "6px",
                      }}
                      className="progress"
                    >
                      <a
                        className="clickable-row cursor progress-bar progress-bar-orange bg-expo"
                        title="progressbar-label"
                        style={{ width: "90%" }}
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  className="d-block fs-14"
                  style={{ padding: "1px 10px", height: "52px" }}
                >
                  <div className="aud_box_col text-left fs-14 clickable-row cursor">
                    Director
                  </div>
                  <div style={{ width: "245px" }}>
                    <div
                      style={{
                        marginBottom: "0px !important",
                        height: "6px",
                      }}
                      className="progress"
                    >
                      <a
                        className="clickable-row cursor progress-bar progress-bar-orange bg-expo"
                        title="progressbar-label"
                        style={{ width: "70%" }}
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  className="d-block fs-14"
                  style={{ padding: "1px 10px", height: "52px" }}
                >
                  <div className="aud_box_col text-left fs-14 clickable-row cursor">
                    Proprietor
                  </div>
                  <div style={{ width: "245px" }}>
                    <div
                      style={{
                        marginBottom: "0px !important",
                        height: "6px",
                      }}
                      className="progress"
                    >
                      <a
                        className="clickable-row cursor progress-bar progress-bar-orange bg-expo"
                        title="progressbar-label"
                        style={{ width: "60%" }}
                      ></a>
                    </div>
                  </div>
                </div>
                <div
                  className="d-block fs-14"
                  style={{ padding: "1px 10px", height: "52px" }}
                >
                  <div className="aud_box_col text-left fs-14 clickable-row cursor">
                    Manager
                  </div>
                  <div style={{ width: "245px" }}>
                    <div
                      style={{
                        marginBottom: "0px !important",
                        height: "6px",
                      }}
                      className="progress"
                    >
                      <a
                        className="clickable-row cursor progress-bar progress-bar-orange bg-expo"
                        title="progressbar-label"
                        style={{ width: "50%" }}
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="col-md-6 fs-14">
          <section id="visitor_map">
            <h2
              className="fw-bold"
              style={{ fontSize: "1.2em", alignItems: "baseline" }}
            >
              Audience Spread
            </h2>
            <ul className="nav nav-tabs spread_tab">
              <li role="presentation" className="activeDsk country_tab">
                <a
                  href=""
                  className="text-decoration-none text-muted rounded-2 d-block"
                  style={{
                    padding: "10px 15px",
                    border: "1px solid #ddd",
                    borderBottomColor: "transparent",
                  }}
                >
                  Global
                </a>
              </li>
            </ul>
            <div className="mapcontainer mapael">
              <div className="map">UnderConstruction</div>
            </div>
          </section>
          <div id=""></div>
        </div>
      </div>
      {/* Frequently section */}
      {event.EventData.data.FrequentlyAskedQuestions.lenght > 0 ? (
        <section className="box fs-14" id="Q_A">
          <div className="row">
            <div className="col-md-12" style={{ position: "relative" }}>
              <h2
                className="visitors fw-bold d-flex"
                style={{ alignItems: "baseline", fontSize: "1.2em" }}
              >
                Frequently Asked Questions
                <span className="d-flex" style={{ marginLeft: "auto" }}>
                  <span className="btn btn-sm btn-expo x-iai join-comm text-decoration-none fs-12 cursor">
                    Contact Organizer
                  </span>
                </span>
              </h2>
              <hr className="mt-3 mb-3" />

              <Accordion defaultActiveKey="0">
                {event.EventData.data.FrequentlyAskedQuestions.map(
                  (faq, index) => {
                    return (
                      <Accordion.Item key={index} eventKey={index}>
                        <Accordion.Header>{faq.question}</Accordion.Header>
                        <Accordion.Body>{faq.answer}</Accordion.Body>
                        <hr className="mt-3 mb-3" />
                      </Accordion.Item>
                    );
                  }
                )}
              </Accordion>

              <div
                id="ask_faq_question"
                className="text-center margin more_questions my-2"
              >
                <a
                  href=""
                  className="btn btn-blue text-decoration-none fs-14 faq_ask_contact"
                >
                  Ask More Questions
                </a>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      {/* Review  section*/}
      {showRatingSection ? (
        <form onSubmit={handleSubmit}>
          <section className="box" id="verified-review-open">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: "20px" }}>
                <strong className="text-muted">Write a Review</strong>
                <a
                  onClick={handelRating}
                  className="float-end text-danger text-decoration-none fs-14 cursor"
                >
                  Cancel
                </a>
              </div>
              <div className="col-md-2">
                <img
                  style={{ borderRadius: "50%", marginTop: "-15px" }}
                  width={50}
                  src={`${JSON.parse(window.localStorage.getItem("userInfo")).image}`}
                  alt={`${JSON.parse(window.localStorage.getItem("userInfo")).firstName}`}
                />
              </div>
              <div className="col-md-10">
                <span className="vf-rating">
                  <input
                    type="radio"
                    id="star5"
                    name="rating"
                    value="5"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star5" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star4"
                    name="rating"
                    value="4"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star4" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star3"
                    name="rating"
                    value="3"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star3" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star2"
                    name="rating"
                    value="2"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star2" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star1"
                    name="rating"
                    value="1"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star1" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                </span>
                <span className="float-end" style={{ marginTop: "10px" }}>
                  <span className="text-muted fs-12">Edition Visited</span>
                </span>
                <div
                  className="mt-2"
                  style={{ position: "relative", clear: "both" }}
                >
                  <textarea
                    id="word-count"
                    className="form-control fs-14"
                    style={{ resize: "vertical" }}
                    rows="6"
                    placeholder="Share details of your experience at this event"
                    name="review"
                    value={variables.review}
                    onChange={handleInputChange}
                  />
                  <div
                    className="word-counter fs-12 text-danger"
                    style={{
                      position: "absolute",
                      right: "15px",
                      bottom: "10px",
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={{ clear: "both" }}>
                  <div className="dropdown float-end">
                    <a href="" className="text-decoration-none text-blue">
                      <span className="fs-12">
                        Tips for writing great review
                      </span>
                    </a>
                  </div>
                  <span className="fs-12 d-none error-message d-block text-danger"></span>
                </div>
                <div className="mt-2" style={{ clear: "both" }}>
                  <label htmlFor="uploadimage" className="image-upload">
                    <input
                      id="uploadimage"
                      type="file"
                      accept="image/*"
                      name="imgUpload"
                      onChange={handleInputChange}
                    />
                    <img
                      style={{
                        height: "inherit",
                        width: "inherit",
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      src={
                        selectedFile ||
                        "https://c1.10times.com/images/add-photo.png"
                      }
                      alt="Add photos"
                    />
                  </label>
                  <span
                    className="text-muted d-block fs-12"
                    style={{ marginTop: "-5px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="green"
                      className="bi bi-shield-check mx-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                      <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    Get verified listing of your feedback by uploading pictures
                    of your event visit
                  </span>
                </div>
                <div className="mt-2">
                  {/* <a
                    href=""
                    className="past_edition btn btn-expo float-end fs-14"
                  >
                    Submit Your Review
                  </a> */}
                </div>
              </div>
            </div>
          </section>
          <section className="box fs-14" id="verified-review-question">
            <div className="row">
              <div className="col-md-12">
                <h2 className="" style={{ fontSize: "1.2em" }}>
                  What do you like the most about
                </h2>
                <div className="row">
                  <div className="col-md-4 fq-event">
                    <strong className="text-muted">Event</strong>
                    {tagsDataEvent.map((tag, index) => (
                      <div className="mt-2" key={index}>
                        <span
                          className={`suggestion-tag-new vf-question ${activeTags.includes(tag) ? "vf-answer" : ""
                            }`}
                          onClick={() => {
                            handleTagClick(tag);
                            setSelectedOptions([...selectedOptions, tag]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-hand-thumbs-up-fill mb-1 me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-4 fq-venue">
                    <strong className="text-muted">Venue</strong>
                    {tagsDataVenue.map((tag, index) => (
                      <div className="mt-2" key={index}>
                        <span
                          className={`suggestion-tag-new vf-question ${activeTags.includes(tag) ? "vf-answer" : ""
                            }`}
                          onClick={() => {
                            handleTagClick(tag);
                            setSelectedOptions([...selectedOptions, tag]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-hand-thumbs-up-fill mb-1 me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-4 fq-orgnizer">
                    <strong className="text-muted">Organizer</strong>
                    {tagsDataOrganizer.map((tag, index) => (
                      <div className="mt-2" key={index}>
                        <span
                          className={`suggestion-tag-new vf-question ${activeTags.includes(tag) ? "vf-answer" : ""
                            }`}
                          onClick={() => {
                            handleTagClick(tag);
                            setSelectedOptions([...selectedOptions, tag]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-hand-thumbs-up-fill mb-1 me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-12 mt-2 fq-comeback">
                    <strong className="text-muted mt-2 d-block">
                      Would you like to comeback?
                    </strong>
                    <div className="mt-2">
                      <span
                        className={`suggestion-tag-new vf-question mx-2 ${activeTags.includes("yes") ? "vf-answer" : ""
                          }`}
                        onClick={() => {
                          handleTagClick("yes");
                        }}
                        style={{ width: "auto" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={`${activeTags.includes("yes") ? "white" : "green"
                            }`}
                          className="bi bi-emoji-smile me-1 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                        </svg>{" "}
                        Yes
                      </span>
                      <span
                        style={{ width: "auto" }}
                        className={`suggestion-tag-new vf-question mx-2 ${activeTags.includes("no") ? "vf-no" : ""
                          }`}
                        onClick={() => {
                          handleTagClick("no");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={`${activeTags.includes("no") ? "white" : "red"
                            }`}
                          className="bi bi-emoji-frown me-1 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                        </svg>{" "}
                        No
                      </span>
                      <span
                        style={{ width: "auto" }}
                        className={`suggestion-tag-new vf-question mx-2 ${activeTags.includes("may") ? "vf-may" : ""
                          }`}
                        onClick={() => {
                          handleTagClick("may");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={`${activeTags.includes("may") ? "white" : "yellow"
                            }`}
                          className="bi bi-emoji-neutral me-1 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z" />
                        </svg>{" "}
                        Maybe
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    {/* <a
                      href=""
                      className="btn btn-expo d-block float-end custom-submit fs-14"
                    > */}
                    <button
                      className="btn btn-expo d-block float-end custom-submit fs-14"
                      type="submit"
                    >
                      Submit
                    </button>

                    {/* </a> */}
                    <a
                      className="btn btn-official  float-end custom-skip fs-14 me-2"
                      onClick={handelRating}
                    >
                      Skip
                    </a>
                    {/* <a href="" className="btn btn-expo fs-14">
                      Back
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      ) : (
        <section id="verified-review" className="box fs-14">
          <div className="row">
            <div className="col-md-12 position-relative">
              <h2
                className="visitors fw-bold d-flex"
                style={{ alignItems: "baseline", fontSize: "1.2em" }}
              >
                Write a Review
              </h2>
              <input
                type="text"
                name="verifiedReview"
                className="engageAction form-control bg-white text-muted fs-14 cursor"
                data-action="verifiedReview"
                data-settle="review"
                style={{ paddingLeft: "30px" }}
                value="Help other visitors by sharing your review."
                aria-label="verified review"
                readOnly
                onClick={() => {
                  handelRating();
                }}
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-fill position-absolute text-muted fs-12"
                style={{ bottom: "90px", left: "25px" }}
                viewBox="0 0 16 16"
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
              </svg>
              <p className={`${login ? "visible" : "invisible"} text-danger`}>Must be Register</p>
              <span className="engageAction btn btn-expo mt-2 text-decoration-none fs-14 fw-bold cursor">
                Add Your Review
              </span>
            </div>
          </div>
        </section>
      )}
      {/* <section className="box" id="my-review">
        <div className="row">
          <div className="col-md-12" style={{ position: "relative" }}>
            <div className="row df">
              <div className="col-md-12  feedback-block">
                <img
                  className="float-start rounded-2 img-48 lazyloaded"
                  src="https://img.10times.com/user_pic%2FnUjEqF4zq9RsczNFelovYEKBO4XUVJtTbTcYBnUos5J0DOFN2sO%2BAh4fvbQ44KZN09lnHk%2BhxaGZohS%2BQAw%3D%3D.jpg?imgeng=/w_32/h_32/m_stretch/cmpr_60"
                  alt="profile_pic"
                />
                <div className="float-end text-end">
                  <span
                    className="label barclass3 rounded-2 fw-bold p-1"
                    style={{
                      padding: "5px 6px",
                      color: "#fff",
                      fontSize: "10px",
                    }}
                  >
                    3
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-star-fill mx-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                    </svg>
                  </span>{" "}
                  <br />
                  <span
                    style={{ clear: "both" }}
                    className="text-muted fs-12  reviewCreated"
                  >
                    today
                  </span>
                </div>
                <div
                  className="d-inline-block"
                  style={{ lineHeight: "1em", marginLeft: "7px" }}
                >
                  <span className="fs-14 fw-bold"> ahmed Elsayed</span>
                  <span className="ms-1 text-muted fs-12">Visitor</span>
                  <span className="text-muted d-block fs-12">
                    {" "}
                    Web Developer at switch communcation
                  </span>
                  <span className="text-muted d-block fs-12">Qena, Egypt</span>
                </div>
                <div className="mt-2 fs-14" style={{ wordBreak: "break-word" }}>
                  This my first comment
                </div>
                <div className="mt-3">
                  <a
                    href=""
                    className="helpul-btn text-muted float-end engageAction my-review"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-pencil me-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                    </svg>{" "}
                    Edit
                  </a>
                  <a href="" className="helpul-btn text-muted engageAction">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-hand-thumbs-up-fill me-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                    </svg>{" "}
                    Helpful
                  </a>
                  <a
                    href=""
                    className="helpul-btn text-muted engageAction ms-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-chat-square me-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    </svg>{" "}
                    Reply
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Rating section */}
      <section className="box fs-14" id="review_all">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-md-6 border-right-1-gray">
                User Rating <br />
                <div className="fs-20 fw-normal mb-2">
                  {event.EventData.data.Rating.Average.toFixed(1)}
                  <small className="text-muted fs-12">/5 </small>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    fill="#862efb"
                    className="bi bi-star-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                  </svg>
                </div>
                <a href="" className="text-decoration-none text-blue">
                  <small>
                    <strong>
                      {`${event.EventData.data.Rating["1"] +
                        event.EventData.data.Rating["2"] +
                        event.EventData.data.Rating["3"] +
                        event.EventData.data.Rating["4"] +
                        event.EventData.data.Rating["5"]
                        }`}{" "}
                      Ratings
                      <br />
                      {event.EventData.reviews.length} Reviews
                    </strong>
                  </small>
                </a>
              </div>
              <div className="col-md-6 px-4">
                <div className="col-md-12 mt-2 d-flex flex-wrap">
                  <div
                    className="float-start me-1"
                    style={{ marginTop: "-7px" }}
                  >
                    <small
                      className="text-muted"
                      style={{ paddingRight: "2px", fontSize: " smaller" }}
                    >
                      5{" "}
                    </small>
                  </div>
                  <div
                    className="fs-12 mb-1 overflow-hidden progress rounded-2 col-md-9"
                    style={{
                      backgroundColor: "#f0f0f0 ",
                      height: "8px ",
                      width: "245px ",
                    }}
                  >
                    <div
                      className="progress-bar barclass5 rounded-2"
                      style={{
                        width: `${(event.EventData.data.Rating["5"] /
                          (event.EventData.data.Rating["1"] +
                            event.EventData.data.Rating["2"] +
                            event.EventData.data.Rating["3"] +
                            event.EventData.data.Rating["4"] +
                            event.EventData.data.Rating["5"])) *
                          100
                          }%`,
                        lineHeight: "15.5px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold text-muted ms-2"
                    style={{ marginTop: "-3px", fontSize: " smaller" }}
                  >
                    {event.EventData.data.Rating["5"]}
                  </div>
                </div>
                <div className="col-md-12 mt-2 d-flex flex-wrap">
                  <div
                    className="float-start me-1"
                    style={{ marginTop: "-7px" }}
                  >
                    <small
                      className="text-muted"
                      style={{ paddingRight: "2px", fontSize: " smaller" }}
                    >
                      4{" "}
                    </small>
                  </div>
                  <div
                    className="fs-12 mb-1 overflow-hidden progress rounded-2 col-md-9"
                    style={{
                      backgroundColor: "#f0f0f0 ",
                      height: "8px ",
                      width: "245px ",
                    }}
                  >
                    <div
                      className="progress-bar barclass4 rounded-2"
                      style={{
                        width: `${(event.EventData.data.Rating["4"] /
                          (event.EventData.data.Rating["1"] +
                            event.EventData.data.Rating["2"] +
                            event.EventData.data.Rating["3"] +
                            event.EventData.data.Rating["4"] +
                            event.EventData.data.Rating["5"])) *
                          100
                          }%`,
                        lineHeight: "15.5px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold text-muted ms-2"
                    style={{ marginTop: "-3px", fontSize: " smaller" }}
                  >
                    {event.EventData.data.Rating["4"]}
                  </div>
                </div>
                <div className="col-md-12 mt-2 d-flex flex-wrap">
                  <div
                    className="float-start me-1"
                    style={{ marginTop: "-7px" }}
                  >
                    <small
                      className="text-muted"
                      style={{ paddingRight: "2px", fontSize: " smaller" }}
                    >
                      3{" "}
                    </small>
                  </div>
                  <div
                    className="fs-12 mb-1 overflow-hidden progress rounded-2 col-md-9"
                    style={{
                      backgroundColor: "#f0f0f0 ",
                      height: "8px ",
                      width: "245px ",
                    }}
                  >
                    <div
                      className="progress-bar barclass3 rounded-2"
                      style={{
                        width: `${(event.EventData.data.Rating["3"] /
                          (event.EventData.data.Rating["1"] +
                            event.EventData.data.Rating["2"] +
                            event.EventData.data.Rating["3"] +
                            event.EventData.data.Rating["4"] +
                            event.EventData.data.Rating["5"])) *
                          100
                          }%`,
                        lineHeight: "15.5px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold text-muted ms-2"
                    style={{ marginTop: "-3px", fontSize: " smaller" }}
                  >
                    {event.EventData.data.Rating["3"]}
                  </div>
                </div>
                <div className="col-md-12 mt-2 d-flex flex-wrap">
                  <div
                    className="float-start me-1"
                    style={{ marginTop: "-7px" }}
                  >
                    <small
                      className="text-muted"
                      style={{ paddingRight: "2px", fontSize: " smaller" }}
                    >
                      2{" "}
                    </small>
                  </div>
                  <div
                    className="fs-12 mb-1 overflow-hidden progress rounded-2 col-md-9"
                    style={{
                      backgroundColor: "#f0f0f0 ",
                      height: "8px ",
                      width: "245px ",
                    }}
                  >
                    <div
                      className="progress-bar barclass2 rounded-2"
                      style={{
                        width: `${(event.EventData.data.Rating["2"] /
                          (event.EventData.data.Rating["1"] +
                            event.EventData.data.Rating["2"] +
                            event.EventData.data.Rating["3"] +
                            event.EventData.data.Rating["4"] +
                            event.EventData.data.Rating["5"])) *
                          100
                          }%`,
                        lineHeight: "15.5px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold text-muted ms-2"
                    style={{ marginTop: "-3px", fontSize: " smaller" }}
                  >
                    {event.EventData.data.Rating["2"]}
                  </div>
                </div>
                <div className="col-md-12 mt-2 d-flex flex-wrap">
                  <div
                    className="float-start me-1"
                    style={{ marginTop: "-7px" }}
                  >
                    <small
                      className="text-muted"
                      style={{ paddingRight: "2px", fontSize: " smaller" }}
                    >
                      1{" "}
                    </small>
                  </div>
                  <div
                    className="fs-12 mb-1 overflow-hidden progress rounded-2 col-md-9"
                    style={{
                      backgroundColor: "#f0f0f0 ",
                      height: "8px ",
                      width: "245px ",
                    }}
                  >
                    <div
                      className="progress-bar barclass1 rounded-2"
                      style={{
                        width: `${(event.EventData.data.Rating["1"] /
                          (event.EventData.data.Rating["1"] +
                            event.EventData.data.Rating["2"] +
                            event.EventData.data.Rating["3"] +
                            event.EventData.data.Rating["4"] +
                            event.EventData.data.Rating["5"])) *
                          100
                          }%`,
                        lineHeight: "15.5px",
                      }}
                    ></div>
                  </div>
                  <div
                    className="fw-bold text-muted ms-2"
                    style={{ marginTop: "-3px", fontSize: " smaller" }}
                  >
                    {event.EventData.data.Rating["1"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        {event.EventData.reviews.length > 0 ? (
          <>
            <h2
              className="visitors fw-bold d-flex"
              style={{ alignItems: "baseline", fontSize: "1.2em" }}
            >
              Reviews with images
            </h2>
            <div className="position-relative fs-14">
              <div className="text-nowrap overflow-hidden cursor">
                <div
                  className="photos_tumbnail_box no-scroller navbar-nav-scroll"
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  {event.EventData.reviews.map((review, index) => {
                    return (
                      <React.Fragment key={index}>
                        {review.Images.length > 0 ? (
                          <div
                            className="gallary-card-review position-relative cursor me-2 m-0"
                            style={{
                              width: "225px",
                              height: "150px",
                              flex: "0 0 225px",
                            }}
                          >
                            <img
                              className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                              src={review.Images[0]}
                              width="225"
                              height="150"
                            />
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </div>
                <button className="btn btn-sm border-0 text-blue float-end">
                  View More
                </button>
              </div>
            </div>
            <hr />
          </>
        ) : (
          ""
        )}

        {event.EventData.reviews.length > 0 ? (
          <>
            <h2
              className="visitors fw-bold d-flex"
              style={{ alignItems: "baseline", fontSize: "1.2em" }}
            >
              User Reviews
            </h2>
            <div className="row">
              {event.EventData.reviews.map((review, index) => {
                return (
                  <div key={index}>
                    <div className="col-md-12  feedback-block">
                      <a href="">
                        <img
                          className="float-start rounded-2 img-48 lazyloaded"
                          src={review.User.Image}
                          alt="abc"
                        />
                      </a>
                      <div className="float-end text-end">
                        <span
                          className={`fw-bold rounded-2 barclass${review.Rating} p-1`}
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            padding: "5px 6px !important ",
                          }}
                        >
                          {review.Rating}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-star-fill mx-1 mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                        <br />
                        <small style={{ clear: "both" }} className="text-muted">
                          {moment(review.Date).format("D MMM YYYY")}
                        </small>
                      </div>
                      <div
                        className="d-inline-block"
                        style={{ lineHeight: "1em", marginLeft: "7px" }}
                      >
                        <strong>{review.User.FirstName}</strong>
                        <small className="text-expo ms-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-shield-check"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                            <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                          </svg>
                        </small>
                        <small className="ms-1 text-muted fs-12">Visitor</small>
                        <small className="text-muted d-block">
                          {" "}
                          {review.User.Role} at {review.User.Organization}
                        </small>
                        <small className="text-muted d-block">
                          {review.User.City}, {review.User.Country}
                        </small>
                      </div>
                      <div
                        className=""
                        style={{
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          width: "inherit",
                          overflowY: "hidden",
                        }}
                      >
                        <ul
                          style={{
                            listStyle: "none",
                            paddingLeft: "0px",
                            marginTop: "10px ",
                            marginBottom: "0px",
                          }}
                        >
                          {review.About.map((like, index) => {
                            return (
                              <li
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginLeft: "0px",
                                  marginRight: "10px",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <span className="suggestion-tag sm-x ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="red"
                                    className={`bi bi-hand-thumbs-up-fill test-${review.Rating}`}
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                  </svg>{" "}
                                  {like}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="mt-2">{review.Content}</div>
                      <div
                        className="mt-4 photos_tumbnail_box no-scroller navbar-nav-scroll"
                        style={{ overflowX: " auto", whiteSpace: "nowrap" }}
                      >
                        {review.Images.map((img, index) => {
                          return (
                            <div
                              key={index}
                              className="gallary-card-review position-relative cursor me-2 m-0"
                              style={{
                                width: "70px",
                                height: "70px",
                                flex: "0 0 70px",
                              }}
                            >
                              <img
                                className="d-inline-block position-absolute photos-tumbnail lazyloaded"
                                src={img}
                                width="70"
                                height="70"
                                alt="gallery"
                                style={{ margin: "0px 5px" }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <span className="helpul-btn text-muted float-end engageAction text-decoration-none cursor">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-flag-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                          </svg>{" "}
                          Reported
                        </span>
                        <span className="cursor helpul-btn text-muted engageAction text-decoration-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-hand-thumbs-up-fill me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          Helpful
                          <strong className="text-muted response-stats">
                            {" "}
                            {review.Helpful}
                          </strong>
                        </span>
                        <span className="cursor helpul-btn text-muted engageAction text-decoration-none ms-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-tv me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" />
                          </svg>
                          Reply
                          <strong className="text-muted response-stats">
                            {" "}
                            {review.Replay}
                          </strong>
                        </span>
                      </div>
                    </div>
                    <hr style={{ marginTop: "10px" }} />
                  </div>
                );
              })}
            </div>
            <div className="text-center margin">
              <a href="" className="btn btn-blue text-decoration-none fs-14">
                View All Reviews
              </a>
            </div>
          </>
        ) : (
          ""
        )}
      </section>
      {/* Visitor Ticket section */}
      <section className="box ticket-price fs-14" id="vistktPrice">
        <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
          Visitor Ticket Price
          <span className="btn btn-official d-block x-pt float-end registerButton text-decoration-none fs-14 cursor mb-2">
            Register
          </span>
        </h2>
        <table className="tb-list w-100 fs-14">
          <tbody>
            {event.EventData.data.PricingOfTickets.filter(
              (ticket, index) => index !== 0
            ).map((ticket, index) => (
              <tr key={index}>
                <td
                  className="text-end"
                  style={{ width: "200px", verticalAlign: "top" }}
                >
                  <span className="text-muted d-block">
                    {ticket.price == 0 ? "FREE" : ticket.price} <br />{" "}
                    {ticket.currency}
                  </span>
                </td>
                <td className="line">
                  <strong>{ticket.header}</strong>
                  <span className="text-muted d-block">
                    {ticket.description}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {/* Exhibit Space Cost section */}
      <section className="box ticket-price fs-14">
        <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
          Exhibit Space Cost
        </h2>
        <table className="tb-list w-100 fs-14">
          <tbody>
            <tr className="ticketdata">
              <td style={{ width: "75%" }}>
                <strong>
                  Standard package (min 12 sqm): RMB 2,800 / sqm (Zone A) (1
                  sq.mt.){" "}
                </strong>
                <p>
                  <strong className="text-expo">CNY 2800 </strong>
                </p>
              </td>
              <td className="text-end">
                <p>
                  <a
                    href=""
                    className="btn btn-official ticketEnquireStall text-decoration-none fs-14"
                  >
                    Request Space
                  </a>
                </p>
              </td>
            </tr>
            <tr className="ticketdata">
              <td style={{ width: "75%" }}>
                <strong>
                  Raw space (min 36 sqm): RMB 2,500 / sqm (Zone A) (1 sq.mt.){" "}
                </strong>
                <p>
                  <strong className="text-expo">CNY 2500 </strong>
                </p>
              </td>
              <td className="text-end">
                <p>
                  <a
                    href=""
                    className="btn btn-official ticketEnquireStall text-decoration-none fs-14"
                  >
                    Request Space
                  </a>
                </p>
              </td>
            </tr>
            <tr className="tiketdata">
              <td style={{ width: "75%" }}>
                <strong>
                  Standard package (min 12 sqm): RMB 1,500 / sqm (Zone B) (1
                  sq.mt.){" "}
                </strong>
                <p>
                  <strong className="text-expo">CNY 1500 </strong>
                </p>
              </td>
              <td className="text-end">
                <p>
                  <a
                    href=""
                    className="btn btn-official ticketEnquireStall text-decoration-none fs-14"
                  >
                    Request Space
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      {/* advertise section */}
      <section className="box fs-14">
        <h5
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100px", minHeight: "100px" }}
        >
          advertise with us
        </h5>
      </section>
      {/* Exhibitors Profile section  */}
      {event.EventData.data.ExhibitorsProfile !== "Null" ? (
        <section className="box fs-14" id="exhib_profile">
          <span className="float-end">
            <a href="" className="text-decoration-none px-1 text-blue">
              Exhibitors List
            </a>
            <a href="" className="text-decoration-none px-1 text-blue">
              Want to Exhibit?
            </a>
          </span>
          <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
            Exhibitors Profile
          </h2>
          <p className="text-break">
            {event.EventData.data.ExhibitorsProfile}{" "}
          </p>
        </section>
      ) : (
        ""
      )}
      {/* Exhibitors List section */}
      <section className=" fs-14" id="exhib" style={{ marginTop: "-18px" }}>
        <div className="box pb-0" style={{ bottom: "-18px" }}>
          <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
            Exhibitors List
          </h2>
          <a href="" className="text-decoration-none text-blue">
            <strong className="d-block" style={{ paddingBottom: "10px" }}>
              <small>
                {event.EventData.data.Exhibitors.length} available exhibitors
              </small>
            </strong>
          </a>
        </div>
        <div>
          {event.EventData.data.Exhibitors.map((exhibit, index) => {
            return (
              <div
                key={index}
                className="exhibitorsBlock row py-2 mx-0 mb-2 bg-white deep-shadow"
              >
                <div className="col-12 text-dark">
                  <div className="small fw-500">
                    <span>Exhibited In Nov 2018</span>
                  </div>
                </div>
                <div className="col-12 text-break">
                  <h2
                    className="exhibitorName position-relative mb-0"
                    style={{ fontSize: "1.2em " }}
                  >
                    <span className="position-absolute end-0">
                      {exhibit.Banner ? (
                        <img
                          className="rounded-3 float-end img-48 lazyloaded"
                          src={exhibit.Banner}
                          alt={exhibit.Name}
                          loading="lazy"
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span className="d-block" style={{ marginRight: "3.5rem" }}>
                      <span> {exhibit.Name}</span>
                      <span className="d-inline-block">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="#862efb"
                          className="bi bi-shield-check mx-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                          <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        </svg>
                      </span>
                    </span>
                  </h2>
                </div>
                <div className="col-12 mb-2">
                  <span className="small">
                    {exhibit.City !== "N/A" ? `${exhibit.City},` : ""}{" "}
                    {exhibit.Country}
                  </span>
                </div>
                <div
                  className="col-12 small text-muted mb-2"
                  style={{ lineHeight: "2" }}
                >
                  <span className="d-inline-block small me-2 p-1 lh-1 bg-light rounded-1">
                    <span className="website engageAction text-decoration-none cursor fw-500 text-blue exhibitrs_website">
                      <a
                        href={exhibit.Website !== "Null" ? exhibit.Website : ""}
                        className="text-decoration-none text-blue"
                      >
                        Website
                      </a>
                    </span>
                  </span>
                </div>
                <div
                  className="py-2 mt-3 w-100 d-flex"
                  style={{ lineHeight: "2" }}
                >
                  <div className="small text-center rounded-3 bg-light">
                    <button className="exhibitorEnquiry btn btn-sm btn-orange typeSort fs-12 border-0 border-end">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-star-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>{" "}
                      Follow
                    </button>
                    <span className="show_pointer interestedContent px-2 fs-12 cursor position-relative">
                      <span className="intrestNumbers fs-12">1</span>
                    </span>
                  </div>
                </div>
              </div>
              // <h1>{exhibit.Exhibitor.Banner}</h1>
            );
          })}
        </div>
        <div className="text-center box" style={{ marginTop: "-10px" }}>
          <a href="" className="btn btn-blue text-decoration-none fs-14">
            View All Exhibitors
          </a>
        </div>
      </section>
      {/* Venue Map & Directions */}
      <div
        className="row fs-14 box p-0"
        style={{
          background: "transparent",
          border: "none",
          marginTop: "1.3em",
        }}
      >
        <div className="col-md-6">
          <section className="box map_dir" id="map_dirr">
            <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
              Venue Map & Directions
            </h2>
            <span>
              <span id="maps">
                {event.EventData.data.Venue.Contacts.maps && document.readyState === "complete" ?
                  setTimeout(() => {
                    document.querySelector("#maps").innerHTML = event.EventData.data.Venue.Contacts.maps;
                    document.querySelector("#maps iframe").removeAttribute("width");
                    document.querySelector("#maps iframe").style.cssText = 'width:100%'
                  }, 1500)
                  :
                  <img
                    width="324"
                    height="324"
                    src="https://img.10times.com/map/venue/104.png?v=1?imgeng=/w_324/h_324/m_stretch/cmpr_60"
                    className="mb-2 cursor w-100 lazyloaded"
                    alt="map of Koelnmesse GmbH"
                  />}
              </span>

              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23251.41825192515!2d31.243822276450423!3d29.969542280730884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145847eaf8d1e7c3%3A0x860316b417c6d400!2z2YXYs9iq2LTZgdmJINin2YTZhdi52KfYr9mKINmE2YTZgtmI2KfYqiDYp9mE2YXYs9mE2K3Zhw!5e0!3m2!1sar!2seg!4v1704020525002!5m2!1sar!2seg" width={400} height={350} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}

              {/*
              <img
                width="324"
                height="324"
                src="https://img.10times.com/map/venue/104.png?v=1?imgeng=/w_324/h_324/m_stretch/cmpr_60"
                className="mb-2 cursor w-100 lazyloaded"
                alt="map of Koelnmesse GmbH"
              /> */}

            </span>
            <div className="mb-1 fs-16">
              {event.EventData.data.Venue.Name !== "N/A" ? (
                <a href="" className="text-decoration-none text-blue">
                  {event.EventData.data.Venue.Name}5
                </a>
              ) : (
                ""
              )}
            </div>
            <p>
              {event.EventData.data.Venue.Contacts.address !== "Null" ? (
                <span>{event.EventData.data.Venue.Contacts.address}</span>
              ) : (
                ""
              )}
              <br />
              <span>{event.EventData.data.Venue.City}</span>,
              <span> {event.EventData.data.Venue.Country}</span>
            </p>
            <span className="btn btn-blue text-decoration-none fs-14 cursor" onClick={(e) => {
              // document.querySelector("#maps iframe").removeAttribute("width");
              // document.querySelector("#maps iframe").style.cssText = 'width:100%'
            }}>
              Get Directions
            </span>
            <span className="text-decoration-none btn btn-official float-end fs-14 cursor">
              Floor Plan
            </span>
          </section>
        </div>
        <div className="col-md-6">
          <section className="box hotl_blk fs-14">
            <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
              More Events in {event.EventData.data.City}
            </h2>
            <table className="tb-list w-100">
              <tbody>
                {event.EventData.sameCityEvents.map((relateEvent, index) => {
                  return (
                    <tr key={index} className="box-link">
                      <td
                        className="text-end text-muted"
                        style={{ width: "70px", verticalAlign: "top" }}
                      >
                        {moment(relateEvent.StartDate).format("MMM DD")}
                        <small className="d-block">
                          {relateEvent.StartDate.slice(0, 4)}
                        </small>
                      </td>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="float-end bi bi-bookmark-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                        </svg>
                        <a className="text-decoration-none text-blue cursor" onClick={() => eventDetailsByCity(relateEvent)}>
                          {relateEvent.Name}
                        </a>
                        <br />
                        <span className="d-block text-muted">
                          {relateEvent.City}, {relateEvent.Country}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br />
            <p>
              <span className="btn btn-blue text-decoration-none fs-14 cursor">
                All Events in {event.EventData.data.City}
              </span>
            </p>
          </section>
        </div>
      </div>
      {/* Sponsors section */}
      <section className="box fs-14">
        <h2 className="visitors fw-bold " style={{ fontSize: "1.2em" }}>
          Sponsors
        </h2>
        <div className="visitor visitor clearfix row flex-wrap">
          <div className="col-md-3 col-sm-6">
            <div className="box text-center box-210">
              <div
                className="scroll no-scroller navbar-nav-scroll"
                style={{ height: "155px" }}
              >
                <img
                  className="rounded-2 img-48 lazyloaded"
                  src="https://img.10times.com/company/759808/63ce2995cf3f4.png?imgeng=/w_48/h_48/m_stretch/cmpr_60"
                  alt="profile"
                />
                <div className="759808 fs-14 mt-2">Bank Albilad</div>
                <small>Official Sponsor</small>
                <small className="d-block text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  Saudi Arabia
                </small>
              </div>
              <button className="btn-follow ">Follow</button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box text-center box-210">
              <div
                className="scroll no-scroller navbar-nav-scroll"
                style={{ height: "155px" }}
              >
                <img
                  className="rounded-2 img-48 lazyloaded"
                  src="https://img.10times.com/company/1652726/63ce2ac0552b2.png?imgeng=/w_48/h_48/m_stretch/cmpr_60"
                  alt="profile"
                />
                <div className="759808 fs-14 mt-2">Pepsi Cola</div>
                <small>Official Sponsor</small>
                <small className="d-block text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  Saudi Arabia
                </small>
              </div>
              <button className="btn-follow ">Follow</button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box text-center box-210">
              <div
                className="scroll no-scroller navbar-nav-scroll"
                style={{ height: "155px" }}
              >
                <img
                  className="rounded-2 img-48 lazyloaded"
                  src="https://img.10times.com/company/1656696/6411f8e171dbc.png?imgeng=/w_48/h_48/m_stretch/cmpr_60"
                  alt="profile"
                />
                <div className="759808 fs-14 mt-2">Riva Spain</div>
                <small>Title Sponsor</small>
                <small className="d-block text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  Saudi Arabia
                </small>
              </div>
              <button className="btn-follow ">Follow</button>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="box text-center box-210">
              <div
                className="scroll no-scroller navbar-nav-scroll"
                style={{ height: "155px" }}
              >
                <img
                  className="rounded-2 img-48 lazyloaded"
                  src="https://img.10times.com/company/759808/63ce2995cf3f4.png?imgeng=/w_48/h_48/m_stretch/cmpr_60"
                  alt="profile"
                />
                <div className="759808 fs-14 mt-2">Bank Albilad</div>
                <small>Official Sponsor</small>
                <small className="d-block text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-geo-alt-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                  </svg>
                  Saudi Arabia
                </small>
              </div>
              <button className="btn-follow ">Follow</button>
            </div>
          </div>
        </div>
      </section>{" "}
    </div>
  );
};

const tagsDataEvent = [
  "Participants",
  "Presentation",
  "Format",
  "Networking",
  "Speakers",
  "Variety",
  "Agenda",
];
const tagsDataVenue = [
  "Amenities",
  "Cleanliness",
  "Food",
  "Location",
  "Parking",
  "Wifi",
];
const tagsDataOrganizer = [
  "Communication",
  "Registration",
  "Staff",
  "Execution",
  "Arrangements",
  "Accessibility",
];

export default AboutEvent;
