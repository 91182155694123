import React, { useContext, useEffect, useState } from "react";
import { getEventDetails } from "../../Store/action";
import { useDispatch } from "react-redux";
import moment from "moment";

import axiosInstance from "../../AxiosConfig/axiosinstance";
import { User } from "../../Contexts/UserContexts";

const ReviewEvent = () => {
  const result = localStorage.getItem("event");
  const event = JSON.parse(result);
  // const token = window.localStorage.getItem("accessToken");
  const context = useContext(User);
  const token = context.auth?.token
  // console.log(token);

  const [reviews, setReviews] = useState([]);
  const dispatch = useDispatch();

  const [variables, setVariables] = useState({
    rating: "",
    review: "",
    imgUpload: "",
  });

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files.length > 0) {
      setSelectedFile(URL.createObjectURL(files[0]));
    }

    setVariables((prevVariables) => ({
      ...prevVariables,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var formData = new FormData();
    const files = document.querySelector("#uploadimage");
    formData.append("subject", event.EventData.data._id);
    formData.append("content", variables.review);
    formData.append("rating", variables.rating);
    formData.append("about", JSON.stringify(selectedOptions));
    for (let i = 0; i < files.files.length; i++) {
      formData.append("images", files.files[i]);
    }
    console.log(token);
    axiosInstance
      .post("addEventReview", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        axiosInstance
          .get(
            `/aboutEvent/${event.EventData.data._id}?country=${event.EventData.data.Country}`
          )
          .then((response) => {
            console.log(response.data);
            dispatch(getEventDetails(response.data));
            const result = JSON.stringify(response.data);
            localStorage.setItem("event", result);
            window.location.reload();
          })
          .catch((error) => {
            console.log(error);
          });
        setShowRatingSection(!showRatingSection);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showRatingSection, setShowRatingSection] = useState(false);
  const handelRating = () => {
    setShowRatingSection(!showRatingSection);
  };
  const [activeTags, setActiveTags] = useState([]);

  const handleTagClick = (index) => {
    if (activeTags.includes(index)) {
      setActiveTags(activeTags.filter((tagIndex) => tagIndex !== index));
    } else {
      setActiveTags([...activeTags, index]);
    }
  };

  const getAllReviews = async () => {
    try {
      const response = await axiosInstance.get(
        `/getEventReviews/${event.EventData.data._id}?limit=10`
      );
    //   console.log(response.data);
      setReviews(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <>
      {/* User Rating */}
      {reviews.data ? (
        <section className="box" id="reviews-data">
          <div className="row">
            <div className="col-md-5">
              User Rating
              <div className="fs-20 fw-normal mb-2">
                {reviews.data ? reviews.data.Rating.Average.toFixed(1) : ""}
                <span className="fs-12"> /5</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  fill="#862efb"
                  className="bi bi-star-fill mx-1 mb-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                </svg>
              </div>
              <span className="text-muted d-block fs-12">
                <strong>
                  {`${
                    reviews.data.Rating["1"] +
                    reviews.data.Rating["2"] +
                    reviews.data.Rating["3"] +
                    reviews.data.Rating["4"] +
                    reviews.data.Rating["5"]
                  }`}{" "}
                  Ratings | {reviews.reviews.length} Reviews
                </strong>
              </span>
            </div>
            <div className="col-md-7">
              <div className="col-md-12 d-inline-block">
                <div className="float-start me-1" style={{ marginTop: "-4px" }}>
                  <span
                    className="text-muted fs-12"
                    style={{ paddingRight: 2, fontSize: "smaller" }}
                  >
                    5{" "}
                  </span>
                </div>
                <div
                  className="fs-12 mb-1 overflow-hidden rounded-0 progress"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div
                    className="progress-bar barclass5 rounded-2"
                    style={{
                      width: `${
                        (reviews.data.Rating["5"] /
                          (reviews.data.Rating["1"] +
                            reviews.data.Rating["2"] +
                            reviews.data.Rating["3"] +
                            reviews.data.Rating["4"] +
                            reviews.data.Rating["5"])) *
                        100
                      }%`,
                      lineHeight: "15.5px",
                    }}
                  >
                    {reviews.data.Rating["5"]}
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-inline-block">
                <div className="float-start me-1" style={{ marginTop: "-4px" }}>
                  <span
                    className="text-muted fs-12"
                    style={{ paddingRight: 2, fontSize: "smaller" }}
                  >
                    4{" "}
                  </span>
                </div>
                <div
                  className="fs-12 mb-1 overflow-hidden rounded-0 progress"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div
                    className="progress-bar barclass4 rounded-2"
                    style={{
                      width: `${
                        (reviews.data.Rating["4"] /
                          (reviews.data.Rating["1"] +
                            reviews.data.Rating["2"] +
                            reviews.data.Rating["3"] +
                            reviews.data.Rating["4"] +
                            reviews.data.Rating["5"])) *
                        100
                      }%`,
                      lineHeight: "15.5px",
                    }}
                  >
                    {reviews.data.Rating["4"]}
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-inline-block">
                <div className="float-start me-1" style={{ marginTop: "-4px" }}>
                  <span
                    className="text-muted fs-12"
                    style={{ paddingRight: 2, fontSize: "smaller" }}
                  >
                    3{" "}
                  </span>
                </div>
                <div
                  className="fs-12 mb-1 overflow-hidden rounded-0 progress"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div
                    className="progress-bar barclass3 rounded-2"
                    style={{
                      width: `${
                        (reviews.data.Rating["3"] /
                          (reviews.data.Rating["1"] +
                            reviews.data.Rating["2"] +
                            reviews.data.Rating["3"] +
                            reviews.data.Rating["4"] +
                            reviews.data.Rating["5"])) *
                        100
                      }%`,
                      lineHeight: "15.5px",
                    }}
                  >
                    {reviews.data.Rating["3"]}
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-inline-block">
                <div className="float-start me-1" style={{ marginTop: "-4px" }}>
                  <span
                    className="text-muted fs-12"
                    style={{ paddingRight: 2, fontSize: "smaller" }}
                  >
                    2{" "}
                  </span>
                </div>
                <div
                  className="fs-12 mb-1 overflow-hidden rounded-0 progress"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div
                    className="progress-bar barclass2 rounded-2"
                    style={{
                      width: `${
                        (reviews.data.Rating["2"] /
                          (reviews.data.Rating["1"] +
                            reviews.data.Rating["2"] +
                            reviews.data.Rating["3"] +
                            reviews.data.Rating["4"] +
                            reviews.data.Rating["5"])) *
                        100
                      }%`,
                      lineHeight: "15.5px",
                    }}
                  >
                    {reviews.data.Rating["2"]}
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-inline-block">
                <div className="float-start me-1" style={{ marginTop: "-4px" }}>
                  <span
                    className="text-muted fs-12"
                    style={{ paddingRight: 2, fontSize: "smaller" }}
                  >
                    1{" "}
                  </span>
                </div>
                <div
                  className="fs-12 mb-1 overflow-hidden rounded-0 progress"
                  style={{ backgroundColor: "#fff" }}
                >
                  <div
                    className="progress-bar barclass1 rounded-2"
                    style={{
                      width: `${
                        (reviews.data.Rating["1"] /
                          (reviews.data.Rating["1"] +
                            reviews.data.Rating["2"] +
                            reviews.data.Rating["3"] +
                            reviews.data.Rating["4"] +
                            reviews.data.Rating["5"])) *
                        100
                      }%`,
                      lineHeight: "15.5px",
                    }}
                  >
                    {reviews.data.Rating["1"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          {/* Feature Rating */}
          <div className="row">
            <div className="col-md-12">
              <span className="text-muted fs-12">
                <span className="fw-bold">Feature Rating</span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass4"
                style={{ color: "#fff" }}
              >
                4.9
              </span>
              <span className="text-muted fs-12">Quality of Participants</span>
            </div>
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass5"
                style={{ color: "#fff" }}
              >
                5.0
              </span>
              <span className="text-muted fs-12">Display & Presentation</span>
            </div>
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass2"
                style={{ color: "#fff" }}
              >
                2.3
              </span>
              <span className="text-muted fs-12">Format of Event</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass5"
                style={{ color: "#fff" }}
              >
                5.0
              </span>
              <span className="text-muted fs-12">Exhibitors & Vendors</span>
            </div>
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass5"
                style={{ color: "#fff" }}
              >
                5.0
              </span>
              <span className="text-muted fs-12">Variety of Products</span>
            </div>
          </div>
          {/* Organizer Rating */}
          <div className="row  mt-2">
            <div className="col-md-12">
              <span className="text-muted fs-12">
                <span className="fw-bold">Organizer Rating</span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass4"
                style={{ color: "#fff" }}
              >
                4.9
              </span>
              <span className="text-muted fs-12">Communication</span>
            </div>
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass4"
                style={{ color: "#fff" }}
              >
                4.3
              </span>
              <span className="text-muted fs-12">Registration Process</span>
            </div>
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass4"
                style={{ color: "#fff" }}
              >
                4.0
              </span>
              <span className="text-muted fs-12">Staff Helpfulness</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass5"
                style={{ color: "#fff" }}
              >
                5.0
              </span>
              <span className="text-muted fs-12">Execution Quality</span>
            </div>
            <div className="col-md-4 mb-2">
              <span
                className="me-1 label rounded-2 fw-bold fs-12 barclass5"
                style={{ color: "#fff" }}
              >
                5.0
              </span>
              <span className="text-muted fs-12">Shuttle Service</span>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}

      {/* add Review */}
      {showRatingSection ? (
        <form onSubmit={handleSubmit}>
          <section className="box" id="verified-review-open">
            <div className="row">
              <div className="col-md-12" style={{ marginBottom: "20px" }}>
                <strong className="text-muted">Write a Review</strong>
                <a
                  href=""
                  className="float-end text-danger text-decoration-none fs-14"
                >
                  Cancel
                </a>
              </div>
              <div className="col-md-2">
                <img
                  style={{ borderRadius: "50%", marginTop: "-15px" }}
                  width={50}
                  src="https://c1.10times.com/user_pic%2FnUjEqF4zq9RsczNFelovYEKBO4XUVJtTbTcYBnUos5J0DOFN2sO%2BAh4fvbQ44KZN09lnHk%2BhxaGZohS%2BQAw%3D%3D.jpg"
                  alt="ahmed Elsayed"
                />
              </div>
              <div className="col-md-10">
                <span className="vf-rating">
                  <input
                    type="radio"
                    id="star5"
                    name="rating"
                    value="5"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star5" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star4"
                    name="rating"
                    value="4"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star4" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star3"
                    name="rating"
                    value="3"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star3" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star2"
                    name="rating"
                    value="2"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star2" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                  <input
                    type="radio"
                    id="star1"
                    name="rating"
                    value="1"
                    onChange={handleInputChange}
                  />
                  <label htmlFor="star1" style={{ margin: "5px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-star"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                    </svg>
                  </label>
                </span>
                <span className="float-end" style={{ marginTop: "10px" }}>
                  <span className="text-muted fs-12">Edition Visited</span>
                </span>
                <div
                  className="mt-2"
                  style={{ position: "relative", clear: "both" }}
                >
                  <textarea
                    id="word-count"
                    className="form-control fs-14"
                    style={{ resize: "vertical" }}
                    rows="6"
                    placeholder="Share details of your experience at this event"
                    name="review"
                    value={variables.review}
                    onChange={handleInputChange}
                  />
                  <div
                    className="word-counter fs-12 text-danger"
                    style={{
                      position: "absolute",
                      right: "15px",
                      bottom: "10px",
                    }}
                  >
                    0
                  </div>
                </div>
                <div style={{ clear: "both" }}>
                  <div className="dropdown float-end">
                    <a href="" className="text-decoration-none text-blue">
                      <span className="fs-12">
                        Tips for writing great review
                      </span>
                    </a>
                  </div>
                  <span className="fs-12 d-none error-message d-block text-danger"></span>
                </div>
                <div className="mt-2" style={{ clear: "both" }}>
                  <label htmlFor="uploadimage" className="image-upload">
                    <input
                      id="uploadimage"
                      type="file"
                      accept="image/*"
                      name="imgUpload"
                      onChange={handleInputChange}
                    />
                    <img
                      style={{
                        height: "inherit",
                        width: "inherit",
                        verticalAlign: "-webkit-baseline-middle",
                      }}
                      src={
                        selectedFile ||
                        "https://c1.10times.com/images/add-photo.png"
                      }
                      alt="Add photos"
                    />
                  </label>
                  <span
                    className="text-muted d-block fs-12"
                    style={{ marginTop: "-5px" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="green"
                      className="bi bi-shield-check mx-1 mb-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                      <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                    Get verified listing of your feedback by uploading pictures
                    of your event visit
                  </span>
                </div>
                <div className="mt-2">
                  {/* <a
                    href=""
                    className="past_edition btn btn-expo float-end fs-14"
                  >
                    Submit Your Review
                  </a> */}
                </div>
              </div>
            </div>
          </section>
          <section className="box fs-14" id="verified-review-question">
            <div className="row">
              <div className="col-md-12">
                <h2 className="" style={{ fontSize: "1.2em" }}>
                  What do you like the most about
                </h2>
                <div className="row">
                  <div className="col-md-4 fq-event">
                    <strong className="text-muted">Event</strong>
                    {tagsDataEvent.map((tag, index) => (
                      <div className="mt-2" key={index}>
                        <span
                          className={`suggestion-tag-new vf-question ${
                            activeTags.includes(tag) ? "vf-answer" : ""
                          }`}
                          onClick={() => {
                            handleTagClick(tag);
                            setSelectedOptions([...selectedOptions, tag]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-hand-thumbs-up-fill mb-1 me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-4 fq-venue">
                    <strong className="text-muted">Venue</strong>
                    {tagsDataVenue.map((tag, index) => (
                      <div className="mt-2" key={index}>
                        <span
                          className={`suggestion-tag-new vf-question ${
                            activeTags.includes(tag) ? "vf-answer" : ""
                          }`}
                          onClick={() => {
                            handleTagClick(tag);
                            setSelectedOptions([...selectedOptions, tag]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-hand-thumbs-up-fill mb-1 me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-4 fq-orgnizer">
                    <strong className="text-muted">Organizer</strong>
                    {tagsDataOrganizer.map((tag, index) => (
                      <div className="mt-2" key={index}>
                        <span
                          className={`suggestion-tag-new vf-question ${
                            activeTags.includes(tag) ? "vf-answer" : ""
                          }`}
                          onClick={() => {
                            handleTagClick(tag);
                            setSelectedOptions([...selectedOptions, tag]);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-hand-thumbs-up-fill mb-1 me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          {tag}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-12 mt-2 fq-comeback">
                    <strong className="text-muted mt-2 d-block">
                      Would you like to comeback?
                    </strong>
                    <div className="mt-2">
                      <span
                        className={`suggestion-tag-new vf-question mx-2 ${
                          activeTags.includes("yes") ? "vf-answer" : ""
                        }`}
                        onClick={() => {
                          handleTagClick("yes");
                        }}
                        style={{ width: "auto" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={`${
                            activeTags.includes("yes") ? "white" : "green"
                          }`}
                          className="bi bi-emoji-smile me-1 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                        </svg>{" "}
                        Yes
                      </span>
                      <span
                        style={{ width: "auto" }}
                        className={`suggestion-tag-new vf-question mx-2 ${
                          activeTags.includes("no") ? "vf-no" : ""
                        }`}
                        onClick={() => {
                          handleTagClick("no");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={`${
                            activeTags.includes("no") ? "white" : "red"
                          }`}
                          className="bi bi-emoji-frown me-1 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
                        </svg>{" "}
                        No
                      </span>
                      <span
                        style={{ width: "auto" }}
                        className={`suggestion-tag-new vf-question mx-2 ${
                          activeTags.includes("may") ? "vf-may" : ""
                        }`}
                        onClick={() => {
                          handleTagClick("may");
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill={`${
                            activeTags.includes("may") ? "white" : "yellow"
                          }`}
                          className="bi bi-emoji-neutral me-1 mb-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z" />
                        </svg>{" "}
                        Maybe
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    {/* <a
                      href=""
                      className="btn btn-expo d-block float-end custom-submit fs-14"
                    > */}
                    <button
                      className="btn btn-expo d-block float-end custom-submit fs-14"
                      type="submit"
                    >
                      Submit
                    </button>

                    {/* </a> */}
                    <a
                      className="btn btn-official  float-end custom-skip fs-14 me-2"
                      onClick={handelRating}
                    >
                      Skip
                    </a>
                    {/* <a href="" className="btn btn-expo fs-14">
                      Back
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </form>
      ) : (
        <section id="verified-review" className="box fs-14">
          <div className="row">
            <div className="col-md-12 position-relative">
              <h2
                className="visitors fw-bold d-flex"
                style={{ alignItems: "baseline", fontSize: "1.2em" }}
              >
                Write a Review
              </h2>
              <input
                type="text"
                name="verifiedReview"
                className="engageAction form-control bg-white text-muted fs-14 cursor"
                data-action="verifiedReview"
                data-settle="review"
                style={{ paddingLeft: "30px" }}
                value="Help other visitors by sharing your review."
                aria-label="verified review"
                readOnly
                onClick={() => {
                  handelRating();
                }}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-pencil-fill position-absolute text-muted fs-12"
                style={{ bottom: "55px", left: "25px" }}
                viewBox="0 0 16 16"
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
              </svg>
              <span className="engageAction btn btn-expo mt-2 text-decoration-none fs-14 fw-bold cursor">
                Add Your Review
              </span>
            </div>
          </div>
        </section>
      )}
      <section className="box fs-14" id="review_all">
        {/* review with image */}
        {reviews.reviews?.length > 0 ? (
          <>
            <h2
              className="visitors fw-bold d-flex"
              style={{ alignItems: "baseline", fontSize: "1.2em" }}
            >
              Reviews with images
            </h2>
            <div className="position-relative fs-14">
              <div className="text-nowrap overflow-hidden cursor">
                <div
                  className="photos_tumbnail_box no-scroller navbar-nav-scroll"
                  style={{ overflowX: "auto", whiteSpace: "nowrap" }}
                >
                  {reviews.reviews.map((review, index) => {
                    return (
                      <React.Fragment key={index}>
                        {review.Images.length > 0 ? (
                          <div
                            className="gallary-card-review position-relative cursor me-2 m-0"
                            style={{
                              width: "225px",
                              height: "150px",
                              flex: "0 0 225px",
                            }}
                          >
                            <img
                              className="img-review d-inline-block position-absolute photos-tumbnail lazyloaded"
                              src={review.Images[0]}
                              width="225"
                              height="150"
                            />
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </div>
                <button className="btn btn-sm border-0 text-blue float-end">
                  View More
                </button>
              </div>
            </div>
            <hr />
          </>
        ) : (
          ""
        )}

        {/* Review comment  */}
        {reviews.reviews?.length > 0 ? (
          <>
            <h2
              className="visitors fw-bold d-flex"
              style={{ alignItems: "baseline", fontSize: "1.2em" }}
            >
              User Reviews
            </h2>
            <div className="row">
              {reviews.reviews.map((review, index) => {
                return (
                  <div key={index}>
                    <div className="col-md-12  feedback-block">
                      <a href="">
                        <img
                          className="float-start rounded-2 img-48 lazyloaded"
                          src={review.User.Image}
                          alt="abc"
                        />
                      </a>
                      <div className="float-end text-end">
                        <span
                          className={`fw-bold rounded-2 barclass${review.Rating} p-1`}
                          style={{
                            fontSize: "12px",
                            color: "#fff",
                            padding: "5px 6px !important ",
                          }}
                        >
                          {review.Rating}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-star-fill mx-1 mb-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                          </svg>
                        </span>
                        <br />
                        <small style={{ clear: "both" }} className="text-muted">
                          {moment(review.Date).format("D MMM YYYY")}
                        </small>
                      </div>
                      <div
                        className="d-inline-block"
                        style={{ lineHeight: "1em", marginLeft: "7px" }}
                      >
                        <strong>{review.User.FirstName}</strong>
                        <small className="text-expo ms-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#862efb"
                            className="bi bi-shield-check"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                            <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                          </svg>
                        </small>
                        <small className="ms-1 text-muted fs-12">Visitor</small>
                        <small className="text-muted d-block">
                          {" "}
                          {review.User.Role} at {review.User.Organization}
                        </small>
                        <small className="text-muted d-block">
                          {review.User.City}, {review.User.Country}
                        </small>
                      </div>
                      <div
                        className=""
                        style={{
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                          width: "inherit",
                          overflowY: "hidden",
                        }}
                      >
                        <ul
                          style={{
                            listStyle: "none",
                            paddingLeft: "0px",
                            marginTop: "10px ",
                            marginBottom: "0px",
                          }}
                        >
                          {review.About.map((like, index) => {
                            return (
                              <li
                                key={index}
                                style={{
                                  display: "inline-block",
                                  marginLeft: "0px",
                                  marginRight: "10px",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <span className="suggestion-tag sm-x ">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="red"
                                    className={`bi bi-hand-thumbs-up-fill test-${review.Rating}`}
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                                  </svg>{" "}
                                  {like}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="mt-2">{review.Content}</div>
                      <div
                        className="mt-4 photos_tumbnail_box no-scroller navbar-nav-scroll"
                        style={{ overflowX: " auto", whiteSpace: "nowrap" }}
                      >
                        {review.Images.map((img, index) => {
                          return (
                            <div
                              key={index}
                              className="gallary-card-review position-relative cursor me-2 m-0"
                              style={{
                                width: "70px",
                                height: "70px",
                                flex: "0 0 70px",
                              }}
                            >
                              <img
                                className="d-inline-block position-absolute photos-tumbnail lazyloaded"
                                src={img}
                                width="70"
                                height="70"
                                alt="gallery"
                                style={{ margin: "0px 5px" }}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className="mt-3">
                        <span className="helpul-btn text-muted float-end engageAction text-decoration-none cursor">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            fill="currentColor"
                            className="bi bi-flag-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                          </svg>{" "}
                          Reported
                        </span>
                        <span className="cursor helpul-btn text-muted engageAction text-decoration-none">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-hand-thumbs-up-fill me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                          </svg>
                          Helpful
                          <strong className="text-muted response-stats">
                            {" "}
                            {review.Helpful}
                          </strong>
                        </span>
                        <span className="cursor helpul-btn text-muted engageAction text-decoration-none ms-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-tv me-1"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z" />
                          </svg>
                          Reply
                          <strong className="text-muted response-stats">
                            {" "}
                            {review.Replay}
                          </strong>
                        </span>
                      </div>
                    </div>
                    <hr style={{ marginTop: "10px" }} />
                  </div>
                );
              })}
            </div>
            <div className="text-center margin">
              <a href="" className="btn btn-blue text-decoration-none fs-14">
                View All Reviews
              </a>
            </div>
          </>
        ) : (
          ""
        )}
      </section>
    </>
  );
};
const tagsDataEvent = [
  "Participants",
  "Presentation",
  "Format",
  "Networking",
  "Speakers",
  "Variety",
  "Agenda",
];
const tagsDataVenue = [
  "Amenities",
  "Cleanliness",
  "Food",
  "Location",
  "Parking",
  "Wifi",
];
const tagsDataOrganizer = [
  "Communication",
  "Registration",
  "Staff",
  "Execution",
  "Arrangements",
  "Accessibility",
];

export default ReviewEvent;
