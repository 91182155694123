import React, { useEffect, useState } from 'react';
import Carousels from '../../Components/Carousel/carousel';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { Col, Row } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import axiosInstance from '../../AxiosConfig/axiosinstance';
import Sliders from '../../Components/Slider/sliders';
import SpinnerComponent from '../../Components/Spinner/spinner';
import RandomReviews from '../../Components/RandomReviews/randomReviews';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getEventDetails } from "../../Store/action";
import './home.css';


const Home = () => {

    const [categories, setCategories] = useState({});
    const [cities, setCitiess] = useState({});
    const [countries, setCountries] = useState({});
    const [venues, setVenues] = useState("");
    const [featuredEvents, setFeaturedEvents] = useState([]);
    const [randomReviews, setRandomReviews] = useState([]);
    const [featuredCompanies, setFeaturedCompanies] = useState([]);


    const dispatch = useDispatch();
    const navigate = useNavigate();

    // get Featured Venues
    const getVenueDetails = async (ele) => {

        try {
            const respons = await axiosInstance.get(`/getVenue/${ele._id}`);
            console.log(respons.data.EventData);
            navigate("/venueinfo")

        } catch (error) {

            console.log(error);
        }
    }

    // get Featured Events
    const getFeaturedEvent = (ele) => {
        // console.log(ele);
        axiosInstance
            .get(`/aboutEvent/${ele._id}?country=${ele.Country}&city=${ele.City}&category=${encodeURIComponent(ele.Category[0])}`)
            .then((response) => {
                // console.log(response.data);
                // dispatch(getEventDetails(response.data));
                const result = JSON.stringify(response.data);
                localStorage.setItem("event", result);
                navigate("/eventsinfo");
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    // send Event Details
    const eventDetails = (event) => {

        axiosInstance
            
            .get(`/aboutEvent/${event._id}?country=${event.Country}&city=${event.City}&category=${encodeURIComponent(event.Category[0])}`)
            .then((response) => {
                // console.log(response.data);
                dispatch(getEventDetails(response.data));
                const result = JSON.stringify(response.data);
                localStorage.setItem("event", result);
                navigate("/eventsinfo");
                // console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // variable for carousel in random review
    const responsiveReview = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 4,
            slidesToSlide: 1, // عدد الشرائح التي تتحرك عند الانتقال
        },
        tablet: {
            breakpoint: { max: 1200, min: 767 },
            items: 2,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 766, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    // variable for carousel in featured Company
    const responsiveCompany = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 7,
            slidesToSlide: 1, // عدد الشرائح التي تتحرك عند الانتقال
        },
        tablet: {
            breakpoint: { max: 1024, min: 767 },
            items: 4,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 766, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    // function to get all data about Home
    const getAllData = () => {
        axiosInstance.get('homeInfo').then((respons) => {
            setCategories(respons.data.Category);
            setCitiess(respons.data.City);
            setCountries(respons.data.Country);
            setVenues(respons.data.Venues);
            setFeaturedEvents(respons.data.FeaturedEvents);
            setRandomReviews(respons.data.RandomReviews);
            setFeaturedCompanies(respons.data.FeaturedCompanys)
            // console.log(respons.data.FeaturedCompanys);

        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        getAllData();

    }, []);

    return (
        <>
            <Carousels />
            <Button className='coutom-btn' variant="secondary">All Events</Button>{' '}
            <Button className='coutom-btn' variant="secondary">Happening Today </Button>{' '}
            <Button className='coutom-btn' variant="secondary">Popular</Button>{' '}
            <Button className='coutom-btn' variant="secondary">Egypt</Button>{' '}

            <Container>

                {/* Start Browse By Category Section*/}
                <h3 className='headLine'>Browse By Category</h3>
                <div className="row">
                    {(categories == "") ? (
                        <SpinnerComponent />
                    ) : (<>
                        {Object.keys(categories).map((name, index) => {
                            const category = categories[name];
                            return (
                                <div className='col-md-3 col-lg-2 mb-3  shadow mx-1 rounded-3' key={index}>
                                    <a className='scroller-item text-dark text-decoration-none px-3 pt-2 rounded-3 link ' href='/events' style={{ width: "100%", height: "100%", minHeight: "110px" }}>
                                        <div>
                                            <span className='text-black px-2 py-1 rounded-circle category-icon'>
                                                <img className="w-10" src={category.Icon} rounded alt='asd' />
                                            </span>
                                        </div>
                                        <div className='w-100 overflow-hidden mt-3 fw-500 small'>
                                            {name}
                                        </div>
                                        <div className="text-muted small">{category.Number} Events</div>
                                    </a>
                                </div>
                            )
                        })}
                        <div className='col-md-2 mb-3 shadow mx-1 rounded-3  d-flex' style={{ backgroundColor: " rgb(188 154 234)" }}>
                            <a className='scroller-item text-dark text-decoration-none px-3 pt-2 link ' href='/events' style={{ width: "100%", height: "100%", minHeight: "110px" }}>
                                <div className='align-self-center text-center justify-items-center'>
                                    View All
                                </div>
                            </a>
                        </div>
                    </>
                    )}
                </div>
                {/* End Browse By Category  Section */}

                {/* Start Featured Events Section*/}
                <h3 className='headLine mt-5'>
                    Featured Events
                    <br />
                    <sub className="text-muted fw-normal bottom-0">Handpicked popular events</sub>
                </h3>
                <Row>
                    {(featuredEvents == "") ? <SpinnerComponent /> : featuredEvents.map((featuredEvent) => {
                        return (
                            <Col sm={12} md={4} lg={4} key={featuredEvent._id}>
                                <Link className=' scroller-item text-dark text-decoration-none p-2 rounded-3 link' onClick={() => getFeaturedEvent(featuredEvent)} >
                                    <Card className=' hShadow'>
                                        <Card.Body className='p-2'>
                                            <Card.Text className="eventDate ">
                                                {moment(featuredEvent.StartDate).format("ddd, D - ")} {moment(featuredEvent.EndDate).format("ddd, D MMM YYYY")}
                                            </Card.Text>
                                            <Row>
                                                <Col className='col-9' >
                                                    <span className='eventName'>{featuredEvent.Name}  </span>
                                                </Col>
                                                <Col className='d-flex justify-content-end col-3'>
                                                    {featuredEvent.Banner ?
                                                        <img className="border rounded-3 ms-2 mt-1 lazyloaded" src={featuredEvent.Banner} width="64" height="64" alt={featuredEvent.Name} loading="lazy" /> : <img className="border rounded-3 ms-2 mt-1 lazyloaded" src={featuredEvent.Banner} width="64" height="64" alt={featuredEvent.Name} style={{ visibility: "hidden" }} />}

                                                    {/* <Image className=" w-100 border"  /> */}
                                                </Col>
                                            </Row>
                                            <Card.Subtitle className="w-100  subtitle">{featuredEvent.City},{featuredEvent.Country}</Card.Subtitle>
                                            <Card.Subtitle className="mt-3">
                                                {featuredEvent.Category.map((cat, index) => {
                                                    return (
                                                        <span key={index} className=' small me-2 p-1  bg-light rounded-1 text-muted'>
                                                            {cat}
                                                        </span>
                                                    )
                                                })}
                                            </Card.Subtitle>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        )
                    })}


                </Row>
                <div className='d-flex gap-4 justify-content-center w-100 mt-3'>
                    <button className='btn expo-primary '>Add Event</button>
                </div>
                {/* End Featured Events Section*/}



                {/* Start Browse By City Section*/}
                <h3 className='headLine mt-2'>Browse Events By City</h3>
                <Row>
                    {(cities == "") ? <SpinnerComponent /> : Object.keys(cities).map((cityName, index) => (
                        <Col key={index} md={2} className='mt-3'>
                            <div onClick={() => {
                                // window.location.href = "#"                                
                                localStorage.setItem("filter", cityName);
                                navigate('/events')
                            }}
                                className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                                <div>
                                    <img src={cityName} style={{ width: "35", height: "35", visibility: "hidden" }} alt={cityName} />
                                </div>
                                <div className='w-100 overflow-hidden small fw-500 mt-3'>{cityName}</div>
                                <div className='text-muted small'>{cities[cityName].Number} Events</div>
                            </div>
                        </Col>
                    ))
                    }
                </Row>
                {/* End Browse By City Section*/}



                {/* Start Browse By Country Section*/}
                <h3 className='headLine mt-5'>Browse Events By Country</h3>
                <Row>
                    {(countries == "") ? <SpinnerComponent /> : Object.keys(countries).map((countryName, index) => (
                        <Col key={index} md={2} className='mt-3'>
                            <div onClick={() => {
                                // window.location.href = "#"
                                // console.log(countryName);
                                localStorage.setItem("filterCountry", countryName);
                                navigate('/events');
                            }}
                                className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                                <div>
                                    <img className='rounded-2 ' src={countries[countryName].Icon} style={{ width: "35px", height: "35px" }} alt={countryName} />
                                </div>
                                <div className='w-100 overflow-hidden small fw-500 mt-3'>{countryName}</div>
                                <div className='text-muted small'>{countries[countryName].Number} Events</div>
                            </div>
                        </Col>
                    ))}


                    {/* <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="London" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100 ' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100 ' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100 ' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100 ' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100 ' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col >
                    <Col md={2} className='mt-3'>
                        <div onClick={() => {
                            window.location.href = "#"
                        }}
                            className='px-3 pt-2 m-1 border rounded-3 link hShadow w-100 h-100 ' role='button'>
                            <div>
                                <img src="https://img.10times.com/img/flag/au.jpg" style={{ width: "35", height: "35" }} alt="Australia" />
                            </div>
                            <div className='w-100 overflow-hidden small fw-500 mt-3'>Australia</div>
                            <div className='text-muted small'>2560 Events</div>
                        </div>
                    </Col > */}
                </Row>
                {/* End Browse By Country Section*/}

                {/* start Random Review */}
                <Row className='mt-5'>
                    <Col className='random-review'>
                        <h4 className='col-12 px-3 mt-3'>We are the world's largest eventgoer community</h4>
                        <div className='col-12 px-3 d-flex flex-column flex-md-row justify-content-between align-items-md-center fs-6'>
                            <span> Every minute <b> 570 </b> people are finding new opportunities at events</span>
                            <span className="d-flex flex-column flex-md-row align-items-md-center mt-3 mt-md-0">
                                <span className='me-md-3 tab_custyle'>
                                    8 Million+ <b>Members</b>
                                </span>
                                <button type='button' className='btn x-iai a-m interestdetailNew btn-success' disabled>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" fill="currentColor" className="bi bi-check-lg " viewBox="0 0 16 16">
                                        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                                    </svg>
                                    &nbsp;Subscribe
                                </button>
                            </span>
                        </div>
                        {/* <RandomReviews /> */}
                        <Carousel
                            swipeable={true} // تعيين السحب بالإصبع إلى خطأ
                            draggable={false} // تعيين السحب بالسحب إلى خطأ
                            showDots={false} // عرض النقاط التمريرية
                            responsive={responsiveReview} // استخدام التحولات المحددة أعلاه
                            ssr={true} // الاستجابة من الجانب الخادم
                            infinite={true} // عرض غير محدود
                            autoPlay={true} // تشغيل تلقائي
                            autoPlaySpeed={1000} // سرعة التشغيل التلقائي (بالمللي ثانية)
                            keyBoardControl={true} // تحكم لوحة المفاتيح
                            customTransition="all .5"
                            transitionDuration={1000} // مدة الانتقال (بالمللي ثانية)
                            containerClass="carousel-container" // فئة الحاوية
                            removeArrowOnDeviceType={['tablet', 'mobile']} // إزالة السهم على أنواع الأجهزة المحددة
                            //deviceType={props.deviceType}
                            dotListClass="custom-dot-list-style" // فئة قائمة النقاط
                            itemClass="carousel-item-padding-40-px" // فئة العنصر
                        >
                            {randomReviews && randomReviews.map((review, index) => {
                                return (
                                    <div key={index} className='scroller-item bg-white  rounded-3 hShadow m-3 ' style={{ height: "382px", width: "325px" }}>
                                        <div className='position-relative d-flex flex-column justify-content-center align-items-center' style={{ height: "150px" }}>
                                            <img className='rounded-3 lazyloaded blur-img-2' src={review.Images[0]} width={325} height={150} alt="Nigerian Bar Association Annual General Conference_bg" />
                                        </div>
                                        <div className='d-flex justify-content-between px-2 pt-3 mb-2'>
                                            <a className='text-decoration-none cursor'>
                                                <span className='line-2' onClick={() => {
                                                    eventDetails(review.Subject);
                                                }}>{review.Subject.Name}</span>
                                            </a>
                                            <button type='button' className='btn text-muted bg-light rounded-circle action-btn xn a-m'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bookmark" viewBox="0 0 16 16">
                                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className='text-wrap px-2 pb-3'>
                                            <div className='small text-muted'>{review.Subject.City}, {review.Subject.Country}</div>
                                            <div className='small fst-italic overflow-auto my-2 scroll'>

                                                "{review.Content}"

                                            </div>
                                            <div className='my-2'>
                                                <span className='d-inline-block'>
                                                    {Array.from({ length: review.Rating }, (_, index) => (
                                                        <svg key={index} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
                                                            <polygon fill="#862efb" points="8 1.5 9.091 5.547 13.636 6.09 10.182 9.091 10.909 13.636 8 11.418 5.091 13.636 5.818 9.091 2.364 6.09 6.909 5.547 8 1.5" />
                                                        </svg>))}

                                                </span>
                                                <span className='d-inline-block small text-end float-end'>
                                                    {moment(review.Date).format("DD MMM YYYY")}</span>
                                            </div>
                                            <div className='d-flex flex-row align-items-center'>
                                                <img src="https://img.10times.com/user/images/6/4/0/65294640_1693834987.jpg?imgeng=/w_30/h_30/m_letterbox_000000_100/cmpr_60" alt="Rosecana Ankama" width={30} height={30} />
                                                <div className='d-flex flex-column mx-1'>
                                                    {` ${review.User.FirstName}`}
                                                    <div className='small text-muted'>{review.User.Country}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                        </Carousel>

                    </Col>
                </Row>
                {/* end Random Review */}

                {/* Start Featured Organizers Section*/}
                <h3 className='headLine mt-5'>
                    Featured Organizers
                    <br />
                    <sub className="text-muted fw-normal bottom-0">Worldwide leading event organisers</sub>
                </h3>
                <Row className='my-4'>
                    <Col>
                        {/* <Sliders /> */}
                        <Carousel
                            swipeable={true} // تعيين السحب بالإصبع إلى خطأ
                            draggable={false} // تعيين السحب بالسحب إلى خطأ
                            showDots={false} // عرض النقاط التمريرية
                            responsive={responsiveCompany} // استخدام التحولات المحددة أعلاه
                            ssr={true} // الاستجابة من الجانب الخادم
                            infinite={true} // عرض غير محدود
                            autoPlay={true} // تشغيل تلقائي
                            autoPlaySpeed={1000} // سرعة التشغيل التلقائي (بالمللي ثانية)
                            keyBoardControl={true} // تحكم لوحة المفاتيح
                            customTransition="all .5"
                            transitionDuration={700} // مدة الانتقال (بالمللي ثانية)
                            containerClass="carousel-container" // فئة الحاوية
                            removeArrowOnDeviceType={['tablet', 'mobile']} // إزالة السهم على أنواع الأجهزة المحددة
                            //deviceType={props.deviceType}
                            dotListClass="custom-dot-list-style" // فئة قائمة النقاط
                            itemClass="carousel-item-padding-40-px" // فئة العنصر
                        >
                            {featuredCompanies.map((company, index) => {
                                return (
                                    <div key={index} className='scroller-item text-center p-2 mx-3 border rounded-3 hShadow'>
                                        <div className='d-flex flex-column w-100 h-100 justify-content-center'>
                                            <Link className='text-decoration-none cursor w-100 h-100' to={'/companies'}>
                                                <img src={company.Banner} alt="Featured Companies Banner" width={100} height={100} />
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </Col>
                    <Col sm={12} md={12} lg={12} className='d-flex gap-4 justify-content-start w-100 mt-3'>
                        <Link to={'/companies'}>
                            <Button className='btn-view-all rounded-5' variant="secondary">View All</Button>
                        </Link>
                    </Col>

                </Row>
                {/* End Featured Organizers Section*/}


                {/* Start Venue  Section*/}
                <h3 className='headLine mt-5'>
                    Explore Venues
                </h3>
                <Row>
                    {(venues == "") ? <SpinnerComponent /> :
                        venues.map((venue, index) => (
                            <Col sm={12} md={4} lg={4} key={index}>
                                <Link className=' scroller-item text-dark text-decoration-none p-2 rounded-3 link' onClick={() => getVenueDetails(venue)} >
                                    <Card className=' hShadow '>
                                        <Card.Body className='px-3 py-2 my-3'>
                                            <Card.Text className='venueName'>{venue.Name}</Card.Text>
                                            <Card.Text className="venuePlace text.muted small">
                                                {venue.City} &nbsp;•&nbsp; {venue.Country}
                                            </Card.Text>
                                            <Card.Text className="venuePlace text.muted small">
                                                {venue.NumberOfEvents} Total Events &nbsp;•&nbsp; {venue.upcomingEventCount} Upcoming Events
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        ))
                    }

                    <Col sm={12} md={12} lg={12} className='d-flex gap-4 justify-content-start w-100 mt-3'>
                        <Link to={'/venues'}>
                            <Button className='btn-view-all rounded-5' variant="secondary">View All</Button>
                        </Link>
                    </Col>
                </Row>
                {/* End  Venue Section*/}

                {/* start News Letter*/}
                <Row className='row text-center border rounded-3 mx-0 my-5 news-letter'>
                    <div className='col-12 col-md-6 text-center'>
                        <img src="image/newsletter3.png" alt="news letter" className='w-75' />
                    </div>
                    <div className='col-12 col-md-6 px-0 my-auto'>
                        <h3 className='fs-5 fw-normal my-2'>Looking for the Event Feed? Get the News Letters!</h3>
                        <div>Find great events to attend, connect with new opportunities and expand your networking!</div>
                        <br />
                        <div className='form-group row'>
                            <div className='col-12 col-md-9 my-2'>
                                <input type="email" className="form-control link_email" placeholder="e.g. abc@xyx" aria-label="link email" />
                            </div>
                            <div className='col-12 col-md-3 text-center text-md-start px-0 my-2 custyle'>
                                <button type="button" className="btn expo-primary">Send News</button>
                            </div>
                        </div>
                        <br />
                    </div>
                </Row>
                {/* end News Letter*/}

            </Container>

        </>
    );
}

export default Home;
