import React, { useState, useEffect } from 'react';
import './test.css'

const Test = () => {
    const [isSticky, setSticky] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (

        <div className={`row`}>

            <div className={`col-4 your-section ${isSticky ? 'sticky' : ''}` }>
                <div className="  position-relative" id="section1">
                    v
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquamnetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                    v
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.

                </div>
            </div>
            <div className=" col-4 ">

                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                v
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                v
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                v
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.

            </div>
            <div className=" col-4 ">

                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                v
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                v
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.
                v
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem harum aliquam ab hic vel, tenetur delectus labore eveniet beatae odio, unde cumque aperiam non cupiditate sunt, laborum distinctio. Molestiae, sequi.

            </div>

        </div>
    );
};

export default Test;
