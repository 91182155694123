export default function getMobileNumber(data) {
    return {
        type: "GET_MOBILENUMBER",
        payload: data
    }
}
export  function getCountryCode(data) {
    return {
        type: "GET_COUNTRYCODE",
        payload: data
    }
}
export  function userInfo(data) {
    return {
        type: "GET_USERINFO",
        payload: data
    }
}
export  function getLocation(data) {
    return {
        type: "GET_LOCATION",
        payload: data
    }
}
export  function getEventDetails(data) {
    return {
        type: "GET_EVENTDETAILS",
        payload: data
    }
}

export const login = (username) => ({
    type: 'LOGIN',
    payload: { username },
  });
  
  export const logout = () => ({
    type: 'LOGOUT',
  });
  