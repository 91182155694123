import React, { useEffect, useState } from "react";
import moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Accordion from "react-bootstrap/Accordion";
import axiosInstance from "../../AxiosConfig/axiosinstance";

const SpeakerEvent = () => {
  const result = localStorage.getItem("event");
  const event = JSON.parse(result);

  const [speakers, setSpeakers] = useState([]);

  const getAllSpeakers = async () => {
    try {
      const response = await axiosInstance.get(
        `/getEventSpeakers?eventId=${event.EventData.data._id}&limit=`
      );
      // console.log(response.data.data);
      setSpeakers(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSpeakers();
    // console.log(speakers);
  }, []);
  return (
    <section className="">
      {speakers.length > 0 ? (
        <>
          <div className="fs-14 box">
            <div>
              <span>
                <h2 className="d-inline fw-bold" style={{ fontSize: "1.2em" }}>
                  Speakers List
                </h2>
              </span>
              <span className="text-end">
                <span className="position-relative float-end">
                  <span className="fs-12 text-muted">
                    Interested in speaking?
                    <a
                      href=""
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-sm fs-12 btn-expo ms-2"
                    >
                      {" "}
                      Send Enquiry
                    </a>
                  </span>
                  <span className="btn btn-sm btn-official x-rabb ms-2 text-decoration-none fs-12 cursor">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-person-fill-add"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                      <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
                    </svg>
                    Invite
                  </span>
                </span>
              </span>
            </div>
            <div className="text-muted mt-3">
              Add Follow these influential speakers in order to receive their
              updates, insights & speaking agenda details and stay tuned.
            </div>
            <div className="mt-2">
              <span>
                <select
                  name=""
                  id=""
                  className="btn btn-official text-muted fs-14 ms-0"
                  style={{
                    margin: "5px",
                    borderRadius: "5px",
                    background: "#fff",
                  }}
                >
                  <option value=""> All Editions </option>
                  <option value=""> Oct 2022</option>
                  <option value=""> Oct 2022</option>
                  <option value=""> Oct 2022</option>
                </select>
              </span>
              <span className="float-end">
                <span className="tentimes-form container">
                  <input
                    type="text"
                    id="search2"
                    placeholder="Search by Name"
                    aria-label="visitor_search"
                    name="v_search"
                    style={{ paddingRight: 15, maxWidth: 150 }}
                    className="fs-14 search_placeholder container"
                  />
                  <svg
                    style={{
                      bottom: 30,
                      right: 14,
                      color: "rgba(9, 79, 183, 1)",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    fill="currentColor"
                    className="bi bi-search position-absolute"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg>
                </span>
              </span>
            </div>
          </div>
          <div className="visitor speaker container row">
            {speakers.map((speaker, index) => {
              return (
                <div key={index} className="col-lg-3 col-md-4 col-sm-12">
                  <div className="box text-center box-210">
                    <div className="scroll no-scroller navbar-nav-scroll">
                      <a
                        href=""
                        className="speakerData text-muted d-inline-block"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi bi-heart-fill float-end heart x-ss-trh"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                          />
                        </svg>
                      </a>
                      <div className="triangle">
                        <span>speaker</span>
                      </div>
                      <img
                        src={speaker.Image}
                        alt={speaker.FirstName}
                        className="rounded-2 img-48 lazyloaded"
                      />
                      <div className="mt-2 fs-14">
                        {speaker.FirstName} {speaker.LastName}
                      </div>
                      <span className="fs-12">
                        {speaker.Role} at {speaker.Organization} <br />
                        {speaker.City}, {speaker.Country}
                      </span>
                    </div>
                    <div
                      className="row action-button"
                      style={{ marginLeft: "-15px", padding: "10px" }}
                    ></div>
                    <div className="d-flex justify-content-center mt-3">
                      <button className="btn-follow ">Follow</button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <section
          className="box"
          style={{ height: "100px", minHeight: "100px", textAlign: "center" }}
        >
            There is no speaker mapped to this event. Interested in speaking?
          <button className="btn btn-expo d-block m-auto mt-2">Interested</button>
        </section>
      )}
    </section>
  );
};

export default SpeakerEvent;
