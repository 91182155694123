import React from "react";
import './venuesDetails.css';

const VenuesDetails = () => {


    return(<>
                
                <main className="main">
                     <section className="contact my-5">
                          <h1 className="title text-center">Awesome Thing</h1>
                            <h2 className="sub-title text-center">Site Under Construction</h2>
                    </section>
                 </main>
        </>
    )
}
export default VenuesDetails