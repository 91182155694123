import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import axiosInstance from "../../AxiosConfig/axiosinstance";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import InfiniteScroll from "react-infinite-scroll-component";
import SpinnerComponent from "../../Components/Spinner/spinner";
import moment from "moment";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getEventDetails } from "../../Store/action";
import "./events.css";


const Events = () => {
  const cardColors = [
    "linear-gradient(90deg, #F8F5F0, #F4CB8C)",
    "linear-gradient(90deg, #F6FCF2, #D1F2B8)",
    "linear-gradient(90deg, #F5F7FB, #B4C4DA)",
  ];

  const [events, setEvents] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [emptyEvent, setEmptyEvent] = useState("");
  const [categories, setCategories] = useState({});
  const [relatedTopics, setRelatedTopics] = useState({});
  const [cities, setCitiess] = useState({});
  const [countries, setCountries] = useState({});

  // handel alert for country
  const [showCountry, setShowCountry] = useState(false);
  const handleCloseCountry = () => setShowCountry(false);
  const handleShowCountry = () => setShowCountry(true);

  // handel alert for City
  const [showCity, setShowCity] = useState(false);
  const handleCloseCity = () => setShowCity(false);
  const handleShowCity = () => setShowCity(true);

  // handel alert for Category
  const [showCategory, setShowCategory] = useState(false);
  const handleCloseCategory = () => setShowCategory(false);
  const handleShowCategory = () => setShowCategory(true);

  // handel alert for Related Topic
  const [showTopic, setShowTopic] = useState(false);
  const handleCloseTopic = () => setShowTopic(false);
  const handleShowTopic = () => setShowTopic(true);

  const [filters, setFilters] = useState({
    country: "",
    city: "",
    rating: "",
    fee: "",
    members: "",
    format: "",
    month: "",
    limit: "200",
    topic: "",
    category: "",
  });

  const [result, setResult] = useState();
  const [eventByDate, setEventByDate] = useState();

  const handelCheckCalendar = async (e) => {
    setResult(true);
    console.log(result);
    localStorage.removeItem("filter")
    // Request get data by Filter
    const newFilters = { ...filters };
    switch (e.name) {
      case "date":
        newFilters.month = e.value;
        break;
      case "format":
        newFilters.format = e.value;
        break;
      case "country":
        newFilters.country = e.value;
        break;
      case "city":
        newFilters.city = e.value;
        break;
      case "fee":
        newFilters.fee = e.value;
        break;
      case "rating":
        newFilters.rating = e.value;
        break;
      case "members":
        newFilters.members = encodeURIComponent(e.value);
        break;
      case "limit":
        newFilters.limit = 25;
        break;
      case "topic":
        newFilters.topic = encodeURIComponent(e.value);
        break;
      case "category":
        newFilters.category = encodeURIComponent(e.value);
        break;
      default:
        break;
    }
   

    let currentPage = 0;

    // Construct the filter query string
    const filterByCountry = `filterByCountry?country=${newFilters.country}&rating=${newFilters.rating}&fee=${newFilters.fee}&members=${newFilters.members}&format=${newFilters.format}&month=${newFilters.month}&limit=${newFilters.limit}&topic=${newFilters.topic}&category=${newFilters.category}&page=${currentPage}`;
    // const filterByCity = `filterByCity?city=${newFilters.city}&rating=${newFilters.rating}&fee=${newFilters.fee}&members=${newFilters.members}&format=${newFilters.format}&month=${newFilters.month}&limit=${newFilters.limit}&topic=${newFilters.topic}&category=${newFilters.category}&page=${Math.ceil(events.length / 20) + 1}`;
    const filterByCity = `filterByCity?city=${newFilters.city}&rating=${newFilters.rating}&fee=${newFilters.fee}&members=${newFilters.members}&format=${newFilters.format}&month=${newFilters.month}&limit=${newFilters.limit}&topic=${newFilters.topic}&category=${newFilters.category}&page=${currentPage}`;

    if (e.checked) {
      if (e.name == "city") {
        console.log("data send ");
        currentPage++
        await axiosInstance
          .get(filterByCity)
          .then((response) => {
            setResult((prev) => !prev);
            // console.log("data come ");
            if (response.data.filteredResults == "") {
              //  console.log("empty Events");
              setEmptyEvent("Unfortunately, there are no Events here");
            }
            setEvents(response.data.filteredResults);
            // console.log(result);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (e.name !== "city") {
        await axiosInstance
          .get(filterByCountry)
          .then((response) => {
            setResult((prev) => !prev);
            // console.log(response.data.filteredResults);
            if (response.data.filteredResults == "") {
              //  console.log("empty Events");
              setEmptyEvent("Unfortunately, there are no Events here");
            }
            setEvents(response.data.filteredResults);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      getAllEvents();
    }
    setFilters(newFilters);
  };

  const [active, setActive] = useState("black");

  // get all Events
  const getAllEvents = async () => {
    const filterCity = localStorage.getItem("filter");
    const filterCountry = localStorage.getItem("filterCountry");

    if (filterCity) {
      localStorage.removeItem("filter");
      await axiosInstance
        .get(`filterByCity?city=${filterCity}&rating=&fee=&members=&format=&month=&limit=200&topic=&category=&page=0`)
        .then((response) => {

          // console.log(response.data);
          if (response.data.filteredResults == "") {
            setEmptyEvent("Unfortunately, there are no Events here");
          }
          setEvents(response.data.filteredResults);
          setFeaturedEvents(response.data.featuredEvents);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (filterCountry) {
      localStorage.removeItem("filterCountry");
      await axiosInstance
        .get(`filterByCountry?country=${filterCountry}&rating=&fee=&members=&format=&month=&limit=200&topic=&category=&page=0`)
        .then((response) => {
          // console.log(response.data);
          if (response.data.filteredResults == "") {
            setEmptyEvent("Unfortunately, there are no Events here");
          }
          setEvents(response.data.filteredResults);
          setFeaturedEvents(response.data.featuredEvents);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (eventByDate) {
      setResult(true);
      let currentPage = Math.ceil(events.length / 20) + 1;
      axiosInstance
        .get(`/getEventsByDate?limit=40&page=${currentPage - 1}`)
        .then((response) => {
          // console.log(response.data.data);
          setEvents(response.data.data);
          setActive("#862efb");
          setResult(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // console.log("Don't have value");
      let currentPage = Math.ceil(events.length / 20) + 1;
      axiosInstance
        .get(`/getEventsByTrending?limit=20&page=${currentPage - 1}`)
        .then((response) => {
          // console.log(currentPage);
          setEvents((prevEvents) => [...prevEvents, ...response.data.events]);
          setFeaturedEvents((prevFeatured) => [...prevFeatured, ...response.data.featuredEvents]);
          setActive("black");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //get all Category and Topic and city and Country
  const getFilterData = () => {

    axiosInstance
      .get("/filterEventInfo")
      .then((response) => {
        // console.log(response.data.data);
        setCategories(response.data.data.Category);
        setRelatedTopics(response.data.data.Topics);
        setCitiess(response.data.data.City);
        // console.log(response.data.data.Topics);
        setCountries(response.data.data.Country);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // send Event Details
  const eventDetails = (event) => {

    axiosInstance
      .get(`/aboutEvent/${event._id}?country=${event.Country}&city=${event.City}&category=${encodeURIComponent(event.Category[0])}`)
      .then((response) => {
        // console.log(response.data);
        dispatch(getEventDetails(response.data));
        const result = JSON.stringify(response.data);
        localStorage.setItem("event", result);
        navigate("/eventsinfo");
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  //handel get Events sort by date
  const getEventsByDate = () => {
    setResult(true);
    setEventByDate(true);
    axiosInstance
      .get("/getEventsByDate?limit=50")
      .then((response) => {
        // console.log(response.data.data);
        setEvents(response.data.data);
        setActive("#862efb");
        setResult(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllEvents();
    getFilterData();
  }, []);

  

  return (


    <div className="pt-4 mb-3">
      {/* first section  */}
      <Container>
        <Row className="pb-3">
          <Col className="col-12 col-md-8">
            <h1 className="mb-0">All Events</h1>
            <div
              id="realted-search"
              className="d-flex fw-bold small pt-md-2 pb-1"
            >
              <div>
                <a
                  href="#"
                  className="d-inline-block text-dark fw-400 text-decoration-none bg-white py-1 px-2 me-3 my-2 related-search"
                  style={{
                    border: " 2px solid #e7e7e7",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  Education & Training
                </a>
                <a
                  href="#"
                  className="d-inline-block text-dark fw-400 text-decoration-none bg-white py-1 px-2 me-3 my-2 related-search"
                  style={{
                    border: " 2px solid #e7e7e7",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  Medical & Pharma
                </a>
                <a
                  href="#"
                  className="d-inline-block text-dark fw-400 text-decoration-none bg-white py-1 px-2 me-3 my-2 related-search"
                  style={{
                    border: " 2px solid #e7e7e7",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  IT & Technology
                </a>
                <a
                  href="#"
                  className="d-inline-block text-dark fw-400 text-decoration-none bg-white py-1 px-2 me-3 my-2 related-search"
                  style={{
                    border: " 2px solid #e7e7e7",
                    borderRadius: "5px",
                  }}
                >
                  {" "}
                  Banking & Finance
                </a>
              </div>
            </div>
          </Col>
          <Col className="col-md-4 text-end">
            <div className="small mb-3">
              <span className="position-relative" style={{ left: "4%" }}>
                <img
                  className="position-relative border border-white rounded-circle protrude"
                  src="https://c1.10times.com/userimages/e.jpg"
                  alt="e"
                  width="30"
                  height="30"
                  loading="lazy"
                />
                <img
                  className="position-relative border border-white rounded-circle protrude"
                  src="https://c1.10times.com/userimages/d.jpg"
                  alt="e"
                  width="30"
                  height="30"
                  loading="lazy"
                  style={{ left: "-3%" }}
                />
                <img
                  className="position-relative border border-white rounded-circle protrude"
                  src="https://c1.10times.com/userimages/f.jpg"
                  alt="e"
                  width="30"
                  height="30"
                  loading="lazy"
                  style={{ left: "-6%" }}
                />
              </span>
              <span className="fw-500">1 Million+ Followers</span>
            </div>
            <button
              className="btn btn-sm btn-primary py-1 me-3 follow-page"
              disabled
              aria-label="page-follow"
            >
              Following
            </button>

            <button
              id="page-share"
              className="btn btn-sm btn-light border-0 py-1 hShadow share-page share_top"
              aria-label="share"
              style={{ boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-box-arrow-in-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
                />
                <path
                  fillRule="evenodd"
                  d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
                />
              </svg>{" "}
              &nbsp; Share
            </button>
          </Col>
        </Row>
      </Container>
      {result ? <SpinnerComponent /> : ""}

      {/* second Section  */}
      <main className="main-wrapper " style={{ backgroundColor: "#f8f9fa" }}>
        <Container className="py-3">
          <Row id="content-main " className="">
            {/* Start Filter Section*/}
            <Col sm={12} md={6} lg={3} style={{ overflowY: "auto" }}>
              <div
                id=""
                className="box bg-white deep-shadow position-relative"
              // style={{ maxWidth: "100%", width: "306px" }}
              >
                <div
                  id="accordion-filter"
                  className="accordion accordion-flush"
                >
                  <Accordion defaultActiveKey={["2", "3", "4"]}>
                    {/* Calendar */}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Calendar</Accordion.Header>

                      <Accordion.Body>

                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-01"
                            id="8"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="8">
                            January, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-02"
                            id="9"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="9">
                            February, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-03"
                            id="10"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="10">
                            March, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-04"
                            id="11"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="11">
                            April, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-05"
                            id="12"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="12">
                            May, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-06"
                            id="13"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="13">
                            June, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-07"
                            id="14"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="14">
                            July, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-08"
                            id="15"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="15">
                            August, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-09"
                            id="4"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="4">
                            September, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-10"
                            id="5"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="5">
                            October, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-11"
                            id="6"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="6">
                            November, 2024
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="date"
                            className="form-check-input"
                            type="radio"
                            value="2024-12"
                            id="7"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="7">
                            December, 2024
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Format */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Format</Accordion.Header>
                      <Accordion.Body>
                        <div className="form-check my-3">
                          <input
                            name="format"
                            className="form-check-input"
                            type="radio"
                            value="Trade Shows"
                            id="1"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="1">
                            Trade Shows
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="format"
                            className="form-check-input"
                            type="radio"
                            value="Conferences"
                            id="2"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="2">
                            Conferences
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="format"
                            className="form-check-input"
                            type="radio"
                            value="Workshops"
                            id="3"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="3">
                            Workshops
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Location */}
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Location</Accordion.Header>
                      <Accordion.Body>
                        {Object.keys(countries).map(
                          (countryName, index) => {
                            if (index < 5) {
                              return (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                                >
                                  <div className="form-check">
                                    <input
                                      name="country"
                                      className="form-check-input"
                                      type="radio"
                                      value={countryName}
                                      id={countryName}
                                      onChange={(e) =>
                                        handelCheckCalendar(e.target)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={countryName}
                                    >
                                      {countryName}
                                    </label>
                                  </div>
                                  <small className="text-blue fw-500 ms-2">
                                    {countries[countryName].Number}
                                  </small>
                                </div>
                              );
                            }
                            return null;
                          }
                        )}

                        <button
                          onClick={handleShowCountry}
                          id="page-share"
                          className="d-flex justify-content-center btn btn-sm btn-light rounded-4 py-1 hShadow share-page share_top w-50 my-3"
                          aria-label="share"
                          style={{
                            boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)",
                          }}
                        >
                          {" "}
                          View All{" "}
                        </button>
                        <hr />

                        {/* modal */}
                        <Modal
                          size="lg"
                          show={showCountry}
                          onHide={handleCloseCountry}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>List of Countries</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Container>
                              <Row >
                                {Object.keys(countries).map(
                                  (countryName, index) => {
                                    if (index < 500) {
                                      return (
                                        <div key={index} className="col-4">
                                          <div
                                            className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                                            key={countryName}
                                          >
                                            <div className="form-check d-flex btn text-muted text-start fw-normal w-100 h-100 bg-light rounded-3">
                                              <input
                                                name="country"
                                                className="form-check-input d-none"
                                                type="radio"
                                                value={countryName}
                                                id={countryName}
                                                onChange={(e) =>
                                                  handelCheckCalendar(
                                                    e.target
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={countryName}
                                                onClick={handleCloseCountry}
                                              >
                                                {countryName}
                                              </label>
                                              <small
                                                className="text-blue fw-500 ms-2"
                                                style={{ color: "#1f5d84" }}
                                              >
                                                (
                                                {
                                                  countries[countryName]
                                                    .Number
                                                }
                                                )
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </Row>
                            </Container>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseCountry}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleCloseCountry}
                            >
                              Save Changes
                            </Button>
                          </Modal.Footer>
                        </Modal>

                        {/* //////////////////////////////          City                    /////////////////////////// */}
                        {Object.keys(cities).map((cityName, index) => {
                          if (index < 5) {
                            return (
                              <div
                                key={index}
                                className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                              >
                                <div className="form-check">
                                  <input
                                    name="city"
                                    className="form-check-input"
                                    type="radio"
                                    value={cityName}
                                    id={cityName}
                                    onChange={(e) =>
                                      handelCheckCalendar(e.target)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={cityName}
                                  >
                                    {cityName}
                                  </label>
                                </div>
                                <small className="text-blue fw-500 ms-2">
                                  {cities[cityName].Number}
                                </small>
                              </div>
                            );
                          }
                          return null;
                        })}

                        <button
                          onClick={handleShowCity}
                          id="page-share"
                          className="d-flex justify-content-center btn btn-sm btn-light rounded-4 py-1 hShadow share-page share_top w-50 my-3"
                          aria-label="share"
                          style={{
                            boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)",
                          }}
                        >
                          {" "}
                          View All{" "}
                        </button>
                        {/* modal */}
                        <Modal show={showCity} onHide={handleCloseCity}>
                          <Modal.Header closeButton>
                            <Modal.Title>List of Cities</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Container>
                              {Object.keys(cities).map(
                                (cityName, index) => {
                                  if (index < 500) {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <div
                                            className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                                            key={cityName}
                                          >
                                            <div className="form-check d-flex btn text-muted text-start fw-normal w-100 h-100 bg-light rounded-3">
                                              <input
                                                name="city"
                                                className="form-check-input d-none"
                                                type="radio"
                                                value={cityName}
                                                id={cityName}
                                                onChange={(e) =>
                                                  handelCheckCalendar(
                                                    e.target
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={cityName}
                                                onClick={handleCloseCity}
                                              >
                                                {cityName}
                                              </label>
                                              <small
                                                className="text-blue fw-500 ms-2"
                                                style={{ color: "#1f5d84" }}
                                              >
                                                ({cities[cityName].Number})
                                              </small>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </Container>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseCity}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleCloseCity}
                            >
                              Save Changes
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Category */}
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Category</Accordion.Header>
                      <Accordion.Body>
                        {Object.keys(categories).map(
                          (categoryName, index) => {
                            if (index < 5) {
                              return (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                                >
                                  <div className="form-check">
                                    <input
                                      name="category"
                                      className="form-check-input"
                                      type="radio"
                                      value={categoryName}
                                      id={categoryName}
                                      onChange={(e) =>
                                        handelCheckCalendar(e.target)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={categoryName}
                                    >
                                      {categoryName}
                                    </label>
                                  </div>
                                  <small className="text-blue fw-500 ms-2">
                                    {categories[categoryName].Number}
                                  </small>
                                </div>
                              );
                            }
                            return null;
                          }
                        )}

                        <button
                          onClick={handleShowCategory}
                          id="page-share"
                          className="d-flex justify-content-center btn btn-sm btn-light rounded-4 py-1 hShadow share-page share_top w-50 my-3"
                          aria-label="share"
                          style={{
                            boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)",
                          }}
                        >
                          {" "}
                          View All{" "}
                        </button>
                        {/* modal */}
                        <Modal
                          show={showCategory}
                          onHide={handleCloseCategory}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>List of Categories</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Container>
                              {Object.keys(categories).map(
                                (categoryName, index) => {
                                  if (index < 500) {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <div
                                            className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga "
                                            key={categoryName}
                                          >
                                            <div className="form-check d-flex btn text-muted text-start fw-normal w-100 h-100 bg-light rounded-3">
                                              {" "}
                                              &nbsp;
                                              <input
                                                name="category"
                                                className="form-check-input d-none"
                                                type="radio"
                                                value={categoryName}
                                                id={categoryName}
                                                onChange={(e) =>
                                                  handelCheckCalendar(
                                                    e.target
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={categoryName}
                                                onClick={
                                                  handleCloseCategory
                                                }
                                              >
                                                <img
                                                  className="mx-2"
                                                  src={
                                                    categories[categoryName]
                                                      .Icon
                                                  }
                                                  alt={
                                                    categories[categoryName]
                                                  }
                                                  width={20}
                                                  height={20}
                                                />
                                                {categoryName}
                                              </label>
                                              <small
                                                className="text-blue fw-500 ms-2"
                                                style={{ color: "#1f5d84" }}
                                              >
                                                (
                                                {
                                                  categories[categoryName]
                                                    .Number
                                                }
                                                )
                                              </small>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </Container>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseCategory}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleCloseCategory}
                            >
                              Save Changes
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Related Topic */}
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Related Topic</Accordion.Header>
                      <Accordion.Body>
                        {Object.keys(relatedTopics).map(
                          (relatedTopicName, index) => {
                            if (index < 5) {
                              return (
                                <div
                                  key={index}
                                  className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                                >
                                  <div className="form-check">
                                    <input
                                      name="topic"
                                      className="form-check-input"
                                      type="radio"
                                      value={relatedTopicName}
                                      id={relatedTopicName}
                                      onChange={(e) =>
                                        handelCheckCalendar(e.target)
                                      }
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={relatedTopicName}
                                    >
                                      {relatedTopicName}
                                    </label>
                                  </div>
                                  <small className="text-blue fw-500 ms-2">
                                    {relatedTopics[relatedTopicName]}
                                  </small>
                                </div>
                              );
                            }
                            return null;
                          }
                        )}
                        <button
                          onClick={handleShowTopic}
                          id="page-share"
                          className="d-flex justify-content-center btn btn-sm btn-light rounded-4 py-1 hShadow share-page share_top w-50 my-3"
                          aria-label="share"
                          style={{
                            boxShadow: " 0 3px 6px 0 rgb(0,0,0,.2)",
                          }}
                        >
                          {" "}
                          View All{" "}
                        </button>
                        {/* modal */}
                        <Modal show={showTopic} onHide={handleCloseTopic}>
                          <Modal.Header closeButton>
                            <Modal.Title>List of Related Topic</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Container>
                              {Object.keys(relatedTopics).map(
                                (relatedTopicName, index) => {
                                  if (index < 500) {
                                    return (
                                      <Row key={index}>
                                        <Col>
                                          <div
                                            className="d-flex justify-content-between align-items-center w-100 text-start px-0 py-2 c-ga"
                                            key={relatedTopicName}
                                          >
                                            <div className="form-check d-flex btn text-muted text-start fw-normal w-100 h-100 bg-light rounded-3">
                                              <input
                                                name="topic"
                                                className="form-check-input d-none"
                                                type="radio"
                                                value={relatedTopicName}
                                                id={relatedTopicName}
                                                onChange={(e) =>
                                                  handelCheckCalendar(
                                                    e.target
                                                  )
                                                }
                                              />
                                              <label
                                                className="form-check-label"
                                                htmlFor={relatedTopicName}
                                                onClick={handleCloseTopic}
                                              >
                                                # {relatedTopicName}
                                              </label>
                                              <small
                                                className="text-blue fw-500 ms-2"
                                                style={{ color: "#1f5d84" }}
                                              >
                                                (
                                                {
                                                  relatedTopics[
                                                  relatedTopicName
                                                  ]
                                                }
                                                )
                                              </small>
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    );
                                  }
                                  return null;
                                }
                              )}
                            </Container>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={handleCloseTopic}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleCloseTopic}
                            >
                              Save Changes
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Entry Fee */}
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Entry Fee</Accordion.Header>
                      <Accordion.Body>
                        <div className="form-check my-3">
                          <input
                            name="fee"
                            className="form-check-input"
                            type="radio"
                            value="Free"
                            id="Free"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Free"
                          >
                            Free
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="fee"
                            className="form-check-input"
                            type="radio"
                            value="Paid"
                            id="Paid"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Paid"
                          >
                            Paid
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="fee"
                            className="form-check-input"
                            type="radio"
                            value="Free%20%26%20Paid"
                            id="Free & Paid"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="Free & Paid"
                          >
                            Free & Paid
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Rating */}
                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Rating</Accordion.Header>
                      <Accordion.Body>
                        <div className="form-check my-3">
                          <input
                            name="rating"
                            className="form-check-input"
                            type="radio"
                            value="2"
                            id="two"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="two">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>{" "}
                            & up
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="rating"
                            className="form-check-input"
                            type="radio"
                            value="3"
                            id="three"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="three"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>{" "}
                            & up
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="rating"
                            className="form-check-input"
                            type="radio"
                            value="4"
                            id="four"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="four"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                            </svg>
                            &nbsp;
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-star"
                              viewBox="0 0 16 16"
                            >
                              <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                            </svg>{" "}
                            & up
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Members  */}
                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Members</Accordion.Header>
                      <Accordion.Body>
                        <div className="form-check my-3">
                          <input
                            name="members"
                            className="form-check-input"
                            type="radio"
                            value="100"
                            id="100"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="100">
                            100 to 500
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="members"
                            className="form-check-input"
                            type="radio"
                            value="500"
                            id="500"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label className="form-check-label" htmlFor="500">
                            +500
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="members"
                            className="form-check-input"
                            type="radio"
                            value="1000"
                            id="1000"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="1000"
                          >
                            +1000
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="members"
                            className="form-check-input"
                            type="radio"
                            value="2000-5000"
                            id="5000"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="5000"
                          >
                            2000 to 5000
                          </label>
                        </div>
                        <div className="form-check my-3">
                          <input
                            name="members"
                            className="form-check-input"
                            type="radio"
                            value="5000-20000"
                            id="20000"
                            onChange={(e) => handelCheckCalendar(e.target)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="20000"
                          >
                            5000 to 20000
                          </label>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    {/* Create Filter */}
                    <Accordion.Item eventKey="8">
                      <Accordion.Header
                        onClick={() =>
                          console.log("hello frome new filter")
                        }
                      >
                        Create Filter
                      </Accordion.Header>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Col>
            {/* End Filter Section*/}

            {/* Start Events Section*/}
            <Col sm={12} md={6} lg={5}>
              <div
                id="sort-by"
                className="position-relative box col-12 d-flex align-items-center small py-2 mb-3 bg-white deep-shadow"
                style={{ boxShadow: "0 1px 4px rgba(0,0,0,.18)" }}
              >
                <Navbar expand="lg" className="">
                  <Navbar.Toggle aria-controls="navbarScroll" />
                  <Navbar.Collapse id="navbarScroll">
                    <Nav
                      className="me-auto my-2 my-lg-0"
                      style={{ maxHeight: "100px", width: "100%" }}
                      navbarScroll
                    >
                      <NavLink
                        style={({ isActive }) =>
                          isActive
                            ? { color: "#862efb" }
                            : { color: "black" }
                        }
                        to="/events"
                        className="navbarLink mx-3"
                        onClick={() => {
                          getAllEvents();
                        }}
                      >
                        Trending
                      </NavLink>
                      <NavLink
                        style={({ isActive }) =>
                          isActive
                            ? { color: active }
                            : { color: "#862efb" }
                        }
                        to="/events"
                        className="navbarLink mx-3"
                        onClick={() => {
                          getEventsByDate();
                        }}
                      >
                        Date
                      </NavLink>
                    </Nav>
                  </Navbar.Collapse>
                  {/* </Container> */}
                </Navbar>
              </div>

              {events == "" ? (
                emptyEvent !== "" ? (
                  <h5>{emptyEvent}</h5>
                ) : (
                  <SpinnerComponent />
                )
              ) : (
                <InfiniteScroll
                  dataLength={events.length}
                  next={getAllEvents}
                  // next={handelCheckCalendar}
                  // style={{
                  //   display: "flex",
                  //   flexDirection: "column-reverse",
                  // }} //To put endMessage and loader to the top.
                  // inverse={true} //
                  hasMore={true}
                  loader={<h4>Loading...</h4>}
                //  scrollableTarget="scrollableDiv"
                >
                  {events.map((event, index) => {
                    const isTenthElement = (index + 1) % 10 === 0; // Check if it's the tenth element
                    const elementToRender = (
                      <div
                        key={index}
                        id="sort-by"
                        className="   small  mb-3 bg-white deep-shadow"
                        style={{ boxShadow: "0 1px 4px rgba(0,0,0,.18)" }}
                      >
                        <a
                          className=" scroller-item text-dark text-decoration-none  rounded-3 link cursor"
                          onClick={(e) =>
                            eventDetails(event)
                          }
                        >
                          <Card className="border-0">
                            <Card.Body className="p-2">
                              {/* name and date */}
                              <Row>
                                <Col className="col-9">
                                  <Card.Text className="event-date fw-500  mb-1">
                                    {moment(event.StartDate).format(
                                      "ddd, D - "
                                    )}{" "}
                                    {moment(event.EndDate).format(
                                      "ddd, D MMM YYYY"
                                    )}
                                  </Card.Text>
                                  <span className="eventName d-block event-name">
                                    {/* Medicall Delhi (Medicall Expo)  */}
                                    {event.Name}
                                  </span>
                                  <Card.Subtitle className="event-location fw-500 mt-2 mb-3">
                                    {/* New Delhi, India */}
                                    {event.City !== "N/A" ? `${event.City},` : ""} {event.Country}
                                  </Card.Subtitle>
                                </Col>
                                <Col className="col-3 d-flex justify-content-end ">
                                  <Image
                                    className="event-img "
                                    src={event.Banner}
                                  />
                                </Col>

                                <Col className="col-12 mt-3">
                                  <div className="event-prev text-wrap text-break">
                                    {event.Summary !== "Null" ? event.Summary : ""}
                                  </div>
                                </Col>
                              </Row>

                              {/* category and topic */}
                              <div className="mt-1 small text-muted mb-2">
                                {event.Topics.map((topic, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="  me-2 p-1  bg-light rounded-1 text-muted event-topic 1h-1 d-inline-block "
                                    >
                                      {topic}
                                    </span>
                                  );
                                })}

                                {event.Category.map((cat, index) => {
                                  return (
                                    <span
                                      key={index}
                                      className="  me-2 p-1  bg-light rounded-1 text-muted event-category"
                                    >
                                      {cat}
                                    </span>
                                  );
                                })}
                              </div>

                              {/* footer section */}
                              <Col>
                                <div className="d-flex justify-content-between align-items-center mb-2 ">
                                  {/* Interested */}
                                  <div
                                    className="small text-center rounded-3 bg-light"
                                    style={{
                                      color: " #161616",
                                      border: "2px solid #e7e7e7",
                                    }}
                                  >
                                    <button className=" btn btn-sm fw-600 border-0 border-end rounded-0 bg-white a-m c-ga xn interested">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-ticket-detailed-fill me-1"
                                        viewBox="0 0 16 16"
                                        style={{ rotate: "-40deg" }}
                                      >
                                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5Zm4 1a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5Zm0 5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5ZM4 8a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H5a1 1 0 0 0-1 1Z" />
                                      </svg>
                                      <span
                                        className="interested fw-600"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Interested
                                      </span>
                                    </button>
                                    <a
                                      href="#"
                                      className="small fw-500 text-decoration-none px-2 xn text-dark interested"
                                    >
                                      {event.Interested}
                                    </a>
                                  </div>
                                  {/* rating */}
                                  <div>
                                    <span
                                      className="float-end "
                                      style={{ marginTop: "5px" }}
                                    >
                                      <a
                                        href="#"
                                        className="badge fw-500 text-decoration-none interested"
                                        style={{
                                          backgroundColor: "#198754",
                                        }}
                                      >
                                        {event.Rating.Average.toFixed(1)}
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="13"
                                          height="13"
                                          fill="currentColor"
                                          className="bi bi-star-fill ms-1"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                        </svg>
                                      </a>
                                      <button className="selectEvent add-new-listing btn p-0">
                                        <svg
                                          className="svg-inline--fa fa-list-add ms-2 mt-1 fs-22"
                                          style={{
                                            width: "2rem",
                                            cursor: " pointer",
                                          }}
                                          aria-hidden="true"
                                          focusable="false"
                                          data-prefix="fak"
                                          data-icon="list-add"
                                          role="img"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 512 512"
                                          data-fa-i2svg=""
                                        >
                                          <path
                                            fill="currentColor"
                                            d="M298.7 213.3H42.7V256h256v-42.7zm0-85.3H42.7v42.7h256V128zM384 298.7v-85.3h-42.7v85.3H256v42.7h85.3v85.3H384v-85.3h85.3v-42.7H384zM42.7 341.3h170.7v-42.7H42.7v42.7z"
                                          ></path>
                                        </svg>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Card.Body>
                          </Card>
                        </a>
                      </div>
                    );
                    if (isTenthElement && index !== 0) {
                      return (
                        <React.Fragment key={index}>
                          <section className="box fs-14">
                            <h5
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                height: "100px",
                                minHeight: "100px",
                              }}
                            >
                              advertise with us
                            </h5>
                          </section>
                          {elementToRender}
                        </React.Fragment>
                      );
                    }

                    return elementToRender;
                  })}
                </InfiniteScroll>
              )}
            </Col>
            {/* End Events Section*/}

            {/* Start Adsenss Section*/}
            <Col md={4} lg={4}>
              <section className="box fs-14">
                <h5
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100px", minHeight: "100px" }}
                >
                  advertise with us
                </h5>
              </section>

              <div className="d-flex justify-content-end align-items-center fw-500 mb-2">
                <span
                  className="d-inline-block me-2 rounded-1"
                  style={{
                    width: "14px",
                    height: " 14px",
                    backgroundColor: " gold",
                  }}
                ></span>
                Premium
              </div>
              {featuredEvents.map((featuredEvent, index) => {
                const cardColor = cardColors[index % cardColors.length];
                const isTenthElement = (index + 1) % 10 === 0; // Check if it's the tenth element
                const elementToRender = (
                  <div
                    key={index}
                    style={{ background: cardColor }}
                    id="featured-events"
                    className="side-lst"
                  >
                    <div
                      className="p-2 mb-3 deep-shadow premium-event event-card"
                      style={{ boxShadow: " 0 1px 4px rgba(0,0,0,.18)" }}
                    >
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="pb-2">
                              <div
                                className="small fw-500"
                                style={{ lineHeight: "1.2" }}
                              >
                                {moment(featuredEvent.StartDate).format(
                                  "ddd, D - "
                                )}{" "}
                                {moment(featuredEvent.EndDate).format(
                                  "ddd, D MMM YYYY"
                                )}
                              </div>
                              <div className="fw-bold position-relative lh-1">
                                <a
                                  href="#"
                                  className="text-decoration-none c-ga xn"
                                >
                                  <span className="position-absolute end-0">
                                    {featuredEvent.Banner !== "" ? (
                                      <img
                                        className="ms-2 mb-2 rounded-3 float-end lazyloaded"
                                        src={featuredEvent.Banner}
                                        alt=""
                                        width="50"
                                        height="50"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <span
                                    className="d-block my-1"
                                    style={{ marginRight: " 60px" }}
                                  >
                                    <span
                                      className="featured-event-name"
                                      onClick={(e) =>
                                        eventDetails(featuredEvent)
                                      }
                                    >
                                      {featuredEvent.Name}
                                    </span>
                                  </span>
                                </a>
                              </div>
                              <div className="small text-dark fw-600 venue">
                                <a
                                  href="#"
                                  className="text-decoration-none text-dark"
                                >
                                  {featuredEvent.City},{" "}
                                  {featuredEvent.Country}
                                </a>
                              </div>
                              <div className="small mt-1">
                                <a
                                  href="#"
                                  className="text-muted text-decoration-none "
                                >
                                  {featuredEvent.Category.map(
                                    (cat, index) => {
                                      return (
                                        <span
                                          key={index}
                                          className="d-inline-block small me-2 p-1 lh-1 bg-light rounded-1"
                                        >
                                          {cat}
                                        </span>
                                      );
                                    }
                                  )}
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="d-flex justify-content-between align-items-center mt-2">
                              <a
                                href="#"
                                className=" text-decoration-none text-dark small px-0 fw-600"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-person-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                </svg>
                                {featuredEvent.Interested} Members
                              </a>
                              <button className="btn btn-sm bg-light rounded-circle a-m ga c-ga xn bookmark">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-bookmark-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
                if (isTenthElement && index !== 0) {
                  // إذا كان هذا العنصر هو العنصر العاشر وليس العنصر الأول، أضف <h1>
                  return (
                    <React.Fragment key={index}>
                      <section className="box fs-14">
                        <h5
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "100px", minHeight: "100px" }}
                        >
                          advertise with us
                        </h5>
                      </section>{" "}
                      {elementToRender}
                    </React.Fragment>
                  );
                }

                return elementToRender;
              })}
            </Col>
            {/* End Adsenss Section*/}
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default Events;
