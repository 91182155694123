import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import './newPassword.css';
import axiosInstance from '../../AxiosConfig/axiosinstance';
import { useNavigate } from 'react-router-dom';

const NewPassword = () => {
    const navigate = useNavigate();

    // handel form login
    const [rePassword, setRePassword] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    //handel show alert 
    const [show, setShow] = useState(false);
    const [reqestMsg, setReqestMsg] = useState('');


    const handelNewPassword = (eve) => {
        eve.preventDefault();
        eve.preventDefault();

        //check password is matching
        if (password === rePassword) {
            // console.log(`password is : ${password}`);
            // console.log(`RePassword is : ${rePassword}`);
            axiosInstance.put('/ResetPassword', {
                "hashedUniqueString": "$2a$10$q6RNNziwUPWY6zGPb2pQT.Q.C9KUsYiZxl2LeGz0R3eBuPVOknaFy",
                "password": password
            }).then((respons) => {
                // navigate('/')
                setReqestMsg(respons.data.message);
                setShow(true);
                setTimeout(() => { navigate('/'); }, 2000)
            }).catch((error) => {
                console.log(error);
                setError(error.response.data.message)
            })
        } else {
            setError("Password is not Matching");
        }


    }
    return (
        <>
            <div className='container  justify-content-end  w-50 mt-2'>
                <Alert className='' show={show} variant="success">
                    <Alert.Heading style={{ backgroundColor: "#d1e7dd", textAlign: "center", padding: "10px" }}>{reqestMsg} ..!</Alert.Heading>
                    <p style={{ backgroundColor: "#d1e7dd" }}>
                    </p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        {/* <Button onClick={() => setShow(false)} variant="outline-success">
                        Close me
                    </Button> */}
                    </div>
                </Alert>
            </div>
            <div className=' parent  bg-white ' style={{ width: "40%", margin: "12rem auto" }}>


                <form action="" method='post' className='m-1 bg-white ' onSubmit={handelNewPassword}>
                    <div className='ticket bg-white'>
                        <center className='bg-white'>

                            <div className='fs-16 text-expo bg-white'>
                                Create New Password
                            </div>
                            <div className="mt-2 bg-white">
                                <div className="index_desktop bg-white">
                                    <br />
                                    <input className="m-2 mb-2 fs-14 shadow-login" style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 65% " }} name="password" placeholder="Password" required type='password' autoComplete="off"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <br />
                                    <input className="m-2 mb-2 fs-14 shadow-login" style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 65% " }} name="repassword" placeholder="Repeat Password" required type='password' autoComplete="off"
                                        onChange={(e) => setRePassword(e.target.value)}
                                    />
                                    <p className='bg-white text-danger'>{error}</p>
                                    <center className='bg-white'>
                                        <br />
                                        <div className='bg-white form-group small privacy-policy d-flex justify-content-center cursor-pointer privacy-policy-text mt-2  mx-5 fs-12'>
                                            <div className="bg-white">
                                                <input type="checkbox" name="" id="" className='fa-w-14 me-2 ' />
                                            </div>
                                            <div className="server_check_box_msg bg-white">
                                                I agree to <a className="text-decoration-none bg-white" href="#" target="_blank"> Privacy Policy</a> and acknowledge to receive communication from Expo Galaxy
                                            </div>
                                        </div>
                                        <input type="submit" id="send" value="New Password" className=" btn btn-default m-3 mb-2" style={{ backgroundColor: " white", border: "  0.05em solid #862efb", outline: " none", color: " #862efb", padding: " 4px", borderRadius: "  5px", fontSize: " 14px", width: "" }} />
                                        {/* <div className='fs-12 text-muted mt-2 bg-white'>Don't have an account? <a className='bg-white text-decoration-none' href="/register">Sign up</a> </div> */}
                                    </center>
                                </div>
                            </div>
                        </center>

                    </div>

                </form>

            </div>
        </>
    );
}

export default NewPassword;
