import React,{Component} from "react";

class ItemList extends React.Component {
    constructor(props){
        super(props);
        
        var clearTimeoutFunction = 0 ;
        this.state = {
            items : [],
            clear : 10,
        };
    }
    componentDidMount(){
        const result =   setTimeout(()=>{
            
            this.setState({items : ['item 1' , 'item 2' , 'item 3']});
        },1000)
        this.setState({ clear: result })
    }
    componentWillUnmount(){
        // clean up method
        clearTimeout(// id value for setTimeout function
        );
    }
    render(){
        return(
            <div>
                <h1>Items:</h1>
                <ul>
                    {this.state.items.length > 0 && this.state.items.map((item ,index)=>(
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default ItemList;