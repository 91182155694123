import axios from 'axios';
import store from './../Store/store';

const axiosInstance = axios.create({
    // baseURL: "http://localhost:3000/api"
    baseURL: "https://www.expogalaxy.com/api"
})


// request interceptors
axiosInstance.interceptors.request.use((config) => {
    // config.headers.Authorization="asd531a2sdf33"
    // if(config.url=="/event/1"){
    //     config.headers.token="65as64d35335asd534";
    // }
    // store.dispatch(changeLoader(true))
    return config
}, (error) => {
    return Promise.reject(error);
})

// respons interceptors
axiosInstance.interceptors.response.use((respons) => {
    // store.dispatch(changeLoader(false))
    return respons
}, (err) => {
    return Promise.reject(err);
})

export default axiosInstance;