import React, { useEffect, useState } from "react";
import "./venues.css";
import axiosInstance from "../../AxiosConfig/axiosinstance";
import SpinnerComponent from "../../Components/Spinner/spinner";
import { Container, Row } from "react-bootstrap";

const Venues = () => {

    // get all featured venues
    const [venues, setVenues] = useState();
    const getAllVenues = async () => {
        try {
            const response = await axiosInstance.get(`/getfeaturedVenue`);

            // console.log(response.data.data);
            setVenues(response.data.data);
        } catch (error) {
            console.log(error);
        }
    }

    // get all Countries
    const [countries, setCountries] = useState();
    const getAllCountry = async () => {
        try {
            const response = await axiosInstance.get('/filterVenueInfo');
            // console.log(response.data.data.Country);
            setCountries(response.data.data.Country);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getAllVenues();
    }, [])
    return (
        // <div className=" pb-1" style={{backgroundColor:"#f8f9fa"}}>
        //     {/* header */}
        //     <header className="venueListing">
        //         <section
        //             className="wrapper d-flex position-relative justify-content-center align-items-center text-center"
        //             style={{
        //                 flexDirection: "column",
        //                 height: "calc(65vh - 55px)",
        //                 backgroundColor: "#000",
        //                 backgroundImage:
        //                     'url("https://img.10times.com/images/venue-homepage.jpg?imgeng=/w_480/h_360/cmpr_50")',
        //                 backgroundRepeat: "no-repeat",
        //                 backgroundSize: "cover",
        //             }}
        //         >
        //             <img
        //                 className="w-100 mb-4 lazyloaded"
        //                 src="./image/venue.png"
        //                 width={400}
        //                 height={87}
        //                 alt="10times venue"
        //                 loading="lazy"
        //                 style={{ maxWidth: 400, maxHeight: 87 }}
        //             />
        //             <h1 className="text-white mb-4">Find a Venue for Your Next Event</h1>
        //             <form action="" className="container-fluid form-horizontal mb-10">
        //                 <div className="form-group">
        //                     <div className="col-12 col-md-6 offset-md-3">
        //                         <div
        //                             className="input-group rounded-3 search-box-area srhmr"
        //                             style={{ zIndex: "90" }}
        //                         >
        //                             <input
        //                                 type="search"
        //                                 id="venue_homepage_search"
        //                                 className="form-control border-0"
        //                                 placeholder="Search by: city, country or venue name"
        //                                 autoComplete="off"
        //                                 data-provide="typeahead"
        //                             />
        //                             <span className="input-group-text border-0 xn">
        //                                 <svg
        //                                     xmlns="http://www.w3.org/2000/svg"
        //                                     width={16}
        //                                     height={16}
        //                                     fill="currentColor"
        //                                     className="bi bi-search cursor"
        //                                     viewBox="0 0 16 16"
        //                                 >
        //                                     <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
        //                                 </svg>
        //                             </span>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </form>
        //         </section>
        //     </header>
        //     {/* main page  */}
        //     <div id="main" className="container bg-white py-4 mb-3" style={{backgroundColor:"255,255,255"}}>
        //         <div className="row">
        //             {/* left section  */}
        //             <div className="col-12 col-md-8">
        //                 <section className="mb-5" id="left-box">
        //                     <h2 className=" mb-3 " style={{ fontSize: "1.2em" }}>
        //                         Featured Venues
        //                         <span className="fs-12 text-muted ms-1">(handpicked popular venues)</span>
        //                     </h2>
        //                     <div className="row">
        //                         {venues ? venues.map((venue, index) => {
        //                             return (
        //                                 <div key={index} className="col-12 col-md-4 col-sm-6 mb-4">
        //                                     <div className="border rounded-3 box-shadow overflow-hidden">
        //                                         <a className="text-decoration-none cursor">
        //                                             {venue.Banner ?
        //                                                 <img className="w-100 img-responsive lazyloaded" src={venue.Banner} width={240} height={120} alt={venue.Name} loading="lazy" /> :
        //                                                 <img className="w-100 img-responsive lazyloaded" src="https://img.10times.com/images/event-image-default.jpg" width={240} height={120} alt={venue.Name} loading="lazy" />}
        //                                         </a>
        //                                         <div className="p-2">
        //                                             <h3 className="mb-0" style={{ fontSize: "1.17em" }}>
        //                                                 <a className="text-decoration-none w-100 d-inline-block text-blue cursor">
        //                                                     <span className="line-1"
        //                                                         data-bs-original-title="China Import and Export Fair(Canton Fair Complex)"
        //                                                     >{venue.Name.slice(0, 24)}</span>
        //                                                 </a>

        //                                             </h3>
        //                                             <div className="small text-muted line-1">
        //                                                 <a className="text-muted text-decoration-none" rel="noreferrer">{venue.City !== "Null" ? venue.City : ""}</a>,
        //                                                 <a className="text-muted text-decoration-none" rel="noreferrer"> {venue.Country !== "Null" ? venue.Country : ""}</a>
        //                                             </div>

        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             )
        //                         }) : <SpinnerComponent />}

        //                     </div>
        //                 </section>
        //             </div>
        //             {/* right section  */}
        //             <div className="col-12 col-md-4">
        //                 <section id="right-box">
        //                     <h2 className=" mb-3 " style={{ fontSize: "1.2em" }}>
        //                         {" "}
        //                         Discover Venues{" "}
        //                     </h2>
        //                     <div id="city_box" className="p-2 border mb-5">
        //                         <table className="table table-responsive table-borderless">
        //                             <thead>
        //                                 <tr>
        //                                     <td colSpan="2">Popular cities</td>
        //                                 </tr>
        //                             </thead>
        //                             <tbody id="changecity">
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             London
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             New York
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Las Vegas
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Toronto
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Dubai
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Mumbai
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Fukuoka
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Beijing
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Chicago
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Paris
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Berlin
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <svg
        //                                                 xmlns="http://www.w3.org/2000/svg"
        //                                                 width="10"
        //                                                 height="10"
        //                                                 fill="currentColor"
        //                                                 className="bi bi-circle text-bold mx-2"
        //                                                 viewBox="0 0 16 16"
        //                                             >
        //                                                 <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
        //                                             </svg>
        //                                             Shanghai
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                         <hr />
        //                         <table className="table table-responsive table-borderless">
        //                             <thead>
        //                                 <tr>
        //                                     <td colSpan="2">Popular countries</td>
        //                                 </tr>
        //                             </thead>
        //                             <tbody>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694940961/CountryFlags/ltejhlmby9wue3d1zpi4.png" alt="United States" width={20} height={20} className="mx-1 mb-1" />
        //                                             United States
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694946408/CountryFlags/ioez497dt5vxvkhbfghr.png" alt="India" width={20} height={20} className="mx-1 mb-1" />India
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694941950/CountryFlags/oq8edmorzhbryfwao6ir.png" alt="Canada" width={20} height={20} className="mx-1 mb-1" />
        //                                             Canada
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694944874/CountryFlags/yuejbbaigbilvspleufa.png" alt="United Kingdom" width={20} height={20} className="mx-1 mb-1" />
        //                                             United Kingdom
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694942032/CountryFlags/rzaqymdoic5jra2zuer7.png" alt="China" width={20} height={20} className="mx-1 mb-1" />China
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694941264/CountryFlags/mmairmlb9vdxvgkmk0ab.png" alt="Australia" width={20} height={20} className="mx-1 mb-1" />
        //                                             Australia
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694947193/CountryFlags/ylymzm6j3uy2mh8bdzh5.png" alt="Malaysia" width={20} height={20} className="mx-1 mb-1" />
        //                                             Malaysia
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694953558/CountryFlags/tgoiwax8pmscjvqkrme0.png" alt="Philippines" width={20} height={20} className="mx-1 mb-1" />
        //                                             Philippines
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694941015/CountryFlags/hbc0iaaxuzfq08leehba.png" alt="France" width={20} height={20} className="mx-1 mb-1" />
        //                                             France
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694956847/CountryFlags/yizxah2ooq6bgpwx43ue.png" alt="UAE" width={20} height={20} className="mx-1 mb-1" />UAE
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694954944/CountryFlags/l8vag89zu8chwqnznpo9.png" alt="South Africa" width={20} height={20} className="mx-1 mb-1" />South
        //                                             Africa
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694946566/CountryFlags/omy6jv1xxnpe94qhcmjn.png" alt="Italy" width={20} height={20} className="mx-1 mb-1" />Italy
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694955024/CountryFlags/q1hcuptqtgc65vvxgeti.png" alt="Spain" width={20} height={20} className="mx-1 mb-1" />Spain
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694946433/CountryFlags/spgm6slzogmypeqbbler.png" alt="Indonesia" width={20} height={20} className="mx-1 mb-1" />
        //                                             Indonesia
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694941708/CountryFlags/dxuzpcwud4bc8hp7qr2r.png" alt="Brazil" width={20} height={20} className="mx-1 mb-1" />
        //                                             Brazil
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694955366/CountryFlags/oojch50vezgzhlubdvtx.png" alt="Thailand" width={20} height={20} className="mx-1 mb-1" />
        //                                             Thailand
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694941525/CountryFlags/o99d1zex7zautcfqrxad.png" alt="Belgium" width={20} height={20} className="mx-1 mb-1" />
        //                                             Belgium
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694946628/CountryFlags/w0d5qklypiopx4atizwv.png" alt="Japan" width={20} height={20} className="mx-1 mb-1" />Japan
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694956194/CountryFlags/wydmcpwm8f4nnqajhwco.png" alt="Turkey" width={20} height={20} className="mx-1 mb-1" />
        //                                             Turkey
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694941284/CountryFlags/bakihxbmegouhpnwqjcv.png" alt="Austria" width={20} height={20} className="mx-1 mb-1" />
        //                                             Austria
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694940906/CountryFlags/fbuapnvqmke7gztyijzx.png" alt="Egypt" width={20} height={20} className="mx-1 mb-1" />Egypt
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694953581/CountryFlags/lf1jn4pqcvnb0wclk1p0.png" alt="Poland" width={20} height={20} className="mx-1 mb-1" />
        //                                             Poland
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694953703/CountryFlags/ye0dv8mase5ywgct8zeu.png" alt="Russia" width={20} height={20} className="mx-1 mb-1" />
        //                                             Russia
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694956976/CountryFlags/m1lk4ixtscg70zzidrii.png" alt="Vietnam" width={20} height={20} className="mx-1 mb-1" />
        //                                             Vietnam
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694952525/CountryFlags/gkz4v4gvcxx9tlftdr1u.png" alt="New Zealand" width={20} height={20} className="mx-1 mb-1" />New Zealand
        //                                         </a>
        //                                     </td>
        //                                     <td>
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             <img src="https://res.cloudinary.com/dl7phtr1y/image/upload/v1694955220/CountryFlags/ap7rgpyz4exmkzrsbank.png" alt="Switzerland" width={20} height={20} className="mx-1 mb-1" />
        //                                             Switzerland
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                         <hr />
        //                         <div className="text-center mb-2">
        //                             <button className="btn btn-sm btn-official py-0 rounded-pill"
        //                                 data-bs-toggle="modal" data-bs-target="#staticBackdrop"
        //                                 onClick={getAllCountry}>
        //                                 Browse All Countries
        //                             </button>
        //                         </div>
        //                         {/* Modal  */}
        //                         <div className="modal custom fade mt-5" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        //                             <div className="modal-dialog">
        //                                 <div className="modal-content">
        //                                     <div className="modal-header">
        //                                         <h1 className="modal-title fs-5" id="staticBackdropLabel">Select Country</h1>
        //                                         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        //                                     </div>
        //                                     <div className="modal-body">
        //                                         <table className="table table-striped table-bordered">
        //                                             <tbody className="row ">
        //                                                 {countries
        //                                                     ? Object.entries(countries).map(([country, value], index) => (
        //                                                         <tr key={index} className="col-4 d-flex justify-items-stretch  justify-content-stretch">
        //                                                             <td className="justify-items-stretch  justify-content-stretch">
        //                                                             <img src={value.Icon} width={20} height={20} className="mx-1 mb-1" />&nbsp;
        //                                                                 <a className="text-decoration-none text-blue cursor">{country}</a>
        //                                                                 <small className="float-end" >{value.Number}</small>
        //                                                             </td>
        //                                                         </tr>
        //                                                     ))
        //                                                     : ""}
        //                                             </tbody>
        //                                         </table>
                                                


        //                                     </div>
        //                                     <div className="modal-footer">
        //                                         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        //                                         <button type="button" className="btn btn-primary">Understood</button>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>

        //                     </div>
        //                     <h2 className=" mb-3 " style={{ fontSize: "1.2em" }}>
        //                         Collections
        //                         <span className="fs-12 ms-2">search by venue category</span>
        //                     </h2>
        //                     <div className="p-2 border mb-5">
        //                         <table className="table table-responsive table-borderless">
        //                             <tbody>
        //                                 <tr>
        //                                     <td className="pe-0">
        //                                         <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="#6c757d" className="bi bi-mic-fill" viewBox="0 0 16 16">
        //                                             <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0z" />
        //                                             <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5" />
        //                                         </svg>

        //                                     </td>
        //                                     <td className="ps-0">
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             Conference Centres
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="pe-0">
        //                                         <svg
        //                                             className="svg-inline--fa fa-bed fa-w-16 text-muted mx-2"
        //                                             aria-hidden="true"
        //                                             focusable="false"
        //                                             data-prefix="fa"
        //                                             data-icon="bed"
        //                                             role="img"
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                             viewBox="0 0 640 512"
        //                                             data-fa-i2svg=""
        //                                         >
        //                                             <path
        //                                                 fill="currentColor"
        //                                                 d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z"
        //                                             ></path>
        //                                         </svg>
        //                                     </td>
        //                                     <td className="ps-0">
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             Hotels &amp; Resorts
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="pe-0">
        //                                         <svg
        //                                             className="svg-inline--fa fa-building fa-w-14 text-muted mx-2"
        //                                             aria-hidden="true"
        //                                             focusable="false"
        //                                             data-prefix="fa"
        //                                             data-icon="building"
        //                                             role="img"
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                             viewBox="0 0 448 512"
        //                                             data-fa-i2svg=""
        //                                         >
        //                                             <path
        //                                                 fill="currentColor"
        //                                                 d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z"
        //                                             ></path>
        //                                         </svg>
        //                                     </td>
        //                                     <td className="ps-0">
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             Exhibition &amp; Convention Centres
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="pe-0">
        //                                         <svg
        //                                             className="svg-inline--fa fa-bullhorn fa-w-18 text-muted mx-2"
        //                                             aria-hidden="true"
        //                                             focusable="false"
        //                                             data-prefix="fa"
        //                                             data-icon="bullhorn"
        //                                             role="img"
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                             viewBox="0 0 576 512"
        //                                             data-fa-i2svg=""
        //                                         >
        //                                             <path
        //                                                 fill="currentColor"
        //                                                 d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"
        //                                             ></path>
        //                                         </svg>
        //                                     </td>
        //                                     <td className="ps-0">
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             Banquets &amp; Halls
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="pe-0">
        //                                         <svg
        //                                             className="svg-inline--fa fa-university fa-w-16 text-muted mx-2"
        //                                             aria-hidden="true"
        //                                             focusable="false"
        //                                             data-prefix="fa"
        //                                             data-icon="university"
        //                                             role="img"
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                             viewBox="0 0 512 512"
        //                                             data-fa-i2svg=""
        //                                         >
        //                                             <path
        //                                                 fill="currentColor"
        //                                                 d="M496 128v16a8 8 0 0 1-8 8h-24v12c0 6.627-5.373 12-12 12H60c-6.627 0-12-5.373-12-12v-12H24a8 8 0 0 1-8-8v-16a8 8 0 0 1 4.941-7.392l232-88a7.996 7.996 0 0 1 6.118 0l232 88A8 8 0 0 1 496 128zm-24 304H40c-13.255 0-24 10.745-24 24v16a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-16c0-13.255-10.745-24-24-24zM96 192v192H60c-6.627 0-12 5.373-12 12v20h416v-20c0-6.627-5.373-12-12-12h-36V192h-64v192h-64V192h-64v192h-64V192H96z"
        //                                             ></path>
        //                                         </svg>
        //                                     </td>
        //                                     <td className="ps-0">
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             Auditoriums
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                                 <tr>
        //                                     <td className="pe-0">
        //                                         <svg
        //                                             className="svg-inline--fa fa-futbol fa-w-16 text-muted mx-2"
        //                                             aria-hidden="true"
        //                                             focusable="false"
        //                                             data-prefix="fas"
        //                                             data-icon="futbol"
        //                                             role="img"
        //                                             xmlns="http://www.w3.org/2000/svg"
        //                                             viewBox="0 0 512 512"
        //                                             data-fa-i2svg=""
        //                                         >
        //                                             <path
        //                                                 fill="currentColor"
        //                                                 d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-48 0l-.003-.282-26.064 22.741-62.679-58.5 16.454-84.355 34.303 3.072c-24.889-34.216-60.004-60.089-100.709-73.141l13.651 31.939L256 139l-74.953-41.525 13.651-31.939c-40.631 13.028-75.78 38.87-100.709 73.141l34.565-3.073 16.192 84.355-62.678 58.5-26.064-22.741-.003.282c0 43.015 13.497 83.952 38.472 117.991l7.704-33.897 85.138 10.447 36.301 77.826-29.902 17.786c40.202 13.122 84.29 13.148 124.572 0l-29.902-17.786 36.301-77.826 85.138-10.447 7.704 33.897C442.503 339.952 456 299.015 456 256zm-248.102 69.571l-29.894-91.312L256 177.732l77.996 56.527-29.622 91.312h-96.476z"
        //                                             ></path>
        //                                         </svg>
        //                                     </td>
        //                                     <td className="ps-0">
        //                                         <a className="text-decoration-none xn text-blue">
        //                                             Sports Complex
        //                                         </a>
        //                                     </td>
        //                                 </tr>
        //                             </tbody>
        //                         </table>
        //                         <hr />
        //                         <div className="text-center mb-2">
        //                             <button className="btn btn-sm btn-official py-0 rounded-pill">
        //                                 Browse All Categories
        //                             </button>
        //                         </div>
        //                     </div>
        //                 </section>
        //             </div>
        //         </div >
        //         <Container>
        //         {/* start News Letter*/}
        //         <Row className='row text-center border rounded-3 mx-0 my-2 news-letter'>
        //             <div className='col-12 col-md-6 text-center'>
        //                 <img src="image/newsletter3.png" alt="news letter" className='w-75' />
        //             </div>
        //             <div className='col-12 col-md-6 px-0 my-auto'>
        //                 <h3 className='fs-5 fw-normal my-2'>Looking for the Event Feed? Get the News Letters!</h3>
        //                 <div>Find great events to attend, connect with new opportunities and expand your networking!</div>
        //                 <br />
        //                 <div className='form-group row'>
        //                     <div className='col-12 col-md-9 my-2'>
        //                         <input type="email" className="form-control link_email" placeholder="e.g. abc@xyx" aria-label="link email" />
        //                     </div>
        //                     <div className='col-12 col-md-3 text-center text-md-start px-0 my-2 custyle'>
        //                         <button type="button" className="btn expo-primary">Send News</button>
        //                     </div>
        //                 </div>
        //                 <br />
        //             </div>
        //         </Row>
        //         {/* end News Letter*/}
        //     </Container>
        //     </div >
        // </div>
        <main className="main under-construction">
            <section className="contact my-5">
                <h1 className="title text-center">Awesome Thing</h1>
                <h2 className="sub-title text-center">Site Under Construction</h2>
                <p className="d-flex justify-content-center" style={{marginTop:"-7em"}}>
                <img src="https://png.pngtree.com/background/20230725/original/pngtree-website-under-construction-domain-webhoster-code-photo-picture-image_4298984.jpg" alt="under-construction" className="rounded-5 img-fluid" style={{zIndex:"-1"}} />

                </p>
            </section>
        </main>
    );
};

export default Venues;
