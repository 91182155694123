import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PhoneInput from 'react-phone-input-2';
import axiosInstance from '../../AxiosConfig/axiosinstance';
import 'react-phone-input-2/lib/style.css';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import getMobileNumber, { getCountryCode, userInfo } from '../../Store/action';
import { toast } from 'react-hot-toast';
// import { useAuth } from '../../Contexts/Auth';
import { User } from '../../Contexts/UserContexts';
import Cookies from 'universal-cookie';


const Login = () => {

    const navigate = useNavigate();
    // const { isAuthenticated, login, logout } = useAuth();

    const userData = useContext(User);

    // useEffect(()=>console.log(userAuth), [])

    // handel form login
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    //  Cookie
    const cookie = new Cookies();


    //hundel submit with email & pw 
    const handelSubmit = async (eve) => {
        eve.preventDefault();
        try {

            let res = await axiosInstance.post('/login', {
                "email": email,
                "password": password
            });
            const token = res.data.token;
            const userDetails = res.data.userDetails;

            //set cookie
            cookie.set('Bearer', token);
            localStorage.setItem('userInfo', JSON.stringify(userDetails));
            userData.setAuth({ token, userDetails });
            toast.success(res.data.message);
            navigate('/');

        } catch (error) {

            console.error(error.response.data.message);
            setError(error.response.data.message);
        }
    }

    //handel mobile form 
    const [countryCode, setCountryCode] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const dispatch = useDispatch()

    // handel show alert 
    const [show, setShow] = useState(false);


    // handel close and show model
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);



    // handel login with phone
    const handelSubmitModel = (eve) => {
        eve.preventDefault();
        dispatch(getMobileNumber(mobileNumber));
        dispatch(getCountryCode(countryCode));
        axiosInstance.post('/send-otp', {
            "countryCode": countryCode,
            "phoneNumber": mobileNumber
        }).then((respons) => {
            navigate('/verifycode');
            handleClose();
            // console.log(respons);
        }).catch((error) => {
            // setError(error.response.data.message)
            console.log(error);
        })
    }

    // handel login with Google
    const handelAuth = () => {
        // axiosInstance.get('/google').then((respons) => {
        //     navigate('/')
        //     // console.log(respons);
        // }).catch((error) => {
        //     setError(error.response.data.message)
        // })
    }

    useEffect(() => {
        // Check if the user is already authenticated
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

        if (isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    return (
        <>

            <div className='parent my-5 bg-white ' style={{ width: "50%", margin: "auto" }}>
                <form action="" method='post' className='m-1 bg-white' onSubmit={handelSubmit}>
                    <div className='ticket bg-white'>
                        <center className='bg-white'>
                            <a href="" className='whySignup bg-white'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle mx-1" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                                </svg>
                                WhySignup
                            </a>
                            <div className='fs-16 text-expo bg-white'>
                                Login or Signup
                            </div>
                            <div className="mt-2 bg-white">
                                <div className='bg-white justify-content-evenly align-items-center align-items-center    small social_btn_wrapper ' style={{ width: "88%" }}>
                                    {/* <a href="" > */}
                                    <div onClick={() => handelAuth()} role='button' className=' shadow-login bg-white d-flex fw-500 text-muted text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social-connect social_btn'>
                                        <span className=' bg-white d-flex align-items-center pe-2 me-2 border-end' >
                                            <img className='bg-white' src="./image/google-icon.svg" alt="" width={"16px"} />
                                        </span>
                                        Continue with Google
                                    </div>
                                    {/* </a> */}


                                    <div role='button' className='shadow-login bg-white d-flex fw-500 text-muted text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social_btn' onClick={handleShow}>
                                        <span className='bg-white d-flex align-items-center pe-2 me-2 border-end' >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-phone bg-white" viewBox="0 0 16 16">
                                                <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                            </svg>
                                        </span>
                                        Continue with Phone
                                        {/* modal-dialog modal-dialog-centered */}
                                    </div>
                                    {/* start model */}
                                    <Modal
                                        className='model-mobile h-50'
                                        show={show}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                        centered

                                    >
                                        <Modal.Header closeButton>
                                            <span className=' modal-title fs-16 w-100 text-center ms-3'>Login</span>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className="card-header">
                                                <h1 className="fs-5 pb-3">
                                                    Enter your phone number
                                                </h1>
                                            </div>
                                            <div className="card-content mb-3 card-header">
                                                <form action="" onSubmit={handelSubmitModel}>
                                                    <PhoneInput
                                                        country='eg'
                                                        inputProps={{
                                                            name: 'phone',
                                                            required: true,
                                                            autoFocus: true
                                                        }}
                                                        onChange={(mobile, country) => {
                                                            setMobileNumber(mobile.slice(2));
                                                            setCountryCode(mobile.slice(0, 2));
                                                        }}
                                                    />
                                                    {/* <Modal.Footer> */}
                                                    <button type="submit" className="btn btn-expo w-25 m-auto firebaseui-id-submit mt-3 firebaseui-button mdl-js-button mdl-button--raised mdl-button--colored" data-upgraded=",MaterialButton">Verify</button>
                                                    {/* </Modal.Footer> */}
                                                </form>


                                            </div>

                                        </Modal.Body>

                                    </Modal>
                                    <a href="http://localhost:3000/api/login/facebook" target="_blank">
                                        <div role='button' className='shadow-login d-flex align-items-center fw-500 text-white  text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social-connect social_btn' style={{ backgroundColor: "#1877f2" }}>
                                            <span className='text-dark d-flex align-items-center pe-2 me-2 border-end' style={{ width: "25px", backgroundColor: "#1877f2" }}>
                                                <svg className="svg-inline--fa fa-facebook fa-w-16" aria-hidden="true" style={{ width: " 16px", height: "16px", backgroundColor: "#1877f2", color: "white" }} focusable="false" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                    <path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z">
                                                    </path>
                                                </svg>
                                            </span>
                                            Continue with Facebook
                                        </div>
                                    </a>
                                    <a href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77pvrp0ctq9az2&redirect_uri=http://localhost:3000/api/auth/linkedin/callback&scope=openid%20profile%20email" target="_blank">
                                        <div role='button' className='shadow-login d-flex align-items-center fw-500  text-nowrap border-0 px-2 py-1 mx-1 my-3 rounded box-shadow social-connect social_btn text-light' style={{ backgroundColor: "#0072b1" }}>
                                            <span className='text-light d-flex align-items-center pe-2 me-2 border-end' style={{ width: "25px", backgroundColor: "#0072b1" }}>
                                                <svg className="svg-inline--fa fa-linkedin-in fa-w-14" aria-hidden="true" style={{ width: " 16px", height: "16px", backgroundColor: "#0072b1", color: "white" }} focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg>
                                            </span>
                                            Continue with Linkedin
                                        </div>
                                    </a>

                                </div>
                                <div className="index_desktop bg-white">
                                    <div className='fs-12 text-muted mt-2 bg-white'>-- or use your email & pw to proceed --</div>
                                    <input className="m-2 mb-2 fs-14 shadow-login" style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} name="email1" placeholder="Email" required type='email' id="valEmail" autoComplete="off"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <br />
                                    <input className="m-2 mb-2 fs-14 shadow-login" style={{ border: " 1px solid darkgray ", borderRadius: " 4px", padding: " 0.4rem", paddingLeft: " 0.4rem", width: " 56% " }} name="password" placeholder="Password" required type='password' id="valpassword" autoComplete="off"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <p className='bg-white text-danger'>{error}</p>
                                    <center className='bg-white'>
                                        <a href="/resetpassword" className='text-decoration-none bg-white'>Forgot password?</a>
                                        <div className='bg-white form-group small privacy-policy d-flex justify-content-center cursor-pointer privacy-policy-text mt-2  mx-5 fs-12'>
                                            <div className="bg-white">
                                                <input type="checkbox" name="" id="" className='fa-w-14 me-2 ' required />
                                            </div>
                                            <div className="server_check_box_msg bg-white">
                                                I agree to <a className="text-decoration-none bg-white" href="#" target="_blank"> Privacy Policy</a> and acknowledge to receive communication from 10times
                                            </div>
                                        </div>
                                        <input type="submit" id="send" value="Login" className=" btn btn-default m-3 mb-2" style={{ backgroundColor: " white", border: "  0.05em solid #862efb", outline: " none", color: " #862efb", padding: " 4px", borderRadius: "  5px", fontSize: " 14px", width: " 80px" }}
                                        />

                                        <div className='fs-12 text-muted mt-2 bg-white'>Don't have an account? <a className='bg-white text-decoration-none' href="/register">Sign up</a> </div>
                                    </center>
                                </div>

                            </div>
                        </center>

                    </div>

                </form>

            </div>
        </>

    );
}

export default Login;
